import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "./SearchInput";
import { useRecoilValue } from "recoil";
import { productTitleState } from "../../store/productStore";
import { ReactComponent as Prev } from "../../assets/ChevronLeftIcon.svg";

const HeaderContainer = styled.header`
  width: 100%;
  padding-top: 24px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  z-index: 100;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderIcon = styled.div`
  width: 32px;
`;
const HeaderTitle = styled.div`
  padding: 5px;
  color: var(--grey-100, #29292a);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function MobileHeader() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const productTitle = useRecoilValue(productTitleState);

  if (pathname.includes("/activities")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>상품목록</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/login")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>로그인</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/partnership")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>제휴안내</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/adopt-enterprise")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>기업 도입 안내</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/introduction")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>Nolly 소개</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/notices")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>공지사항</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/privacy")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>개인정보처리방침</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/privacy-optional")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>개인정보처리방침(선택)</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/marketing-agreement")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>혜택/정보 수신 약관</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/terms")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>이용약관</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/help-center")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>고객센터</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/careers")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>인재채용</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/signup")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>회원가입</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/find-id")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>아이디 찾기</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/find-pw")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>비밀번호 찾기</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/pw-reset")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>비밀번호 재설정</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/like")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>찜한 상품</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/promotions")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>기획전</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/seller")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>판매자 정보</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/profile")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>개인정보수정</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/ask")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>1:1 문의</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/ask")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>상품문의</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/payment")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>결제하기</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/products")) {
    return (
      <HeaderContainer style={{ padding: 14, borderBottom: "none" }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>{productTitle}</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/point")) {
    return (
      <HeaderContainer style={{ padding: 14, borderBottom: "none" }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>포인트</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/order")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>주문내역</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/review/create")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>리뷰 쓰기</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage/review")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <Prev onClick={() => navigate(-1)} />
        <HeaderTitle>내 리뷰 관리</HeaderTitle>
        <HeaderIcon />
      </HeaderContainer>
    );
  } else if (pathname.includes("/mypage-menu")) {
    return (
      <HeaderContainer style={{ padding: 14 }}>
        <HeaderTitle style={{ flex: 1 }}>마이놀리</HeaderTitle>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer style={{ padding: 14 }}>
      <SearchInput
        style={{ flex: 1 }}
        onSearch={(v) => navigate(`/search?q=${v}`)}
      />
    </HeaderContainer>
  );
}
