import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";
import { User } from "./sellerApi";

export interface ProductOptionDay {
  uuid: string;
  option_day: string;
  created: string;
  modified: string;
}

export function getProductOptionDays(
  productUuid: string,
  gte: string,
  lte: string,
  limit?: number,
  offset?: number,
): Promise<PaginationType<ProductOptionDay>> {
  return nollyAxios
    .get(`/api/products/${productUuid}/option_days?option_day__gte=${gte}&option_day__lte=${lte}&limit=${limit}&offset=${offset}`)
    .then(({ data }) => data);
}

export interface ProductOptionSeat {
  uuid: string;
  title: string;
  total_seat: number;
  available_seat: number;
  created: string;
  modified: string;
}

export function getProductOptionSeatByOptionDay(
  productUuid: string,
  optionDayUuid: string
): Promise<PaginationType<ProductOptionSeat>> {
  return nollyAxios
    .get(
      `/api/products/${productUuid}/option_days/${optionDayUuid}/option_seats?limit=${35}&offset=${0}`
    )
    .then(({ data }) => data);
}
