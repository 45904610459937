import styled from "@emotion/styled";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ReactComponent as IcClose } from "../assets/IcClose.svg";
import { loginPopupState } from "../store/commonStore";
import Button from "./Common/Button";

const Container = styled.div`
  width: 100%;
`;
const ContentContainer = styled.div`
  display: flex;
  width: 312px;
  height: 170px;
  padding-top: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
`;
const Top = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const TopText = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
`;
const Bottom = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function LoginPopup() {
  const [loginPopup, setLoginPopup] = useRecoilState(loginPopupState);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ReactModal
      style={customStyles}
      isOpen={loginPopup !== null}
      ariaHideApp={true}
      onRequestClose={() => setLoginPopup(null)}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <ContentContainer>
        <IcClose
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => setLoginPopup(null)}
        />
        <Top>
          <TopText>로그인이 필요합니다.</TopText>
        </Top>
        <Bottom>
          <Button
            style={{
              color: "#474748",
              fontSize: 15,
              fontWeight: "500",
              height: 42,
            }}
            onClick={() => setLoginPopup(null)}
          >
            닫기
          </Button>
          <Button
            style={{
              height: 42,
              color: "white",
              fontSize: 15,
              fontWeight: "500",
              backgroundColor: "#29292A",
            }}
            onClick={() => {
              setLoginPopup(null);
              navigate("/login", {
                state: { next: loginPopup?.next, state: loginPopup?.state },
              });
            }}
          >
            로그인
          </Button>
        </Bottom>
      </ContentContainer>
    </ReactModal>
  );
}
