import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Faq {
  uuid: string;
  title: string;
  created: string;
  modified: string;
}

export type FaqTopic = "INFO" | "USER" | "PAYMENT" | "PARTNER" | "OTHER";

export function getFaqList(
  limit?: number,
  offset?: number,
  topic?: FaqTopic,
  q?: string
): Promise<PaginationType<Faq>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  if (topic) query += `topic=${topic}&`;
  if (q) query += `q=${q}`;
  return nollyAxios.get(`/api/faqs` + query).then(({ data }) => data);
}

export interface FaqDetail extends Faq {
  content: string;
}

export function getFaqDetail(uuid: string): Promise<FaqDetail> {
  return nollyAxios.get(`/api/faqs/${uuid}`).then(({ data }) => data);
}
