import styled from "@emotion/styled";
import { Product } from "../../api/productApi";
import { useNavigate } from "react-router-dom";
import BigContentItem from "./BigContentItem";
import MainContentItem from "./MainContentItem";
import BlankContentItem from "./BlankContentItem";
import BigBlankContentItem from "./BigBlankContentItem";
import Skeleton from "react-loading-skeleton";
import { isMobile } from "react-device-detect";

const MainRowItemsContainer = styled.div`
  margin-bottom: 60px;
  max-width: 100%;
  width: 100%;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    padding: 0px 16px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;
const MainRowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const MainRowTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  white-space: nowrap;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const MainRowLink = styled.div`
  white-space: nowrap;
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  overflow: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    gap: 14px;
  }
  /* justify-content: space-between; */
`;
const WebItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media screen and (max-width: 768px) {
    gap: 14px;
  }
  align-self: stretch;

  overflow: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  /* justify-content: space-between; */
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const MobileItemsWrapper = styled.div`
  display: none;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  overflow: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  /* justify-content: space-between; */
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    display: flex;
    gap: 14px;
  }
`;

interface MainRowItemsProps {
  slug?: string;
  title?: string;
  contents?: Product[];
  oneline?: boolean;
}

export default function MainRowItems({
  slug,
  title,
  contents,
  oneline = false,
}: MainRowItemsProps) {
  const navigate = useNavigate();
  if (!contents) {
    return (
      <MainRowItemsContainer>
        <MainRowTitleWrapper>
          <Skeleton width={isMobile ? 150 : 300} height={32} />
          <MainRowLink onClick={() => navigate(`/promotions/${slug}`)}>
            전체보기
          </MainRowLink>
        </MainRowTitleWrapper>
        <WebItemsWrapper>
          {[1, 2, 3, 4].slice(0, 4).map((content) => (
            <BlankContentItem />
          ))}
        </WebItemsWrapper>
        <MobileItemsWrapper>
          {[1, 2, 3, 4].map((content) =>
            oneline ? <BlankContentItem /> : <BigBlankContentItem />
          )}
        </MobileItemsWrapper>
        {!oneline && (
          <ItemsWrapper>
            {[1, 2, 3, 4].slice(4).map((content) => (
              <BlankContentItem />
            ))}
          </ItemsWrapper>
        )}
      </MainRowItemsContainer>
    );
  }

  return (
    <MainRowItemsContainer>
      <MainRowTitleWrapper>
        <MainRowTitle>{title}</MainRowTitle>
        <MainRowLink onClick={() => navigate(`/promotions/${slug}`)}>
          전체보기
        </MainRowLink>
      </MainRowTitleWrapper>
      <WebItemsWrapper>
        {contents.slice(0, 4).map((content) => (
          <MainContentItem content={content} key={content.uuid} />
        ))}
      </WebItemsWrapper>
      <MobileItemsWrapper>
        {contents
          .slice(0, 4)
          .map((content) =>
            oneline ? (
              <MainContentItem content={content} key={content.uuid} />
            ) : (
              <BigContentItem content={content} key={content.uuid} />
            )
          )}
      </MobileItemsWrapper>
      {!oneline && (
        <ItemsWrapper>
          {contents.slice(4).map((content) => (
            <MainContentItem content={content} key={content.uuid} />
          ))}
        </ItemsWrapper>
      )}
    </MainRowItemsContainer>
  );
}
