import { ProductTopic } from "../types/enums";
import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Banner {
  uuid: string;
  mobile_image: string;
  pc_image: string;
  order: number;
  url: string;
  created: string;
  modified: string;
}

export function getTopBanners(
  topic?: keyof typeof ProductTopic
): Promise<PaginationType<Banner>> {
  return nollyAxios
    .get(`/api/top_banners?topic=${topic}&offset=${0}&limit=${10}`)
    .then(({ data }) => data);
}

export function getMiddleBanners(
  topic?: keyof typeof ProductTopic
): Promise<PaginationType<Banner>> {
  return nollyAxios
    .get(`/api/middle_banners?topic=${topic}&offset=${0}&limit=${10}`)
    .then(({ data }) => data);
}

export function getBottomBanners(
  topic?: keyof typeof ProductTopic
): Promise<PaginationType<Banner>> {
  return nollyAxios
    .get(`/api/bottom_banners?topic=${topic}&offset=${0}&limit=${10}`)
    .then(({ data }) => data);
}

export function getSideBanners(): Promise<PaginationType<Banner>> {
  return nollyAxios.get(`/api/side_banners?offset=${0}&limit=${10}`).then(({ data }) => data);
}

export function getMobileBanners(): Promise<PaginationType<Banner>> {
  return nollyAxios.get(`/api/mobile_banners?offset=${0}&limit=${10}`).then(({ data }) => data);
}
