import Signout from "../components/MyPage/Signout";
import MyPageLayout from "../components/MyPageLayout";

export default function MySignoutPage() {
  return (
    <MyPageLayout menu="exit">
      <Signout />
    </MyPageLayout>
  );
}
