import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import ProfileInfo from "./ProfileInfo";
import ProfileMobile from "./ProfileMobile";
import ProfilePassword from "./ProfilePassword";
import { isMobile } from "react-device-detect";

const Container = styled.div`
  width: 100%;
  max-width: 400px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 40px;
`;
interface CategoryItemWrapperProps {
  isActive: boolean;
}
const CategoryItemWrapper = styled.div<CategoryItemWrapperProps>`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--grey-100, #F00)"
      : " 1px solid var(--grey-50, #CBCBCC)"};
  background: var(--white, #fff);
  color: ${(props) => (props.isActive ? "#F00" : "#474748")};
  font-size: 15px;
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export default function Profile() {
  const [menu, setMenu] = useState<"info" | "password" | "mobile">("info");
  const content = useMemo(() => {
    switch (menu) {
      case "info":
        return <ProfileInfo />;
      case "password":
        return <ProfilePassword />;
      case "mobile":
        return <ProfileMobile />;
      default:
        return null;
    }
  }, [menu]);

  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>개인정보수정</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      <CategoryWrapper>
        <CategoryItemWrapper
          isActive={menu === "info"}
          onClick={() => setMenu("info")}
        >
          개인정보
        </CategoryItemWrapper>
        <CategoryItemWrapper
          isActive={menu === "password"}
          onClick={() => setMenu("password")}
        >
          비밀번호
        </CategoryItemWrapper>
        <CategoryItemWrapper
          isActive={menu === "mobile"}
          onClick={() => setMenu("mobile")}
        >
          휴대폰
        </CategoryItemWrapper>
      </CategoryWrapper>
      {content}
    </Container>
  );
}
