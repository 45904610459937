import styled from "@emotion/styled";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import { imageDetailState } from "../store/productStore";
import { ReactComponent as Prev } from "../assets/BannerChevronLeft.svg";
import { ReactComponent as Next } from "../assets/BannerChevronRight.svg";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useCallback, useEffect, useMemo, useRef } from "react";
import SquareImage from "./Common/SquareImgae";

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  position: relative;
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  content: {
    width: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "none",
  },
};

export default function ImageDetailPopup() {
  const [imageDetail, setImageDetail] = useRecoilState(imageDetailState);
  const ref = useRef<SwiperRef | null>(null);

  return (
    <ReactModal
      style={customStyles}
      isOpen={imageDetail ? imageDetail.list.length > 0 : false}
      ariaHideApp={true}
      onRequestClose={() => setImageDetail(null)}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <ContentContainer>
        <ImgWrapper>
          <Swiper
            ref={ref}
            modules={[Navigation]}
            navigation={{ nextEl: ".img-arrow-next", prevEl: ".img-arrow-prev" }}
            initialSlide={imageDetail?.idx || 0}
          >
            {imageDetail?.list.map((t, idx) => (
              <SwiperSlide
                key={"kd-" + idx}
                style={{
                  width: "calc(100% - 84px)",
                  // height: "70%",
                }}
              >
                <SquareImage imageUrl={t} />
              </SwiperSlide>
            ))}
          </Swiper>
        </ImgWrapper>
        <PrevButton {...{ swiper: ref.current?.swiper }} />
        <NextButton {...{ swiper: ref.current?.swiper }} />
      </ContentContainer>
    </ReactModal>
  );
}

function PrevButton({ swiper }: { swiper?: SwiperCore }) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slidePrev();
    }
  }, [swiper]);

  return (
    <Arrow onClick={clickHandler} style={{ left: 0 }} className="img-arrow-prev">
      <Prev />
    </Arrow>
  );
}
function NextButton({ swiper }: { swiper?: SwiperCore }) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slideNext();
    }
  }, [swiper]);

  return (
    <Arrow onClick={clickHandler} style={{ right: 0 }} className="img-arrow-next">
      <Next />
    </Arrow>
  );
}
