import styled from "@emotion/styled";
import React, { HTMLInputTypeAttribute, useState } from "react";
import { InputChangeEvent } from "./Input";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const InputContainer = styled.input`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid var(--grey-50, #cbcbcc);
  background: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.032px;
  &::placeholder {
    color: var(--grey-60, #b2b2b3);
  }
  &:disabled {
    background: var(--grey-30, #efeff0);
    color: var(--grey-60, #b2b2b3);
  }
`;

interface NumberInputProps {
  value?: number;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  style?: React.CSSProperties;
  onChange: (ev: InputChangeEvent) => void;
  onSet: (v: number) => void;
  errors?: any;
  disabled?: boolean;
  max?: number;
  countDown?: string;
  localeString?: boolean;
}

function formatNumberToLocaleString(
  number?: number,
  flag?: boolean
): string | number | undefined {
  if (!number) return undefined;
  if (!flag) return number;

  return number.toLocaleString();
}

function parseLocaleStringToNumber(localeString?: number) {
  if (!localeString) return undefined;
  return parseFloat(String(localeString).replace(/,/g, ""));
}

function NumberInput(props: NumberInputProps) {
  const {
    value,
    onChange,
    onSet,
    placeholder,
    disabled,
    errors,
    style,
    localeString,
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes("-")) return onSet(0);
    if (Number.isNaN(Number(e.target.value))) return onSet(0);

    onChange(e);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const formattedValue = isFocused
    ? value
    : formatNumberToLocaleString(
        parseLocaleStringToNumber(value),
        localeString
      );

  return (
    <Container>
      <InputContainer
        type="text"
        value={formattedValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        disabled={disabled}
        style={errors ? { ...style, border: "1px solid red" } : style}
      />
    </Container>
  );
}

export default NumberInput;
