import { Insta } from "../api/instaApi";
import SquareImage from "./Common/SquareImgae";

const Instagram = ({
  insta,
  width,
  height,
}: {
  width: string;
  height: string;
  insta?: Insta,
}) => {
  if (!insta) return null;
  return (
    <a href={insta.ig_link} target="_blank">
      <SquareImage imageUrl={insta.image} />
    </a>
  );
};
export default Instagram;
