import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginationType } from "../../api/productApi";
import {
  ProductQuestion,
  getProductQuestionList,
} from "../../api/productQuestionApi";
import Button from "../Common/Button";
import Pagination from "../Common/Pagenation";
import ProductQuestionItem from "./ProductQuestionItem";
import { useSetRecoilState } from "recoil";
import { loginPopupState } from "../../store/commonStore";
import { ProductTitle } from "./ProductCommon";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE } from "../../utils/common.util";

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
`;

const SubTitle = styled.div`
  color: var(--grey-90, #707071);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;
const CellHeader = styled.div`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  display: flex;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const TitleHeader = styled(CellHeader)`
  flex: 1 0 0;
  justify-content: flex-start;
`;
const OtherHeader = styled(CellHeader)`
  width: 160px;
`;

export default function ProductQuestionContent({
  productUuid,
  productTitle,
}: {
  productUuid: string;
  productTitle: string;
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [questions, setQuestions] =
    useState<null | PaginationType<ProductQuestion>>(null);
  const setLoginPopup = useSetRecoilState(loginPopupState);

  useEffect(() => {
    if (productUuid)
      getProductQuestionList(
        productUuid,
        PAGE_SIZE,
        (page - 1) * PAGE_SIZE
      ).then(setQuestions);
  }, [productUuid, page]);

  return (
    <QuestionWrapper>
      <TopWrapper>
        <TitleWrapper>
          <ProductTitle>상품문의</ProductTitle>
          <SubTitle>
            상품에 대한 문의를 남기는 공간입니다. 해당 게시판의 성격과 맞지 않는
            글은 사전동의 없이 삭제 혹은 이동될 수 있습니다.
          </SubTitle>
        </TitleWrapper>
        <Button
          style={
            isMobile
              ? { fontSize: 15, height: 42, fontWeight: 500 }
              : {
                  fontSize: 15,
                  fontWeight: 500,
                  maxWidth: "100px",
                  border: "1px solid #29292A",
                  height: 42,
                }
          }
          onClick={() => {
            if (!window.localStorage.getItem("accessToken")) {
              setLoginPopup({
                next: `/products/${productUuid}/ask`,
                state: {
                  state: { title: productTitle },
                },
              });
              return;
            }
            navigate(`/products/${productUuid}/ask`, {
              state: { title: productTitle },
            });
          }}
        >
          문의하기
        </Button>
      </TopWrapper>
      <ContentWrapper>
        {!isMobile && (
          <Row>
            <TitleHeader>제목</TitleHeader>
            <OtherHeader>작성자</OtherHeader>
            <OtherHeader>작성일</OtherHeader>
            <OtherHeader>답변상태</OtherHeader>
          </Row>
        )}
        {questions?.results.map((t: any) => {
          return <ProductQuestionItem question={t} key={`${t.uuid}`} />;
        })}
        {/* {questions?.results.map((t: any) => (
          <ProductQuestionItem question={t} key={`${t.id}`} />
        ))} */}
      </ContentWrapper>
      <Pagination
        curIndex={page}
        paginate={setPage}
        maxCount={questions?.count}
      />
    </QuestionWrapper>
  );
}
