import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PromotionDetail, getPromotionDetailBySlug } from "../api/promotionApi";
import { CommonContainer } from "../components/Common";
import Pagination from "../components/Common/Pagenation";
import ContentItem from "../components/Main/ContentItem";

const ListContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;
const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;

  white-space: nowrap;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const ListTitleCount = styled.div`
  color: var(--grey-70, #939394);
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    gap: 16px;
  }
`;

const ExhibitionImg = styled.img`
  width: 100%;
  height: 700px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    height: auto;
    object-fit: contain;
    margin-bottom: 0px;
  }
`;

export default function PromotionPage() {
  const [res, setRes] = useState<null | PromotionDetail>(null);
  let { id } = useParams();

  useEffect(() => {
    if (id) getPromotionDetailBySlug(id).then(setRes).catch();
  }, [id]);
  return (
    <ListContainer>
      <ContentContainer>
        <ExhibitionImg src={res?.image} />
        <ListTitleWrapper>
          <ListTitle>{res?.title}</ListTitle>
          <ListTitleCount>상품 {res?.products.length}개</ListTitleCount>
        </ListTitleWrapper>
        <ItemsWrapper>
          {res?.products.map((content) => (
            <ContentItem content={content} />
          ))}
        </ItemsWrapper>
        <Pagination />
      </ContentContainer>
    </ListContainer>
  );
}
