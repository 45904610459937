import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import FindIdByEmail from "../components/FindId/FindIdByEmail";
import FindIdByMobile from "../components/FindId/FindIdByMobile";

const LoginContainer = styled(CommonContainer)`
  padding: 80px 14px 120px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
interface TabWrapper {
  isActive: boolean;
}
const TabWrapper = styled.div<TabWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  flex: 1;
  color: ${(props) => (props.isActive ? "#29292A" : "#B2B2B3")};
  border-bottom: ${(props) =>
    props.isActive ? "2px solid var(--red-100, #F00)" : "none"};
`;
const SubTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
const IdResultWrapper = styled.div`
  width: 100%;
  height: 60px;
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.032px;
  border-radius: 4px;
  border: 1px solid var(--grey-100, #29292a);
  background: var(--grey-10, #fafafa);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export default function FindIdPage() {
  const [tab, setTab] = useState(0);
  const [foundId, setFoundId] = useState<string>("");
  const navigate = useNavigate();

  if (foundId) {
    return (
      <LoginContainer>
        <ContentContainer>
          <Title>아이디 찾기</Title>
          <SubTitle>
            고객님의 놀리 계정을 찾았습니다.
            <br />
            아이디 확인 후 로그인해 주세요.
          </SubTitle>
          <IdResultWrapper>{foundId}</IdResultWrapper>
          <Button
            onClick={() => navigate("/login")}
            style={{
              background: "#29292A",
              color: "white",
              border: "none",
              marginBottom: 12,
            }}
          >
            로그인
          </Button>
        </ContentContainer>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer>
      <ContentContainer>
        <Title>아이디 찾기</Title>
        {!foundId && (
          <TabsWrapper>
            <TabWrapper isActive={tab === 0} onClick={() => setTab(0)}>
              휴대폰 인증
            </TabWrapper>
            <TabWrapper isActive={tab === 1} onClick={() => setTab(1)}>
              이메일 인증
            </TabWrapper>
          </TabsWrapper>
        )}
        {tab === 0 ? (
          <FindIdByMobile setFoundId={setFoundId} />
        ) : (
          <FindIdByEmail setFoundId={setFoundId} />
        )}
      </ContentContainer>
    </LoginContainer>
  );
}
