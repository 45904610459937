import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { removeLikeProduct } from "../../api/likeApi";
import Button from "../Common/Button";
import dayjs from "dayjs";
import SquareImage from "../Common/SquareImgae";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-40, #e0e0e1);
`;

const Header = styled.div`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
`;
const HeaderDate = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 600;
  flex: 1;
`;
const Content = styled.div`
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  cursor: pointer;
`;

const Profile = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 4px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
`;

const InfoTitle = styled.div`
  width: 100%;
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
`;
const DiscountPct = styled.span`
  color: #f00;
  font-size: 24px;
  font-weight: 500;
`;
const Price = styled.span`
  color: var(--grey-100, #29292a);
  font-size: 24px;
  font-weight: 600;
`;
const Strike = styled.span`
  color: var(--grey-60, #b2b2b3);
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

interface Props extends React.PropsWithChildren {
  likeUuid: string;
  productUuid: string;
  createdAt: string;
  main_thumbnail_image: string;
  title: string;
  price: number;
  currentPrice: number;
  discountPct: number;
  successCb: () => void;
}

const LikeItem: React.FunctionComponent<Props> = ({
  likeUuid,
  productUuid,
  createdAt,
  main_thumbnail_image,
  title,
  price,
  currentPrice,
  discountPct,
  successCb,
}) => {
  const navigate = useNavigate();
  // const final = (price * (100 - discountPct)) / 100;
  const handleRemoveItem = () => {
    removeLikeProduct(likeUuid).then(() => {
      successCb();
    });
  };

  return (
    <Container>
      <Header>
        <HeaderDate>{dayjs(createdAt).format("YYYY-MM-DD")}</HeaderDate>
      </Header>
      <Content onClick={() => navigate(`/products/${productUuid}`)}>
        <div style={{ width: 100, height: 100 }}>
          <SquareImage imageUrl={main_thumbnail_image} radius={4} />
        </div>
        {/* <Profile src={main_thumbnail_image || ""} /> */}
        <InfoWrapper>
          <InfoTitle>{title}</InfoTitle>
          <Row>
            {discountPct !== 0 && <DiscountPct>{discountPct}%</DiscountPct>}
            <Price>{currentPrice.toLocaleString()}원</Price>
            {discountPct !== 0 && <Strike>{price.toLocaleString()}원</Strike>}
          </Row>
          <ButtonsWrapper>
            <Button
              style={{
                width: 95,
                height: 42,
                fontSize: 15,
                fontWeight: 500,
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                handleRemoveItem();
              }}
            >
              찜 삭제
            </Button>
            <Button
              style={{
                width: 95,
                height: 42,
                fontSize: 15,
                fontWeight: 500,
                background: "#29292A",
                color: "white",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                navigate("/products/" + productUuid);
              }}
            >
              결제하기
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default LikeItem;
