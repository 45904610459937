import axios from "axios";
import nollyAxios from "./nollyAxios";
export interface User {
  uuid: string;
  username: string;
  first_name: string;
  email: string;
  profile: Profile;
  created: Date;
  modified: Date;
  point?: Point;
}

export interface Point {
  amount: number;
}

export interface Profile {
  uuid: string;
  mobile_no: string;
  address_a: string;
  address_b: string;
  birthday: string;
  company_code: string;
  created: string;
  modified: string;
}

const baseUrl = process.env.REACT_APP_API_HOST;

const session = window.sessionStorage;

interface SignupPayload {
  username: string;
  first_name: string;
  email: string;
  password_a: string;
  password_b: string;
  mobile_no: string;
  verification_code: string;
  address_a: string;
  address_b: string;
  birthday: string;
  company_code?: string;
  session_token: string;
}
export function signup(
  signupPayload: SignupPayload,
  has_agreed_required_tos: boolean,
  has_agreed_required_privacy: boolean,
  has_agreed_privacy: boolean,
  has_agreed_information_receive: boolean,
  has_agreed_required_old_enough: boolean
): Promise<User> {
  const payload: any = {
    ...signupPayload,
    profile: {
      mobile_no: signupPayload.mobile_no,
      verification_code: signupPayload.verification_code,
      address_a: signupPayload.address_a,
      address_b: signupPayload.address_b,
      birthday: signupPayload.birthday,
      session_token: signupPayload.session_token,
      has_agreed_required_tos,
      has_agreed_required_privacy,
      has_agreed_privacy,
      has_agreed_information_receive,
      has_agreed_required_old_enough,
    },
  };
  if (signupPayload.company_code) {
    payload.profile.company_code = signupPayload.company_code;
  }
  return axios
    .post(baseUrl + "/api/users", {
      ...payload,
    })
    .then(({ data }) => data.user as User);
}

interface LoginRes {
  access: string;
  refresh: string;
}

interface LoginPayload {
  username: string;
  password: string;
}
export function login(loginPayload: LoginPayload): Promise<LoginRes> {
  return axios
    .post(baseUrl + "/api/auth/token", loginPayload)
    .then(({ data }) => data);
}

export function me(): Promise<User> {
  return nollyAxios.get("/api/users/me").then(({ data }) => data);
}

export function refreshToken(): Promise<User> {
  const refresh = window.localStorage.getItem("refreshToken");
  return axios
    .post(baseUrl + "/api/auth/refresh_token", { refresh })
    .then(({ data }) => data);
}

export function validateUsername(username: string): Promise<any> {
  return axios
    .post(baseUrl + "/api/users/validate_username", { username })
    .then(({ data }) => data);
}
export function validateEmail(email: string): Promise<any> {
  return axios
    .post(baseUrl + "/api/users/validate_email", { email })
    .then(({ data }) => data);
}

export function findIdByMobile(
  first_name: string,
  session_token: string
): Promise<{ username: string }> {
  const payload = { first_name, session_token };
  return axios
    .post(baseUrl + "/api/users/find_username_by_mobile_no", payload)
    .then(({ data }) => data);
}

export function findIdByEmail(
  first_name: string,
  session_token: string
): Promise<{ username: string }> {
  const payload = { first_name, session_token };
  return axios
    .post(baseUrl + "/api/users/find_username_by_email", payload)
    .then(({ data }) => data);
}

export function sendResetPwEmail(email: string): Promise<void> {
  const payload = { email };
  return axios
    .post(baseUrl + "/api/users/send_reset_password_email", payload)
    .then(({ data }) => data);
}

export function updatePassword(
  userId: string,
  password_a: string,
  password_b: string
): Promise<void> {
  const payload = {
    password_a,
    password_b,
  };
  return nollyAxios
    .put(baseUrl + "/api/users/" + userId + "/update_password", payload)
    .then(({ data }) => data);
}

export function updateMobileNo(
  userId: string,
  session_token: string,
  mobile_no: string
): Promise<void> {
  const payload = {
    session_token,
    profile: {
      mobile_no,
    },
  };
  return nollyAxios
    .put(baseUrl + "/api/users/" + userId + "/update_mobile_no", payload)
    .then(({ data }) => data);
}

export function updateProfile(
  userId: string,
  email: string,
  first_name: string,
  address_a: string,
  address_b: string,
  company_code: string
): Promise<User> {
  const payload: any = {
    email,
    first_name,
    profile: {
      address_a,
      address_b,
    },
  };
  if (company_code) {
    payload.profile.company_code = company_code;
  }
  return nollyAxios
    .put(baseUrl + "/api/users/" + userId, payload)
    .then(({ data }) => data);
}
export function resetPassword(
  new_password1: string,
  new_password2: string,
  uid: string,
  token: string
): Promise<User> {
  const payload = {
    uid,
    token,
    new_password1,
    new_password2,
  };
  return axios
    .put(baseUrl + "/api/users/reset_password", payload)
    .then(({ data }) => data);
}

export function deleteUser(
  userUuid: string,
  reason: string,
  bank_name: string,
  bank_account_no: string,
  password: string
): Promise<User> {
  const payload = {
    reason,
    bank_name,
    bank_account_no,
    password,
  };
  return nollyAxios
    .delete(baseUrl + "/api/users/" + userUuid, { data: payload })
    .then(({ data }) => data);
}
