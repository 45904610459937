import styled from "@emotion/styled";
import react from "react";
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";

const DesktopWrapper = styled.div`
  display: block;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const MobileWrapper = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const ItemPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  align-content: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
`;
const ItemDiscountPct = styled.span`
  color: var(--red-90, #ff4747);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.04px;
  max-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ItemPrice = styled.span`
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;
const ItemPreviousPrice = styled.span`
  color: var(--grey-60, #b2b2b3);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.024px;
  text-decoration-line: line-through;
`;

interface PriceTextProps {
  price?: number;
  currentPrice?: number;
  discountPct?: number;
  discountFontSize?: number;
  priceFontSize?: number;
  strikeFontSize?: number;
}

export default function PriceText({
  price,
  currentPrice,
  discountPct = 18,
  discountFontSize,
  priceFontSize = 18,
  strikeFontSize = 12,
}: PriceTextProps) {
  if (!price) {
    return <Skeleton width={150} height={24} />;
  }

  return (
    <>
      <MobileWrapper>
        <ItemPriceWrapper>
          {discountPct !== 0 && (
            <ItemDiscountPct
              style={{ fontSize: `${isMobile ? 18 : discountFontSize}px` }}
            >
              {discountPct}%
            </ItemDiscountPct>
          )}
          <ItemPrice style={{ fontSize: `${isMobile ? 18 : priceFontSize}px` }}>
            {currentPrice?.toLocaleString()}원
          </ItemPrice>
        </ItemPriceWrapper>
        {discountPct !== 0 && (
          <ItemPreviousPrice
            style={{ fontSize: `${isMobile ? 12 : strikeFontSize}px` }}
          >
            {price.toLocaleString()}원
          </ItemPreviousPrice>
        )}
      </MobileWrapper>
      <DesktopWrapper>
        <ItemPriceWrapper>
          {discountPct !== 0 && (
            <ItemDiscountPct
              style={{ fontSize: `${discountFontSize}px` }}
            >
              {discountPct}%
            </ItemDiscountPct>
          )}
          <ItemPrice style={{ fontSize: `${priceFontSize}px` }}>
            {currentPrice?.toLocaleString()}원
          </ItemPrice>
          {discountPct !== 0 && (
            <ItemPreviousPrice
              style={{ fontSize: `${strikeFontSize}px` }}
            >
              {price.toLocaleString()}원
            </ItemPreviousPrice>
          )}
        </ItemPriceWrapper>
      </DesktopWrapper>
    </>
  );
}
