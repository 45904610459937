import styled from "@emotion/styled";
import { ProductTitle } from "./ProductCommon";
import { useState } from "react";
import ChevronUpIconSvg from "../../assets/ChevronUpIcon.svg";
import ChevronDownIconSvg from "../../assets/ChevronDownIcon.svg";

const ProductHelpCenterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

const CollapseWarpper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;
const CollapseHeader = styled.div`
  display: inline-flex;
  padding: 20px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  cursor: pointer;
`;
const CollapseTitle = styled.div``;
const CollapseIcon = styled.img``;
const CollapseContent = styled.pre`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  background: var(--grey-20, #f9f9fa);
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
`;

export default function ProductHelpCenter({
  faq,
  refund_policy,
}: {
  faq: string;
  refund_policy: string;
}) {
  const [refundOpened, setRefundOpened] = useState(false);
  const [askOpened, setAskOpened] = useState(false);
  return (
    <ProductHelpCenterWrapper>
      <ProductTitle>고객센터</ProductTitle>
      <CollapseWarpper>
        <CollapseHeader onClick={() => setRefundOpened((v) => !v)}>
          <CollapseTitle>환불정책</CollapseTitle>
          <CollapseIcon
            src={refundOpened ? ChevronUpIconSvg : ChevronDownIconSvg}
          />
        </CollapseHeader>
        {refundOpened && <CollapseContent>{faq}</CollapseContent>}
      </CollapseWarpper>
      <CollapseWarpper>
        <CollapseHeader onClick={() => setAskOpened((v) => !v)}>
          <CollapseTitle>유의사항</CollapseTitle>
          <CollapseIcon
            src={askOpened ? ChevronUpIconSvg : ChevronDownIconSvg}
          />
        </CollapseHeader>
        {askOpened && <CollapseContent>{refund_policy}</CollapseContent>}
      </CollapseWarpper>
    </ProductHelpCenterWrapper>
  );
}
