import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PaginationType, Product } from "../api/productApi";
import { Seller, sellerDetail, sellerProducts } from "../api/sellerApi";
import { CommonContainer } from "../components/Common";
import { StartIcon } from "../components/Icon/StarIcon";
import ContentItem from "../components/Main/ContentItem";
import Pagination from "../components/Common/Pagenation";
import { PAGE_SIZE, PRODUCTS_PAGE_SIZE } from "../utils/common.util";

const ListContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
`;
const SellerContainer = styled.div`
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  align-self: stretch;
  padding-bottom: 40px;
  overflow: hidden;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
`;
const SellerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;
const SellerProfile = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;
const SellerDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
`;
const SellerDescTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.056px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const SellerDescRating = styled.div`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
  display: flex;
  align-items: center;
  gap: 6px;
`;
const SellerDescContent = styled.pre`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
  white-space: pre-line;
  max-width: 768px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    display: none;
    font-size: 14px;
  }
`;
const MobileSellerDescContent = styled.pre`
  display: none;
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.032px;
  word-break: break-all;
  white-space: normal;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 16px;
  }
`;
const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const ListTitleCount = styled.div`
  color: var(--grey-70, #939394);
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export default function SellerPage() {
  const [seller, setSeller] = useState<null | Seller>(null);
  const [products, setProducts] = useState<null | PaginationType<Product>>(
    null
  );
  const [page, setPage] = useState(1);

  let { id } = useParams();

  useEffect(() => {
    if (id) sellerDetail(id).then(setSeller);
  }, [id]);

  useEffect(() => {
    if (id) {
      sellerProducts(id, PRODUCTS_PAGE_SIZE, (page - 1) * PRODUCTS_PAGE_SIZE).then(setProducts);
    }
  }, [id, page]);

  return (
    <ListContainer>
      <ContentContainer>
        <SellerContainer>
          <SellerWrapper>
            <SellerProfile src={seller?.profile_image} />
            <SellerDescWrapper>
              <SellerDescTitle>{seller?.user.username}</SellerDescTitle>
              <SellerDescRating>
                <StartIcon /> {seller?.review_avg} (리뷰 {seller?.review_count}
                개)
              </SellerDescRating>
              <SellerDescContent>{seller?.bio}</SellerDescContent>
            </SellerDescWrapper>
          </SellerWrapper>
        </SellerContainer>
        <MobileSellerDescContent>{seller?.bio}</MobileSellerDescContent>
        <ListTitleWrapper>
          <ListTitle>판매중인 상품</ListTitle>
          <ListTitleCount>{products?.count}</ListTitleCount>
        </ListTitleWrapper>
        <ItemsWrapper style={{ marginBottom: 0 }}>
          {products?.results.map((content) => (
            <ContentItem content={content} />
          ))}
        </ItemsWrapper>
        <Pagination
          curIndex={page}
          paginate={setPage}
          maxCount={products?.count}
          pageSize={PRODUCTS_PAGE_SIZE}
        />
      </ContentContainer>
    </ListContainer>
  );
}
