import styled from "@emotion/styled";
import InfoImgSvg from "../../assets/InfoIcon.svg";

const ProductSellerPhoneInfoWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background: var(--grey-20, #f9f9fa);
`;

const InfoIcon = styled.img``;
const InfoText = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 500;
`;

export default function ProductSellerPhoneInfo() {
  return (
    <ProductSellerPhoneInfoWrapper>
      <InfoIcon src={InfoImgSvg} />
      <InfoText>구매 후 판매자 연락처가 전송됩니다.</InfoText>
    </ProductSellerPhoneInfoWrapper>
  );
}
