import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";
import { User } from "./sellerApi";

export interface ProductQuestion {
  uuid: string;
  user: User;
  is_public: boolean;
  title: string;
  content: string;
  created: string;
  modified: string;
}

export function getProductQuestionList(
  productUuid: string,
  limit?: number,
  offset?: number,
): Promise<PaginationType<ProductQuestion>> {
  return nollyAxios
    .get(`/api/products/${productUuid}/product_questions?limit=${limit}&offset=${offset}`)
    .then(({ data }) => data);
}

export function createProductQuestion(
  productUuid: string,
  title: string,
  content: string,
  is_public: boolean
): Promise<PaginationType<ProductQuestion>> {
  const payload = { title, content, is_public };
  return nollyAxios
    .post(`/api/products/${productUuid}/product_questions`, payload)
    .then(({ data }) => data);
}
