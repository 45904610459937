import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import {
  Purchase,
  PurchaseDetail,
  getPruchaseDetail,
} from "../../api/purchaseApi";
import { PayMethods } from "../../types/enums";
import { useEffect, useState } from "react";

const Container = styled.div`
  width: 100%;
  max-width: 720px;
`;
const DetailWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

const DetailTitle = styled.div`
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
const DetailRow = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const DetailRowTitle = styled.div`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 500;
  width: 200px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: 100px;
  }
`;
const DetailRowDesc = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 18px;
  font-weight: 500;
  flex: 1;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 200px);

  @media screen and (max-width: 768px) {
    font-size: 16px;
    max-width: calc(100% - 100px);
  }
`;

export default function OrderDetail() {
  const location = useLocation();
  const purchase: Purchase | null = location.state?.purchase || null;
  const [res, setRes] = useState<null | PurchaseDetail>(null);

  useEffect(() => {
    if (purchase) {
      getPruchaseDetail(purchase?.uuid).then(setRes);
    }
  }, [purchase]);
  return (
    <Container>
      <DetailWrapper>
        <DetailTitle>주문상세</DetailTitle>
        <DetailRow>
          <DetailRowTitle>제품명</DetailRowTitle>
          <DetailRowDesc>{purchase?.product.title}</DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>찾아가는 길</DetailRowTitle>
          <DetailRowDesc>{purchase?.product.location}</DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>판매자 명</DetailRowTitle>
          <DetailRowDesc>{res?.product.seller.user.username}</DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>연락처</DetailRowTitle>
          <DetailRowDesc>
            {res?.product.seller.user.profile?.mobile_no || "-"}
          </DetailRowDesc>
        </DetailRow>
      </DetailWrapper>
      <DetailWrapper>
        <DetailTitle>결제정보</DetailTitle>
        <DetailRow>
          <DetailRowTitle>상품 금액</DetailRowTitle>
          <DetailRowDesc>
            {(
              (purchase?.origin_price || 0) * (purchase?.quantity || 0)
            ).toLocaleString()}
            원
          </DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>할인 금액</DetailRowTitle>
          <DetailRowDesc>
            {(
              (purchase?.sale_amount || 0) * (purchase?.quantity || 0)
            ).toLocaleString()}
            원
          </DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>포인트 사용</DetailRowTitle>
          <DetailRowDesc>
            {purchase?.point_spent.toLocaleString()}원
          </DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>총 결제 금액</DetailRowTitle>
          <DetailRowDesc>
            {purchase?.amount_spent.toLocaleString()}원
          </DetailRowDesc>
        </DetailRow>
        <DetailRow>
          <DetailRowTitle>결제 방법</DetailRowTitle>
          <DetailRowDesc>
            {PayMethods[purchase?.purchase_method as keyof typeof PayMethods] ||
            purchase?.amount_spent === 0
              ? "포인트"
              : "-"}
          </DetailRowDesc>
        </DetailRow>
      </DetailWrapper>
    </Container>
  );
}
