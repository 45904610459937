import styled from "@emotion/styled";

export const ProductTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export interface IsActiveProps {
  isActive: boolean;
}