import styled from "@emotion/styled";
import PriceText from "../PriceText";
import SquareImage from "../Common/SquareImgae";
import Skeleton from "react-loading-skeleton";

// const ItemWrapper = styled.div`
const ItemWrapper = styled.a`
  max-width: calc(25% - 24px);
  min-width: calc(25% - 24px);
  flex: 1 1 calc(25% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    flex: 1;
    max-width: calc(43% - 10px);
    min-width: calc(43% - 10px);
    /* width: calc(50% - 10px); */
  }
`;
const ItemImgWrpaper = styled.div`
  width: 100%;
  position: relative;
`;
const ItemDescWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;

export type Content = {
  id: string;
  imgUrl: string;
  location: string;
  title: string;
  price: number;
  liked: boolean;
  discountPct: number;
  isNew: boolean;
  isBest: boolean;
};

export default function BlankContentItem() {
  return (
    <ItemWrapper>
      <ItemImgWrpaper>
        <SquareImage radius={8} />
      </ItemImgWrpaper>
      <ItemDescWrapper>
        <Skeleton width={38} height={25} />
        <Skeleton width={200} height={22} />
        <PriceText />
      </ItemDescWrapper>
    </ItemWrapper>
  );
}
