import nollyAxios from "./nollyAxios";
import { PaginationType, Product } from "./productApi";
export interface MyProductLike {
  uuid: string;
  product: Product;
  created: string;
  modified: string;
}

export function getProductLikes(
  limit?: number,
  offset?: number
): Promise<PaginationType<MyProductLike>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}`;
  return nollyAxios
    .get(`/api/product_likes${query}`)
    .then(({ data }) => data);
}
