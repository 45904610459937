import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { likeProduct, removeLikeProduct } from "../../api/likeApi";
import { Product } from "../../api/productApi";
import LikeIcon from "../../assets/LikeIcon.svg";
import LikeIconActive from "../../assets/LikeIconActive.svg";
import { loginPopupState } from "../../store/commonStore";
import SquareImage from "../Common/SquareImgae";
import PriceText from "../PriceText";

// const ItemWrapper = styled.div`
const ItemWrapper = styled.a`
  max-width: calc(25% - 24px);
  min-width: calc(25% - 24px);
  /* width: calc(25% - 24px); */
  flex: 1 1 calc(25% - 24px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    flex: 1;
    max-width: calc(50% - 14px);
    min-width: calc(50% - 14px);
    /* width: calc(50% - 10px); */
  }
`;
const ItemImgWrpaper = styled.div`
  width: 100%;
  position: relative;
`;
const LikeWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const LikeImg = styled.img`
  @media screen and (max-width: 768px) {
    width: 36px;
    height: 36px;
  }
`;
const BadgesWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;
const BestBadge = styled.span`
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--blue-10, #f2f2ff);
  color: var(--blue-90, #2626ff);
  font-size: 12px;
  font-weight: 600;
`;
const NewBadge = styled.span`
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--blue-10, #29292a);
  color: var(--blue-90, #0f0);
  font-size: 12px;
  font-weight: 600;
`;
const ItemImg = styled.img`
  display: flex;
  width: 100%;
  height: 238px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 150px;
  }
`;
const ItemDescWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;
const ItemLocation = styled.span`
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--grey-30, #efeff0);
`;
const ItemTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.03px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export type Content = {
  id: string;
  imgUrl: string;
  location: string;
  title: string;
  price: number;
  liked: boolean;
  discountPct: number;
  isNew: boolean;
  isBest: boolean;
};

interface ContentItemProps {
  content: Product;
  _likeUuid?: string;
}

export default function ContentItem({ content, _likeUuid }: ContentItemProps) {
  const {
    uuid,
    origin_price,
    current_price,
    sale_percentage,
    main_thumbnail_image,
    is_new,
    is_best,
    location,
    title,
  } = content;

  const [likeUuid, setLikeUuid] = useState<null | string>(_likeUuid || null);
  const setLoginPopup = useSetRecoilState(loginPopupState);

  useEffect(() => {
    if (content && !_likeUuid) {
      setLikeUuid(content.like_uuid);
    }
  }, [content, _likeUuid]);

  const toggle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!window.localStorage.getItem("accessToken")) {
      setLoginPopup({
        next: "/",
        state: {},
      });
      return;
    }
    if (likeUuid) {
      removeLikeProduct(likeUuid).then(() => {
        setLikeUuid(null);
      });
    } else {
      likeProduct(content.uuid).then(({ uuid }) => {
        setLikeUuid(uuid);
      });
    }
  };

  return (
    <ItemWrapper href={"/products/" + uuid}>
      <ItemImgWrpaper>
        <BadgesWrapper>
          {is_best && <BestBadge>BEST</BestBadge>}
          {is_new && <NewBadge>New</NewBadge>}
        </BadgesWrapper>
        <SquareImage imageUrl={main_thumbnail_image} radius={8} />
        <LikeWrapper onClick={toggle}>
          <LikeImg src={likeUuid ? LikeIconActive : LikeIcon} />
        </LikeWrapper>
      </ItemImgWrpaper>
      <ItemDescWrapper>
        <ItemLocation>{location}</ItemLocation>
        <ItemTitle>{title}</ItemTitle>
        <PriceText
          price={origin_price}
          discountPct={sale_percentage}
          currentPrice={current_price}
        />
      </ItemDescWrapper>
    </ItemWrapper>
  );
}
