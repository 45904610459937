import OrderHistory from "../components/MyPage/OrderHistory";
import MyPageLayout from "../components/MyPageLayout";

export default function MyOrderPage() {
  return (
    <MyPageLayout menu="order">
      <OrderHistory />
    </MyPageLayout>
  );
}
