import styled from "@emotion/styled";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { createProductQuestion } from "../api/productQuestionApi";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import Input from "../components/Common/Input";
import Radio from "../components/Common/Radio";
import TextArea from "../components/Common/TextArea";
import { isMobile } from "react-device-detect";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 24px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;
const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
`;

const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const InputLabelWrapper = styled.div`
  display: flex;
  min-width: 110px;
  width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const InputProductWrapper = styled.div`
  padding: 0px 16px;
  gap: 8px;
  flex: 1 0 0;
  max-width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RequiredSpan = styled.span`
  color: #f00;
  font-size: 14px;
  font-weight: 400;
`;

const SecretWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SecretText = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;

interface InputTypes {
  title: string;
  content: string;
}

export default function AskPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const title = location.state?.title || "";

  let { id } = useParams();

  const [isPublic, setIsPublic] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  const onSubmit = async (inputValues: InputTypes) => {
    if (id) {
      createProductQuestion(
        id,
        inputValues.title,
        inputValues.content,
        isPublic
      ).then((question) => {
        alert("상품문의를 성공적으로 작성하였습니다.");
        navigate(`/products/${id}`);
      });
    }
  };

  return (
    <AskContainer>
      <ContentContainer>
        {!isMobile && (
          <HeaderWrapper>
            <ListTitleWrapper>
              <ListTitle>상품문의</ListTitle>
            </ListTitleWrapper>
          </HeaderWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <FormContainer>
            <InputRowWrapper>
              <InputLabelWrapper>상품명</InputLabelWrapper>
              <InputProductWrapper style={{ padding: 0 }}>
                {title}
              </InputProductWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper>
                제목 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"title"}
                rules={{
                  required: "필수 항목입니다.",
                  maxLength: 50,
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="제목을 입력해주세요."
                    style={{ height: 42 }}
                    errors={errors.title?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper style={{ alignItems: "flex-start" }}>
              <InputLabelWrapper style={{ marginTop: 10 }}>
                내용 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"content"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextArea
                    placeholder="내용을 입력해주세요."
                    style={{ height: 268 }}
                    errors={errors.content?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper />
              <SecretWrapper onClick={() => setIsPublic((v) => !v)}>
                <Radio checked={!isPublic} />
                <SecretText>비공개 문의</SecretText>
              </SecretWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper />
              <Button
                style={{
                  background: "#29292A",
                  color: "white",
                  border: "none",
                  marginBottom: 12,
                }}
              >
                등록
              </Button>
            </InputRowWrapper>
          </FormContainer>
        </form>
      </ContentContainer>
    </AskContainer>
  );
}
