import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useRecoilState } from "recoil";
import { me, updateMobileNo } from "../../api/authApi";
import { registerSms, verifySms } from "../../api/smsApi";
import { CommonContainer } from "../../components/Common";
import Button from "../../components/Common/Button";
import Input from "../../components/Common/Input";
import { userState } from "../../store/authStore";
import { isMobile } from "react-device-detect";

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Desc = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
`;
const Mark = styled.span`
  color: #f00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
`;
const InputsWrapper = styled.div`
  margin-bottom: 60px;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Label = styled.div`
  min-width: 110px;
  width: 110px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 768px) {
    min-width: 85px;
    width: 85px;
  }
`;

interface InputTypes {
  mobile_no: string;
  verification_code: string;
}

export default function ProfileMobile() {
  const [user, setUser] = useRecoilState(userState);
  const [sessionToken, setSessionToken] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  useEffect(() => {
    console.log("erros: ", errors);
  }, [errors]);

  const handleClickSendMobile = async () => {
    try {
      const mobileNo = watch("mobile_no");
      const { session_token } = await registerSms(mobileNo);
      setSessionToken(session_token);
    } catch (e) {}
  };

  const onSubmit = async (inputValues: InputTypes) => {
    if (user) {
      await verifySms(
        inputValues.mobile_no,
        sessionToken,
        inputValues.verification_code
      );
      await updateMobileNo(user?.uuid, sessionToken, inputValues.mobile_no);
      alert("성공적으로 휴대폰 번호가 변경되었습니다.");
      reset();
      me().then(setUser);
    }
    // const { username } = await findIdByMobile(firstName, sessionToken);
    // signup(inputValues).then((user) => {
    //   setUser(user);
    // });
  };

  return (
    <ContentContainer>
      <Desc>
        <Mark>*</Mark> 필수 입력사항
      </Desc>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <InputsWrapper>
          <InputWrapper>
            <Label>
              휴대폰 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"mobile_no"}
              rules={{
                required: "필수 항목입니다.",
              }}
              defaultValue={user?.profile?.mobile_no}
              render={({ field }) => (
                <InputMask
                  {...field}
                  style={{
                    width: "100%",
                    paddingLeft: 16,
                    paddingRight: 16,
                    height: 42,
                    borderRadius: 4,
                    border: "1px solid #cbcbcc",
                    background: "#fff",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  mask="999-9999-9999"
                  maskChar=""
                  placeholder="010-1234-5678"
                />
              )}
            />
            <Button
              style={{
                height: 42,
                marginLeft: 8,
                fontSize: isMobile ? 12 : 15,
                fontWeight: "500",
                color: "#474748",
                maxWidth: isMobile ? 110 : 130,
              }}
              onClick={(e: any) => {
                e.preventDefault();
                handleClickSendMobile();
              }}
            >
              인증번호 받기
            </Button>
          </InputWrapper>
          {sessionToken && (
            <InputWrapper>
              <Label />
              <Controller
                control={control}
                name={"verification_code"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                render={({ field }) => (
                  <Input
                    placeholder="인증번호"
                    style={{ height: 42 }}
                    errors={errors.verification_code?.message}
                    countDown={sessionToken}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
          )}
        </InputsWrapper>
        <Button
          style={{
            background: "#29292A",
            color: "white",
            border: "none",
            marginBottom: 12,
          }}
        >
          수정
        </Button>
      </form>
    </ContentContainer>
  );
}
