import OrderDetail from "../components/MyPage/OrderDetail";
import MyPageLayout from "../components/MyPageLayout";

export default function MyOrderDetailPage() {
  return (
    <MyPageLayout menu="order">
      <OrderDetail />
    </MyPageLayout>
  );
}
