import nollyAxios from "./nollyAxios";

export type ProductLike = {
  uuid: string;
  created: string;
  modified: string;
};

export function likeProduct(productUuid: string): Promise<ProductLike> {
  return nollyAxios
    .post(`/api/products/${productUuid}/product_likes`)
    .then(({ data }) => data);
}

export function removeLikeProduct(productLikeUuid: string): Promise<void> {
  return nollyAxios
    .delete(`/api/product_likes/${productLikeUuid}`)
    .then(({ data }) => data);
}
