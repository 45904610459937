import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { ReactComponent as Next } from "../assets/IcChevronRightSmall.svg";
import { CommonContainer } from "../components/Common";
import { userState } from "../store/authStore";
import { useEffect, useState } from "react";
import { Banner, getSideBanners } from "../api/bannerApi";
import BannerCarousel from "../components/BannerCarousel";

const MyPageContainer = styled(CommonContainer)`
  background: var(--grey-10, #fafafa);
  height: calc(100vh - 60px);
`;
const ContentContainer = styled(CommonContainer)`
  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px; */
`;
const Name = styled.div`
  display: flex;
  height: 80px;
  padding: 0px 14px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  background: var(--white, #fff);
  color: #000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.032px;
`;
const MenuWrapper = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: var(--white, #fff);
  border-bottom: 1px solid var(--grey-30, #efeff0);
`;
const BannerWrapper = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: var(--grey-20, #f9f9fa);
`;
const MenuTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
`;
export default function MobileMyMenuPage() {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userState);

  const [sideBanners, setSideBanners] = useState<Banner[]>([]);

  useEffect(() => {
    getSideBanners().then((data) => {
      setSideBanners(data.results);
    });
  }, []);

  return (
    <MyPageContainer>
      <ContentContainer>
        {user ? (
          <>
            <Name>{user?.username}님</Name>
            <MenuWrapper
              style={{ marginTop: 8 }}
              onClick={() => navigate("/mypage/order")}
            >
              <MenuTitle>주문내역</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/mypage/review")}>
              <MenuTitle>내 리뷰 관리</MenuTitle>
              <Next />
            </MenuWrapper>
            <BannerWrapper>
              <BannerCarousel
                banners={sideBanners}
                height={100}
                borderRadius={8}
                showIndicators={false}
                name="mob"
              />
            </BannerWrapper>
            <MenuWrapper onClick={() => navigate("/mypage/point")}>
              <MenuTitle>포인트</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/mypage/ask")}>
              <MenuTitle>1:1 문의</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/mypage/profile")}>
              <MenuTitle>개인정보수정</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper
              onClick={() => {
                resetUser();
                window.localStorage.removeItem("accessToken");
                window.localStorage.removeItem("refreshToken");
                navigate("/login");
              }}
            >
              <MenuTitle>로그아웃</MenuTitle>
            </MenuWrapper>
          </>
        ) : (
          <>
            <MenuWrapper onClick={() => navigate("/login")}>
              <MenuTitle>로그인</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/signup")}>
              <MenuTitle>회원가입</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/notices")}>
              <MenuTitle>공지사항</MenuTitle>
              <Next />
            </MenuWrapper>
            <MenuWrapper onClick={() => navigate("/help-center")}>
              <MenuTitle>고객센터</MenuTitle>
              <Next />
            </MenuWrapper>
          </>
        )}
      </ContentContainer>
    </MyPageContainer>
  );
}
