import styled from "@emotion/styled";
import { KakaoMap } from "../KakaoMap";
import { ProductTitle } from "./ProductCommon";

const ProductMapWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

export default function ProductMap({
  lat,
  lon,
  title,
  url,
}: {
  lat?: number;
  lon?: number;
  title?: string;
  url?: string;
}) {
  return (
    <ProductMapWrapper>
      <ProductTitle>찾아오는 길</ProductTitle>
      <KakaoMap lat={lat} lng={lon} title={title} url={url} />
    </ProductMapWrapper>
  );
}
