import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginationType } from "../../api/productApi";
import {
  Answer,
  Question,
  getAnswerList,
  getQuestionDetail,
  getQuestionList,
} from "../../api/questionApi";
import Button from "../Common/Button";
import Pagination from "../Common/Pagenation";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE } from "../../utils/common.util";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;

const RowContainer = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px 14px;
  }
`;
const Left = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 8px;
  }
`;
const Right = styled.div`
  display: flex;
  width: 160px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
const RightRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const RightMobile = styled.div`
  margin-right: 8px;
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
const LeftHeader = styled(Left)`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 600;
`;
const RightHeader = styled(Right)`
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const BottomWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;
const Content = styled.div`
  display: flex;
  padding: 40px 32.5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  background: var(--grey-20, #f9f9fa);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
`;
const QuestionContent = styled.div``;
const AnswerWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const AnswerTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
`;
const AnswerContent = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
`;
const AnswerDate = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 400;
`;
function OneToOneItem({ item }: { item: Question }) {
  const [isOpen, setIsOpen] = useState(false);
  const [answer, setAnswer] = useState<Answer | null>(null);
  useEffect(() => {
    if (item.has_answer && isOpen) {
      getAnswerList(item.uuid).then((t) => {
        if (t.results.length > 0) {
          setAnswer(t.results[0]);
        }
      });
    }
  }, [isOpen, item]);

  if (isMobile) {
    return (
      <>
        <Row key={`${item.uuid}`} onClick={() => setIsOpen((v) => !v)}>
          <Left>{item.title}</Left>
          <RightRow>
            <RightMobile>
              {dayjs(item.created).format("YYYY-MM-DD")}
            </RightMobile>
            <RightMobile>
              {item.has_answer ? "답변완료" : "답변대기"}
            </RightMobile>
          </RightRow>
        </Row>
        {isOpen && (
          <Content>
            <QuestionContent>{item.content}</QuestionContent>
            {answer && (
              <AnswerWrapper>
                <AnswerTitle>[답변]</AnswerTitle>
                <AnswerContent>{answer.content}</AnswerContent>
                <AnswerDate>
                  {dayjs(answer.created).format("YYYY-MM-DD")}
                </AnswerDate>
              </AnswerWrapper>
            )}
          </Content>
        )}
      </>
    );
  }
  return (
    <>
      <Row key={`${item.uuid}`} onClick={() => setIsOpen((v) => !v)}>
        <Left>{item.title}</Left>
        <Right>{dayjs(item.created).format("YYYY-MM-DD")}</Right>
        <Right>{item.has_answer ? "답변완료" : "답변대기"}</Right>
      </Row>
      {isOpen && (
        <Content>
          <QuestionContent>{item.content}</QuestionContent>
          {answer && (
            <AnswerWrapper>
              <AnswerTitle>[답변]</AnswerTitle>
              <AnswerContent>{answer.content}</AnswerContent>
              <AnswerDate>
                {dayjs(answer.created).format("YYYY-MM-DD")}
              </AnswerDate>
            </AnswerWrapper>
          )}
        </Content>
      )}
    </>
  );
}

export default function OneToOneHistory() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState<null | PaginationType<Question>>(
    null
  );

  useEffect(() => {
    if (isMobile) {
      getQuestionList(5, (page - 1) * 5).then(setQuestions);
    } else {
      getQuestionList(PAGE_SIZE, (page - 1) * PAGE_SIZE).then(setQuestions);
    }
  }, [page]);

  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>1:1 문의</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      {isMobile && (
        <BottomWrapper>
          <Button
            style={{
              width: "100%",
              height: 42,
              fontSize: 15,
              fontWeight: 500,
              background: "#29292A",
              color: "white",
            }}
            onClick={() => navigate(`/mypage/ask/create`)}
            // onClick={onCreateClick}
          >
            문의하기
          </Button>
        </BottomWrapper>
      )}
      <RowContainer>
        {!isMobile && (
          <Row>
            <LeftHeader>제목</LeftHeader>
            <RightHeader>작성일</RightHeader>
            <RightHeader>답변상태</RightHeader>
          </Row>
        )}
        {questions?.results.map((t: Question) => (
          <OneToOneItem item={t} key={t.uuid} />
        ))}
        {isMobile && (
          <BottomWrapper style={{ justifyContent: "center", marginTop: 15 }}>
            <Pagination
              curIndex={page}
              paginate={setPage}
              maxCount={questions?.count}
              pageSize={5}
            />
          </BottomWrapper>
        )}
        {!isMobile && (
          <BottomWrapper>
            <Pagination
              curIndex={page}
              paginate={setPage}
              maxCount={questions?.count}
              pageSize={PAGE_SIZE}
            />
            <Button
              style={{
                width: 95,
                height: 42,
                fontSize: 15,
                fontWeight: 500,
                background: "#29292A",
                color: "white",
              }}
              onClick={() => navigate(`/mypage/ask/create`)}
              // onClick={onCreateClick}
            >
              문의하기
            </Button>
          </BottomWrapper>
        )}
      </RowContainer>
    </Container>
  );
}
