import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notice, getNoticeList } from "../api/noticeApi";
import { PaginationType } from "../api/productApi";
import { CommonContainer } from "../components/Common";
import NoticeItem from "../components/Notice/NoticeItem";
import Pagination from "../components/Common/Pagenation";
import useQuery from "../hooks/useQuery";
import { NoticeTopic } from "../types/enums";
import { useRecoilState, useRecoilValue } from "recoil";
import { noticeState } from "../store/commonStore";
import { PAGE_SIZE } from "../utils/common.util";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ListTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ListDescription = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 400;
`;
const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
interface CategoryItemWrapperProps {
  isActive: boolean;
}
const CategoryItemWrapper = styled.div<CategoryItemWrapperProps>`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--grey-100, #F00)"
      : " 1px solid var(--grey-50, #CBCBCC)"};
  background: var(--white, #fff);
  color: ${(props) => (props.isActive ? "#F00" : "#474748")};
  font-size: 15px;
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
  cursor: pointer;
`;

const NoticesWrapper = styled.div`
  width: 100%;
`;

export default function NoticePage() {
  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<Notice>>(null);
  const [topic, setTopic] = useRecoilState(noticeState);

  useEffect(() => {
    getNoticeList(PAGE_SIZE, (page - 1) * PAGE_SIZE, topic).then(setRes);
  }, [page, topic]);

  return (
    <AskContainer>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            <ListTitle>공지사항</ListTitle>
            <ListDescription>
              매일 새로운 경험을! 함께 놀자 놀리!
            </ListDescription>
          </ListTitleWrapper>
        </HeaderWrapper>
        <CategoryWrapper>
          {Object.keys(NoticeTopic).map((t) => (
            <CategoryItemWrapper
              key={t}
              isActive={topic === t}
              onClick={() => setTopic(t as any)}
            >
              {NoticeTopic[t as keyof typeof NoticeTopic]}
            </CategoryItemWrapper>
          ))}
        </CategoryWrapper>
        <NoticesWrapper>
          {res?.results.map(({ title, created, uuid }) => (
            <NoticeItem
              key={uuid}
              uuid={uuid}
              title={title}
              createdAt={created}
              category={topic}
              topic={topic}
            />
          ))}
        </NoticesWrapper>
        <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} />
      </ContentContainer>
    </AskContainer>
  );
}
