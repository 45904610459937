import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import ReactModal from "react-modal";
import Select from "react-select";
import { createInquiry } from "../api/inquiryApi";
import { CommonContainer } from "../components/Common";
import Button from "../components/Common/Button";
import Input from "../components/Common/Input";
import Radio from "../components/Common/Radio";
import TextArea from "../components/Common/TextArea";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const ListTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ListDescription = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 400;
`;

const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const InputLabelWrapper = styled.div`
  display: flex;
  width: 110px;
  min-width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
`;
const RequiredSpan = styled.span`
  color: #f00;
  font-size: 14px;
  font-weight: 400;
`;

const AgreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const AgreeContent = styled.div`
  display: flex;
  height: 300px;
  padding: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grey-20, #f9f9fa);
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
  overflow: scroll;
`;
const AgreeTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
`;
const SecretWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const SecretText = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;
const IframWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface InputTypes {
  company_name: string;
  address_a: string;
  address_b: string;
  poc_name: string;
  poc_mobile_no: string;
  poc_email: string;
  content: string;
}

const options = [
  { value: "naver.com", label: "naver.com" },
  { value: "gmail.com", label: "gmail.com" },
  { value: "hanmail.net", label: "hanmail.net" },
  { value: "nate.com", label: "nate.com" },
  { value: "kakao.com", label: "kakao.com" },
  { value: "direct", label: "직접입력" },
];
function iframe() {
  return {
    __html:
      '<iframe src="./agree2.html" width="100%" height="100%" frameBorder="0" ></iframe>',
  };
}

export default function AdoptPage() {
  const [openPostcode, setOpenPostcode] = useState(false);
  const [emailA, setEmailA] = useState("");
  const [emailB, setEmailB] = useState("");
  const [agree, setAgree] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("poc_email", emailA + "@" + emailB);
  }, [emailA, emailB]);

  const onSubmit = async (inputValues: InputTypes) => {
    if (!agree) {
      alert("개인정보수집에 관한 동의를 해주세요.");
      return;
    }
    createInquiry(inputValues).then(() => {
      alert("서비스 도입이 신청되었습니다.");
      setEmailA("");
      setEmailB("");
      reset();
    });
  };
  const selectAddress = (data: any) => {
    setValue("address_a", data.address);
    setOpenPostcode(false);
  };
  return (
    <AskContainer>
      <ReactModal
        isOpen={openPostcode}
        ariaHideApp={true}
        onRequestClose={() => setOpenPostcode(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <DaumPostcodeEmbed
          onComplete={selectAddress} // 값을 선택할 경우 실행되는 이벤트
          autoClose={true} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
          defaultQuery="" // 팝업을 열때 기본적으로 입력되는 검색어
        />
        <Button
          onClick={() => setOpenPostcode(false)}
          style={{ marginTop: 10 }}
        >
          닫기
        </Button>
      </ReactModal>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            <ListTitle>기업 도입 안내</ListTitle>
            <ListDescription>
              문의 주신 사항에 대해 영업일 기준 48시간 이내에 담당자님의
              이메일(또는 유선)으로 안내 드립니다.
            </ListDescription>
          </ListTitleWrapper>
        </HeaderWrapper>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <FormContainer>
            <InputRowWrapper>
              <InputLabelWrapper>
                기업명 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"company_name"}
                rules={{
                  required: "기업명을 입력해주세요.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="기업명을 입력해주세요."
                    style={{ height: 42 }}
                    errors={errors.company_name?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper style={{ alignItems: "flex-start" }}>
              <InputLabelWrapper style={{ marginTop: 10 }}>
                주소 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"address_a"}
                rules={{
                  required: "주소를 입력해주세요.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="주소"
                    style={{ height: 42 }}
                    errors={errors.address_a?.message}
                    {...field}
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474748",
                  maxWidth: 80,
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  setOpenPostcode(true);
                }}
              >
                검색
              </Button>
            </InputRowWrapper>
            <InputRowWrapper style={{ alignItems: "flex-start" }}>
              <InputLabelWrapper style={{ marginTop: 10 }}></InputLabelWrapper>
              <Controller
                control={control}
                name={"address_b"}
                rules={{
                  required: "나머지 주소를 입력해주세요.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="나머지 주소"
                    style={{ height: 42 }}
                    errors={errors.address_b?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper>
                담당자 명 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"poc_name"}
                rules={{
                  required: "담당자 명을 입력해주세요.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="담당자 명을 입력해주세요."
                    style={{ height: 42 }}
                    errors={errors.poc_name?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper>
                연락처 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"poc_mobile_no"}
                rules={{
                  required: "010-0000-0000",
                }}
                defaultValue={""}
                render={({ field }) => (
                  // <Input
                  //   placeholder="010-0000-0000"
                  //   style={{ height: 42 }}
                  //   errors={errors.poc_mobile_no}
                  //   {...field}
                  // />
                  <InputMask
                    {...field}
                    style={{
                      width: "100%",
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: 42,
                      borderRadius: 4,
                      border: "1px solid #cbcbcc",
                      background: "#fff",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    mask="999-9999-9999"
                    maskChar=""
                    placeholder="010-1234-5678"
                  />
                )}
              />
            </InputRowWrapper>
            <InputRowWrapper>
              <InputLabelWrapper>
                이메일 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Input
                placeholder=""
                style={{ height: 42, width: "100%" }}
                value={emailA}
                onChange={(e) => setEmailA(e.target.value)}
              />
              <div>@</div>
              <Input
                placeholder=""
                style={{ height: 42, width: "100%" }}
                value={emailB}
                onChange={(e) => setEmailB(e.target.value)}
              />
              <Select
                menuPlacement="auto"
                menuPosition="fixed"
                options={options}
                isSearchable={false}
                onChange={(t) => {
                  if (t?.value === "direct") setEmailB("");
                  else setEmailB(t?.value || "");
                }}
                styles={{
                  container: (base) => ({ width: "220px", height: "42px" }),
                  control: (base) => ({
                    ...base,
                    width: "220px",
                    height: "100%",
                    cursor: "pointer",
                  }),
                }}
                placeholder={"직접 입력"}
                components={{ IndicatorSeparator: () => null }}
              />
            </InputRowWrapper>
            <InputRowWrapper style={{ alignItems: "flex-start" }}>
              <InputLabelWrapper style={{ marginTop: 10 }}>
                문의사항 <RequiredSpan>*</RequiredSpan>
              </InputLabelWrapper>
              <Controller
                control={control}
                name={"content"}
                rules={{
                  required:
                    "희망하시는 서비스 운영방식 및 구체적인 희망사항과 같은 서비스 관련 정보를 기입 부탁드립니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextArea
                    placeholder="희망하시는 서비스 운영방식 및 구체적인 희망사항과 같은 서비스 관련 정보를 기입 부탁드립니다."
                    style={{ height: 268 }}
                    errors={errors.content?.message}
                    {...field}
                  />
                )}
              />
            </InputRowWrapper>

            <AgreeWrapper>
              <AgreeTitle>
                개인정보수집에 관한 동의 <RequiredSpan>*</RequiredSpan>
              </AgreeTitle>
              <AgreeContent>
                {/* <IframWrapper dangerouslySetInnerHTML={iframe()} /> */}
                <iframe
                  src="./agree2.html"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  onLoad={() => {
                    const iframe = document.querySelector("iframe");
                    const innerDoc =
                      iframe?.contentDocument ||
                      iframe?.contentWindow?.document;

                    // 폰트 스타일을 추가
                    if (innerDoc) {
                      const style = innerDoc?.createElement("style");
                      if (style) {
                        style.innerHTML = `
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 100;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
                                    format("opentype");
                              }
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 300;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
                                    format("opentype");
                              }
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 400;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
                                    format("opentype");
                              }
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 500;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
                                    format("opentype");
                              }
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 700;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
                                    format("opentype");
                              }
                              @font-face {
                                font-family: "Noto Sans KR";
                                font-style: normal;
                                font-weight: 900;
                                src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
                                    format("woff2"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
                                    format("woff"),
                                  url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
                                    format("opentype");
                              }
                              * {
                                font-family: "Noto Sans KR", sans-serif !important;
                              }
                        `;
                        innerDoc.head.appendChild(style);
                      }
                    }
                  }}
                ></iframe>
              </AgreeContent>
              <InputRowWrapper>
                <SecretWrapper onClick={() => setAgree((v) => !v)}>
                  <Radio checked={agree} />
                  <SecretText>
                    위의 '개인정보수집 및 이용'에 동의합니다.
                  </SecretText>
                </SecretWrapper>
              </InputRowWrapper>
            </AgreeWrapper>

            <InputRowWrapper style={{ justifyContent: "center" }}>
              <Button
                style={{
                  background: "#29292A",
                  color: "white",
                  border: "none",
                  marginBottom: 12,
                  width: 400,
                }}
              >
                등록
              </Button>
            </InputRowWrapper>
          </FormContainer>
        </form>
      </ContentContainer>
    </AskContainer>
  );
}
