import axios from "axios";
import { setRecoil, getRecoil } from "recoil-nexus";
import { loadingState } from "../store/commonStore";

const storage = window.localStorage;

const nollyAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

nollyAxios.interceptors.request.use(function customInterceptorRequest(_config) {
  const config = { ..._config };
  const access = storage.getItem("accessToken");
  config.headers.Authorization = access;

  const { url } = config;
  if (!url?.includes("option_days")) {
    const isLoading = getRecoil(loadingState);
    if (!isLoading) setRecoil(loadingState, true);
  }
  return config;
});

nollyAxios.interceptors.response.use(
  function customInterceptorResponse(res) {
    setRecoil(loadingState, false);
    if (res.data.access) {
      storage.setItem("accessToken", `Bearer ${res.data.access}`);
    }
    if (res.data.refresh) {
      storage.setItem("refreshToken", res.data.refresh);
    }
    return res;
  },
  function customInterceptorError(err) {
    console.log(err);
    setRecoil(loadingState, false);
    if (err.response?.status === 401) {
      storage.removeItem("accessToken");
      storage.removeItem("refreshToken");
      window.location.pathname = "/";
      throw err;
    } else if (err.response?.status === 500) {
      alert("서버에러");
      return;
    }
    throw err;
  }
);

export default nollyAxios;
