import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Faq, FaqTopic, getFaqList } from "../api/faqApi";
import { PaginationType } from "../api/productApi";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import SearchInput from "../components/Header/SearchInput";
import FaqItem from "../components/HelpCenter/FaqItem";
import Pagination from "../components/Common/Pagenation";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loginPopupState } from "../store/commonStore";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE } from "../utils/common.util";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ListTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const SubTitle = styled.div`
  color: var(--black, #000);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
interface CategoryItemWrapperProps {
  isActive: boolean;
}
const CategoryItemWrapper = styled.div<CategoryItemWrapperProps>`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--grey-100, #F00)"
      : " 1px solid var(--grey-50, #CBCBCC)"};
  background: var(--white, #fff);
  color: ${(props) => (props.isActive ? "#F00" : "#474748")};
  font-size: 15px;
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    white-space: nowrap;
  }
`;

const NoticesWrapper = styled.div`
  width: 100%;
`;
const MoreWrapper = styled.div`
  display: flex;
  padding: 14px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--grey-20, #f9f9fa);
`;
const MoreTitle = styled.div`
  color: var(--black, #000);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.036px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export default function HelpCenterPage() {
  const [topic, setTopic] = useState<undefined | FaqTopic>(undefined);
  const [q, setQ] = useState("");

  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<Faq>>(null);

  const navigate = useNavigate();
  const setLoginPopup = useSetRecoilState(loginPopupState);

  useEffect(() => {
    getFaqList(PAGE_SIZE,  (page - 1) * PAGE_SIZE, topic, q).then(setRes);
  }, [page, topic, q]);

  return (
    <AskContainer>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            <ListTitle>고객센터</ListTitle>
          </ListTitleWrapper>
        </HeaderWrapper>
        <SubTitleWrapper>
          <SubTitle>자주 묻는 질문</SubTitle>
        </SubTitleWrapper>
        <SearchInput
          style={{
            background: "white",
            border: "1px solid #E0E0E1",
            width: "100%",
          }}
          placeholder="키워드를 입력하세요."
          onSearch={setQ}
        />
        <CategoryWrapper>
          <CategoryItemWrapper
            isActive={topic === undefined}
            onClick={() => setTopic(undefined)}
          >
            전체
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "INFO"}
            onClick={() => setTopic("INFO")}
          >
            이용안내
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "USER"}
            onClick={() => setTopic("USER")}
          >
            회원정보
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "PAYMENT"}
            onClick={() => setTopic("PAYMENT")}
          >
            결제/환불
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "PARTNER"}
            onClick={() => setTopic("PARTNER")}
          >
            입점 및 제휴
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "OTHER"}
            onClick={() => setTopic("OTHER")}
          >
            기타
          </CategoryItemWrapper>
        </CategoryWrapper>
        <NoticesWrapper>
          {res?.results.map(({ title, uuid, created }) => (
            <FaqItem
              uuid={uuid}
              key={uuid}
              title={title}
              category={""}
              createdAt={created}
            />
          ))}
        </NoticesWrapper>
        <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} pageSize={PAGE_SIZE} />
        <MoreWrapper>
          <MoreTitle>찾는 질문이 없으신가요?</MoreTitle>
          <Button
            style={{
              width: 92,
              height: 42,
              padding: "0px 16px",
              background: "#29292A",
              color: "white",
              fontSize: isMobile ? 12 : 15,
              fontWeight: 500,
            }}
            onClick={() => {
              if (!window.localStorage.getItem("accessToken")) {
                setLoginPopup({ next: "/mypage/ask/create", state: {} });
                return;
              }
              navigate(`/mypage/ask/create`);
            }}
          >
            문의하기
          </Button>
        </MoreWrapper>
      </ContentContainer>
    </AskContainer>
  );
}
