import styled from "@emotion/styled";
import { ProductTitle } from "./ProductCommon";

const IntroductionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

const IntroductionImg = styled.img`
  width: 100%;
`;

export default function ProductIntroduction({
  image,
  content,
}: {
  image: string;
  content: string;
}) {
  return (
    <IntroductionWrapper>
      <ProductTitle>상품소개</ProductTitle>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        style={{ width: "100%" }}
      />
      <IntroductionImg src={image} />
    </IntroductionWrapper>
  );
}
