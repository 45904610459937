import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { CommonContainer } from "../components/Common";
import { IsActiveProps } from "../components/Product/ProductCommon";
import { MyPageMenu, isMyPageMenu } from "../types/enums";

const MyPageContainer = styled(CommonContainer)`
  padding: 80px 14px 80px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
`;
const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const SideBarHeader = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuItem = styled.li<IsActiveProps>`
  display: flex;
  width: 200px;
  padding: 14px 0px 14px 8px;
  align-items: flex-start;
  color: ${(props) => (props.isActive ? "#f00" : "#707071")};
  font-size: 16px;
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  flex: 1 0 0;
  width: 100%;
`;

const MyPageLayout: React.FunctionComponent<
  React.PropsWithChildren<{ menu: keyof typeof MyPageMenu }>
> = ({ children, menu }) => {
  const navigate = useNavigate();
  return (
    <MyPageContainer>
      <ContentContainer>
        <SideBar>
          <SideBarHeader>마이놀리</SideBarHeader>
          <MenuWrapper>
            <MenuItem
              onClick={() => navigate(`/mypage/order`)}
              isActive={menu === "order"}
            >
              주문내역
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/like")}
              isActive={menu === "like"}
            >
              찜한 상품 관리
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/review")}
              isActive={menu === "review"}
            >
              내 리뷰 관리
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/point")}
              isActive={menu === "point"}
            >
              포인트
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/ask")}
              isActive={menu === "ask"}
            >
              1:1 문의
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/profile")}
              isActive={menu === "profile"}
            >
              개인정보수정
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/mypage/exit")}
              isActive={menu === "exit"}
            >
              회원탈퇴
            </MenuItem>
          </MenuWrapper>
        </SideBar>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentContainer>
    </MyPageContainer>
  );
};

export default MyPageLayout;
