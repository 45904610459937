import ReviewCreate from "../components/MyPage/ReviewCreate";
import MyPageLayout from "../components/MyPageLayout";

export default function MyReviewCreatePage() {
  return (
    <MyPageLayout menu="review">
      <ReviewCreate />
    </MyPageLayout>
  );
}
