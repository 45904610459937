import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { Banner, getMobileBanners } from "../api/bannerApi";
import { PaginationType, Product, productList } from "../api/productApi";
import BannerCarousel from "../components/BannerCarousel";
import { CommonContainer } from "../components/Common";
import Pagination from "../components/Common/Pagenation";
import ContentItem from "../components/Main/ContentItem";
import ListHeader from "../components/Product/ListHeader";
import { IsActiveProps } from "../components/Product/ProductCommon";
import { ProductSort, ProductTopic } from "../types/enums";
import { PAGE_SIZE, PRODUCTS_PAGE_SIZE } from "../utils/common.util";

const ListContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;
const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    gap: 16px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const CategoryItemWrapper = styled.div<IsActiveProps>`
  display: flex;
  height: 42px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--grey-100, #29292a)"
      : " 1px solid var(--grey-50, #CBCBCC)"};
  background: var(--white, #fff);
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
`;
const BannerItem = styled.div`
  cursor: pointer;
  width: 100%;
`;
const CarouselImg = styled.div`
  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
`;

// sold_count|review_count|current_price

export default function SearchPage() {
  const [topic, setTopic] = useState<keyof typeof ProductTopic | undefined>(
    undefined
  );
  const [sortBy, setSortBy] = useState<keyof typeof ProductSort>("sold_count");
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<Product>>(null);

  const [sideBanners, setSideBanners] = useState<Banner[]>([]);
  const q = searchParams.get("q") || undefined; // test

  useEffect(() => {
    productList(topic, q, undefined, PRODUCTS_PAGE_SIZE, (page - 1) * PRODUCTS_PAGE_SIZE, sortBy).then(setRes);
  }, [q, topic, page, sortBy]);

  useEffect(() => {
    getMobileBanners().then((data) => {
      setSideBanners(data.results);
    });
  }, []);

  if (!q && isMobile) {
    return (
      <ListContainer>
        <ContentContainer>
          <BannerCarousel
            banners={sideBanners}
            height={257}
            showIndicators={true}
            name="search"
          />
          {/* {promotions?.results.map((p) => {
            return (
              <a
                href={`/promotions/${p.uuid}`}
                style={{ width: "100%", height: 240 }}
              >
                <img
                  key={p.uuid}
                  src={p.image}
                  style={{ width: "100%", height: 240 }}
                />
              </a>
            );
          })} */}
        </ContentContainer>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <ContentContainer>
        <ListHeader
          title={`"${q || ""}" 검색결과`}
          subTitle={res?.count + ""}
          sortBy={sortBy}
          onChangeSortBy={setSortBy}
        />
        <CategoryWrapper>
          <CategoryItemWrapper
            isActive={topic === undefined}
            onClick={() => setTopic(undefined)}
          >
            전체
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "ACTIVITY"}
            onClick={() => setTopic("ACTIVITY")}
          >
            액티비티
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "WORKACTION"}
            onClick={() => setTopic("WORKACTION")}
          >
            워케이션
          </CategoryItemWrapper>
          <CategoryItemWrapper
            isActive={topic === "CLASS"}
            onClick={() => setTopic("CLASS")}
          >
            클래스
          </CategoryItemWrapper>
        </CategoryWrapper>
        <ItemsWrapper>
          {res?.results.map((content) => (
            <ContentItem content={content} key={`${content.uuid}`} />
          ))}
        </ItemsWrapper>
        <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} pageSize={PRODUCTS_PAGE_SIZE} />
      </ContentContainer>
    </ListContainer>
  );
}
