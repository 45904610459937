import OneToOneHistory from "../components/MyPage/OneToOneHistory";
import MyPageLayout from "../components/MyPageLayout";

export default function MyAskPage() {
  return (
    <MyPageLayout menu="ask">
      <OneToOneHistory />
    </MyPageLayout>
  );
}
