import Profile from "../components/MyPage/Profile";
import MyPageLayout from "../components/MyPageLayout";

export default function MyProfilePage() {
  return (
    <MyPageLayout menu="profile">
      <Profile />
    </MyPageLayout>
  );
}
