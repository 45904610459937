import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Career {
  uuid: string;
  title: string;
  is_open: boolean;
  created: string;
  modified: string;
}

export function getCareerList(
  limit?: number,
  offset?: number,
): Promise<PaginationType<Career>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}`;
  return nollyAxios.get(`/api/careers` + query).then(({ data }) => data);
}

export interface CareerDetail extends Career {
  content: string;
}

export function getCareerDetail(uuid: string): Promise<CareerDetail> {
  return nollyAxios.get(`/api/careers/${uuid}`).then(({ data }) => data);
}
