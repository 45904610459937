import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { me } from "../../api/authApi";
import {
  PointPurchase,
  getPointPruchases,
  pointPurchase,
} from "../../api/pointPurchaseApi";
import { PaginationType } from "../../api/productApi";
import { userState } from "../../store/authStore";
import { PayMethods, PaymentMethodEnum, dateOptions } from "../../types/enums";
import {
  RequestPayParams,
  RequestPayResponse,
} from "../../utils/iamport.utils";
import Button from "../Common/Button";
import Pagination from "../Common/Pagenation";
import Radio from "../Common/Radio";
import { isMobile } from "react-device-detect";
import NumberInput from "../Common/NumberInput";
import { PAGE_SIZE } from "../../utils/common.util";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const ChargeWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 60px;
`;

const DetailTitle = styled.div`
  width: 100%;
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
`;
const DetailRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const DetailRowLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const DetailRowLabel = styled.div`
  display: flex;
  width: 110px;
  min-width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
`;
const DetailRowContent = styled.div`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--grey-100, #29292a);
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
`;
const DetailRowNotice = styled.div`
  padding: 0px 10px;
  flex: 1 0 0;
  color: #f00;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const HistoryHeader = styled.div`
  margin-top: 44px;
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
`;
const HistoryTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
`;
const HistoryRow = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
`;
const RowCommon = styled.div`
  display: flex;
  width: 160px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const RowContent = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 10px;
`;
const PaymentMethodEachWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;
const PaymentMethodTitle = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;

const options = [
  { value: "100000", label: "10만원" },
  { value: "200000", label: "20만원" },
  { value: "300000", label: "30만원" },
  { value: "400000", label: "40만원" },
  { value: "500000", label: "50만원" },
];

export default function PointHistory() {
  const [user, setUser] = useRecoilState(userState);
  const [point, setPoint] = useState<number | undefined>(undefined);
  const [paymentMethod, setPaymentMethod] =
    useState<keyof typeof PaymentMethodEnum>("welcome");
  const [paymentway, setPaymentWay] = useState("card");

  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<PointPurchase>>(null);

  const [date, setDate] = useState(dateOptions[0]);

  useEffect(() => {
    me().then(setUser);
  }, []);
  useEffect(() => {
    getPointPruchases(PAGE_SIZE, (page - 1) * PAGE_SIZE, date.value).then(
      setRes
    );
  }, [page, date]);

  const onClickPayment = () => {
    if (point) {
      pointPurchase(point)
        .then(() => {
          alert("포인트 충전 신청이 완료되었습니다.");
        })
        .catch(() => {
          alert("포인트 충전 신청을 실패하였습니다.");
        });
    }
    // if (!window.IMP) return;

    // const { IMP } = window;
    // IMP.init("imp76742540"); // 가맹점 식별코드

    // const data: RequestPayParams = {
    //   pg: paymentMethod, // PG사 : https://portone.gitbook.io/docs/sdk/javascript-sdk/payrq#undefined-1 참고
    //   pay_method: paymentway, // 결제수단
    //   merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
    //   amount: point || 0, // 결제금액
    //   name: "포인트 결제",
    //   buyer_name: user?.username,
    //   buyer_tel: user?.profile?.mobile_no || "010-1234-1234",
    //   buyer_email: user?.email,
    // };

    // IMP.request_pay(data, callback);
  };

  function callback(response: RequestPayResponse) {
    // const { success, error_msg, imp_uid } = response;
    // if (success) {
    //   if (imp_uid) {
    //     pointPurchase(imp_uid).then(() => {
    //       alert("결제 성공하였습니다.");
    //       me().then(setUser);
    //     });
    //   }
    // } else {
    //   alert(`결제 실패: ${error_msg}`);
    // }
  }

  const navigate = useNavigate();
  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>포인트</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      <ChargeWrapper>
        <DetailTitle>포인트 충전</DetailTitle>
        <DetailRow>
          <DetailRowLeft>
            <DetailRowLabel>현재 포인트</DetailRowLabel>
            <DetailRowContent>
              {user?.point?.amount.toLocaleString()}원
            </DetailRowContent>
          </DetailRowLeft>
          <Button
            style={{
              width: 105,
              height: 42,
              fontSize: 15,
              fontWeight: 500,
            }}
            onClick={() => navigate(`/mypage/point/refund`)}
          >
            환불
          </Button>
        </DetailRow>
        <DetailRow>
          <DetailRowLeft>
            <DetailRowLabel>포인트 충전</DetailRowLabel>
            <DetailRowContent>
              <NumberInput
                value={point}
                onSet={setPoint}
                onChange={(e) => setPoint(Number(e.currentTarget.value))}
                placeholder={"금액을 입력하세요."}
                localeString
              />
              {/* <Select
                isSearchable={false}
                menuPlacement="auto"
                menuPosition="fixed"
                onChange={(a) => setPoint(Number(a?.value))}
                options={options}
                styles={{
                  container: (base) => ({ width: "100%" }),
                  control: (base) => ({ ...base, cursor: "pointer" }),
                }}
                placeholder={"금액을 선택하세요."}
                components={{ IndicatorSeparator: () => null }}
              /> */}
            </DetailRowContent>
          </DetailRowLeft>
        </DetailRow>
        <DetailRow>
          {!isMobile && <DetailRowLabel />}
          <DetailRowNotice>
            포인트 충전은 무통장입금으로 진행됩니다. <br />
            충전 금액 기입 후 해당 금액을 아래 계좌(신한 100-036-553907 |
            주식회사 놀리)로 입금해 주시면 충전이 진행되며, 회원정보와
            입금자명은 반드시 일치해야 합니다.
            <br />
            반영은 영업일 기준 1일 이내 완료됩니다.
            <br />
            이상 시 1:1 문의로 연락주시면 빠른 해결이 가능합니다.
          </DetailRowNotice>
        </DetailRow>
        <Button
          style={{
            width: isMobile ? "100%" : 65,
            height: 42,
            fontSize: 15,
            fontWeight: 500,
            backgroundColor: "#29292A",
            color: "white",
          }}
          onClick={onClickPayment}
        >
          충전
        </Button>
        <HistoryHeader>
          <HistoryTitle>충전 내역</HistoryTitle>
          {/* <Button
            style={{
              width: 120,
              height: 42,
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            조회기간 설정
          </Button> */}
          <Select
            isSearchable={false}
            menuPlacement="auto"
            menuPosition="fixed"
            value={date}
            onChange={(a) =>
              setDate({ label: a?.label || "", value: a?.value || "" })
            }
            options={dateOptions}
            styles={{
              container: (base) => ({ width: "180px" }),
              control: (base) => ({ ...base, cursor: "pointer" }),
            }}
            placeholder={"조회기간 설정"}
            components={{ IndicatorSeparator: () => null }}
          />
        </HistoryHeader>
        {res?.results.map((t) => {
          const { uuid, created, amount_spent, purchase_method } = t;
          return (
            <HistoryRow key={`like-items-${uuid}`}>
              <RowCommon>{dayjs(created).format("YYYY-MM-DD")}</RowCommon>
              <RowCommon>충전</RowCommon>
              <RowCommon>
                {PayMethods[purchase_method as keyof typeof PayMethods] || "-"}
                {/* {PayMethods[purchase_method} */}
              </RowCommon>
              <RowContent>+{amount_spent.toLocaleString()}원</RowContent>
            </HistoryRow>
          );
        })}
        <Pagination
          curIndex={page}
          paginate={setPage}
          maxCount={res?.count}
          pageSize={PAGE_SIZE}
        />
      </ChargeWrapper>
    </Container>
  );
}
