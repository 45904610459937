import styled from "@emotion/styled";
import React from "react";
import RadioIcon from "../../assets/RadioIcon.svg";
import RadioIconActive from "../../assets/RadioIconActive.svg";

const RadioContainer = styled.img`
  width: 24px;
  height: 24px;
`;

interface Props extends React.PropsWithChildren {
  checked: boolean;
  style?: React.CSSProperties;
}

const Radio: React.FunctionComponent<Props> = ({ checked = false, style }) => {
  const svg = checked ? RadioIconActive : RadioIcon;
  return <RadioContainer src={svg} style={style} />;
};

export default Radio;
