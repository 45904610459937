import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { isMobile } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { signup, validateEmail, validateUsername } from "../api/authApi";
import { getCompanyNameByCode } from "../api/companyApi";
import { registerSms, verifySms } from "../api/smsApi";
import { CommonContainer } from "../components/Common";
import Button from "../components/Common/Button";
import Input from "../components/Common/Input";
import Radio from "../components/Common/Radio";

const LoginContainer = styled(CommonContainer)`
  padding: 80px 14px 120px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 60px;
`;
const Desc = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
`;
const Mark = styled.span`
  color: #f00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
`;
const InputsWrapper = styled.div`
  margin-bottom: 60px;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Label = styled.div`
  min-width: 110px;
  width: 110px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const AgreeWrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;
const SubTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: left;
`;
const AgreeRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  cursor: pointer;
`;
const AgreeTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 18px;
  font-weight: 500;
`;
const TermsTitle = styled.div`
  flex: 1;
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;
const TermsLink = styled.a`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
`;
const CompanyName = styled.div`
  color: var(--grey-80, #707071);
  font-size: 12px;
  font-weight: 400;
`;
const ErrorText = styled.div`
  color: var(--red-100, #f00);
  font-size: 8px;
  font-weight: 400;
`;

interface InputTypes {
  username: string;
  first_name: string;
  email: string;
  password_a: string;
  password_b: string;
  mobile_no: string;
  verification_code: string;
  address_a: string;
  address_b: string;
  session_token: string;
  birthday: string;
  company_code: string;
}

export default function SignupPage() {
  const navigate = useNavigate();
  const [openPostcode, setOpenPostcode] = useState(false);

  const [isUsernameVerified, setIsUsernameVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [sessionToken, setSessionToken] = useState<string | undefined>(undefined);
  const [isVerified, setIsVerified] = useState(false);

  const [essential1Agree, setEssential1Agree] = useState(false);
  const [essential2Agree, setEssential2Agree] = useState(false);
  const [ageAgree, setAgeAgree] = useState(false);
  const [optional1Agree, setOptional1Agree] = useState(false);
  const [optional2Agree, setOptional2Agree] = useState(false);
  const [comapnyName, setCompanyName] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<InputTypes>({
    shouldFocusError: false,
    mode: "onSubmit",
  });

  useEffect(() => {
    setIsEmailVerified(false);
  }, [watch("email")]);

  useEffect(() => {
    setIsUsernameVerified(false);
  }, [watch("username")]);

  useEffect(() => {
    setCompanyName("");
  }, [watch("company_code")]);

  const onSubmit = async (inputValues: InputTypes) => {
    if (inputValues.password_a !== inputValues.password_b) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    if (!isUsernameVerified) {
      alert("아이디 중복확인을 해주세요.");
      return;
    }
    if (!isEmailVerified) {
      alert("이메일 중복확인을 해주세요.");
      return;
    }
    if (!isVerified) {
      alert("휴대폰 인증을 해주세요.");
      return;
    }
    if (!essential1Agree || !essential2Agree || !ageAgree) {
      alert("필수 약관을 동의해주세요.");
      return;
    }
    signup(
      inputValues,
      essential1Agree,
      essential2Agree,
      optional1Agree,
      optional2Agree,
      ageAgree
    )
      .then((user) => {
        alert("회원가입이 완료되었습니다.");
        navigate("/login");
      })
      .catch((e) => {
        if (e.response) {
          const data = e.response.data;
          const { type, errors } = data;
          if (
            type === "validation_error" &&
            errors.some(
              (item: any) =>
                item.attr === "profile.mobile_no" && item.code === "unique"
            )
          ) {
            alert("이미 사용중인 휴대폰 번호입니다.");
          }
        } else {
          alert("회원가입에 실패하였습니다.");
        }
      });
  };

  const selectAddress = (data: any) => {
    setValue("address_a", data.address);
    setOpenPostcode(false);
  };

  const getSmsNum = async () => {
    try {
      const mobileNo = watch("mobile_no");
      if (!mobileNo) {
        alert("휴대폰 번호를 입력해주세요.");
        return;
      }
      if (!/^\d{3}-?\d{3,4}-?\d{4}$/.test(mobileNo)) {
        alert("올바른 휴대폰 번호를 입력해주세요.");
        return;
      }
      const numbered = mobileNo.replace(/[^0-9]/g, "");
      const { session_token } = await registerSms(numbered);
      setSessionToken(session_token);
      setValue("session_token", session_token);
    } catch (e) {}
    // console.log(mobileNo);
  };

  const verify = async () => {
    if (sessionToken === undefined) return;
    try {
      const mobileNo = watch("mobile_no");
      const verificationCode = watch("verification_code");
      const numbered = mobileNo.replace(/[^0-9]/g, "");
      await verifySms(numbered, sessionToken, verificationCode);
      setSessionToken("");
      setIsVerified(true);
      alert("휴대폰 인증에 성공하였습니다.");
    } catch (e) {
      alert("인증번호가 잘못 되었습니다.");
    }
  };

  const onAgreeAll = () => {
    if (
      essential1Agree &&
      essential2Agree &&
      optional1Agree &&
      optional2Agree &&
      ageAgree
    ) {
      setEssential1Agree(false);
      setEssential2Agree(false);
      setOptional1Agree(false);
      setOptional2Agree(false);
      setAgeAgree(false);
    } else {
      setEssential1Agree(true);
      setEssential2Agree(true);
      setOptional1Agree(true);
      setOptional2Agree(true);
      setAgeAgree(true);
    }
  };

  const _validateUsername = async () => {
    if (!watch("username")) {
      alert("아이디를 입력해주세요.")
      return;
    }
    if (errors["username"]) {
      alert(errors["username"].message);
      return;
    }
    try {
      const username = watch("username");
      await validateUsername(username);
      setIsUsernameVerified(true);
      alert("사용 가능한 아이디입니다.");
    } catch (e) {
      alert("해당 아이디는 이미 사용중입니다.");
    }
  };

  const _validateEmail = async () => {
    if (errors["email"]) {
      alert(errors["email"].message);
      return;
    }
    try {
      const email = watch("email");
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(email)) {
        alert("이메일 형식을 확인해주세요.");
        return;
      }

      await validateEmail(email);
      setIsEmailVerified(true);
      alert("사용 가능한 이메일입니다.");
    } catch (e) {
      alert("해당 이메일은 이미 사용중입니다.");
    }
  };

  const checkCompany = async () => {
    try {
      const code = watch("company_code");
      if (code.length < 4) {
        alert("회사 코드를 다시 확인해주세요.");
        return;
      }

      const { name } = await getCompanyNameByCode(code);
      if (!name) return alert("회사 코드를 다시 확인해주세요.");

      setCompanyName(name);
      alert("회사 코드가 확인되었습니다.");
    } catch (e) {
      alert("사용할 수 없는 회사 코드입니다.");
    }
  };

  return (
    <LoginContainer>
      <ReactModal
        isOpen={openPostcode}
        ariaHideApp={true}
        onRequestClose={() => setOpenPostcode(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        {/* 닫기 버튼 */}
        <DaumPostcodeEmbed
          onComplete={selectAddress} // 값을 선택할 경우 실행되는 이벤트
          autoClose={true} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
          defaultQuery="" // 팝업을 열때 기본적으로 입력되는 검색어
        />
        <Button
          onClick={() => setOpenPostcode(false)}
          style={{ marginTop: 10 }}
        >
          닫기
        </Button>
      </ReactModal>
      <ContentContainer>
        {!isMobile && <Title>회원가입</Title>}
        <Desc>
          <Mark>*</Mark> 필수 입력사항
        </Desc>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputsWrapper>
            <InputWrapper>
              <Label>
                아이디 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"username"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="아이디"
                    style={{ height: 42 }}
                    errors={errors.username?.message}
                    {...field}
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474748",
                  maxWidth: 92,
                }}
                disabled={isUsernameVerified}
                onClick={(e: any) => {
                  e.preventDefault();
                  _validateUsername();
                }}
              >
                중복확인
              </Button>
            </InputWrapper>
            <InputWrapper>
              <Label>
                비밀번호 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"password_a"}
                rules={{
                  required: "필수 항목입니다.",
                  minLength: {
                    message: "비밀번호는 최소 8자리 이상입니다.",
                    value: 8,
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="비밀번호 (최소 8자리)"
                    style={{ height: 42 }}
                    errors={errors.password_a?.message}
                    type={"password"}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>
                비밀번호 확인 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"password_b"}
                rules={{
                  required: "필수 항목입니다.",
                  validate: (val: string) => {
                    if (watch("password_a") != val) {
                      return "비밀번호가 일치하지 않습니다.";
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="비밀번호 확인"
                    style={{ height: 42 }}
                    errors={errors.password_b?.message}
                    type={"password"}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>
                이름 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"first_name"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="이름"
                    style={{ height: 42 }}
                    errors={errors.first_name?.message}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>
                이메일 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"email"}
                rules={{
                  required: "필수 항목입니다.",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    message: "이메일 형식을 확인해주세요.",
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="이메일"
                    style={{ height: 42 }}
                    errors={errors.email?.message}
                    {...field}
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474748",
                  maxWidth: 92,
                }}
                disabled={isEmailVerified}
                onClick={(e: any) => {
                  e.preventDefault();
                  _validateEmail();
                }}
              >
                중복확인
              </Button>
            </InputWrapper>
            <InputWrapper>
              <Label>
                휴대폰 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"mobile_no"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  // <Input
                  //   placeholder="000-0000-0000"
                  //   style={{ height: 42 }}
                  //   errors={errors.mobile_no}
                  //   {...field}
                  // />
                  <InputMask
                    {...field}
                    style={{
                      width: "100%",
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: 42,
                      borderRadius: 4,
                      border: "1px solid #cbcbcc",
                      background: "#fff",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    mask="999-9999-9999"
                    maskChar=""
                    placeholder="010-1234-5678"
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474748",
                  maxWidth: 120,
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  getSmsNum();
                }}
                // disabled={sessionToken !== undefined}
                disabled={isVerified}
              >
                인증번호 받기
              </Button>
            </InputWrapper>
            <InputWrapper>
              <Label />
              <Controller
                control={control}
                name={"verification_code"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="인증번호"
                    style={{ height: 42 }}
                    errors={errors.verification_code?.message}
                    countDown={sessionToken}
                    {...field}
                  />
                )}
              />
              {(sessionToken !== undefined) && (
                <Button
                  style={{
                    height: 42,
                    marginLeft: 8,
                    fontSize: 15,
                    fontWeight: "500",
                    color: "#474748",
                    maxWidth: 120,
                  }}
                  disabled={isVerified}
                  onClick={(e: any) => {
                    e.preventDefault();
                    verify();
                  }}
                >
                  인증하기
                </Button>
              )}
              {/* <Input placeholder="인증번호" style={{ height: 42 }} /> */}
            </InputWrapper>
            <InputWrapper>
              <Label>
                주소 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"address_a"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="주소"
                    style={{ height: 42 }}
                    errors={errors.address_a?.message}
                    {...field}
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474747",
                  maxWidth: 80,
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  setOpenPostcode(true);
                }}
              >
                검색
              </Button>
            </InputWrapper>
            <InputWrapper>
              <Label />
              <Controller
                control={control}
                name={"address_b"}
                rules={{
                  required: "필수 항목입니다.",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder="나머지 주소"
                    style={{ height: 42 }}
                    errors={errors.address_b?.message}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper style={{ marginBottom: errors.birthday ? 2 : 16 }}>
              <Label>
                생년월일 <Mark>*</Mark>
              </Label>
              <Controller
                control={control}
                name={"birthday"}
                rules={{
                  required: "필수 항목입니다.",
                  validate: (date) => {
                    const [year, month, day] = date.split("-").map(Number);
                    const currentDate = new Date();

                    if (year < 1900) {
                      return "년도를 다시 확인해주세요.";
                    }
                    if (year > currentDate.getFullYear()) {
                      return "현재 연도보다 큰 연도는 입력할 수 없습니다.";
                    }

                    if (month > 12) {
                      return "12월 이후의 월은 입력할 수 없습니다.";
                    }

                    if (day > 31) {
                      return "31일 이후의 날짜는 입력할 수 없습니다.";
                    }

                    const parsedDate = new Date(year, month - 1, day);
                    if (!parsedDate || parsedDate.getMonth() !== month - 1) {
                      return "올바른 날짜 형식(YYYY-MM-DD)으로 입력하세요.";
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <InputMask
                    {...field}
                    style={{
                      width: "100%",
                      paddingLeft: 16,
                      paddingRight: 16,
                      height: 42,
                      borderRadius: 4,
                      border: errors.birthday
                        ? "1px solid red"
                        : "1px solid #cbcbcc",
                      background: "#fff",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    mask="9999-99-99"
                    maskChar=""
                    placeholder="yyyy-mm-dd"
                  />
                  // <Input
                  //   placeholder="yyyy-mm-dd"
                  //   style={{ height: 42 }}
                  //   errors={errors.birthday}
                  //   {...field}
                  // />
                )}
              />
            </InputWrapper>
            {errors.birthday && (
              <InputWrapper>
                <Label />
                <ErrorText>{errors.birthday.message}</ErrorText>
              </InputWrapper>
            )}
            <InputWrapper>
              <Label>회사 코드</Label>
              <Controller
                control={control}
                name={"company_code"}
                rules={{}}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    placeholder=""
                    style={{ height: 42 }}
                    errors={errors.company_code?.message}
                    {...field}
                  />
                )}
              />
              <Button
                style={{
                  height: 42,
                  marginLeft: 8,
                  fontSize: 15,
                  fontWeight: "500",
                  color: "#474747",
                  maxWidth: 80,
                }}
                disabled={!!comapnyName}
                onClick={(e: any) => {
                  e.preventDefault();
                  checkCompany();
                }}
              >
                확인
              </Button>
            </InputWrapper>
            {comapnyName && (
              <InputWrapper>
                <Label />
                <CompanyName>{comapnyName}</CompanyName>
              </InputWrapper>
            )}
          </InputsWrapper>
          <AgreeWrapper>
            <SubTitle>
              이용약관 동의<Mark>*</Mark>
            </SubTitle>
            <AgreeRowWrapper onClick={() => onAgreeAll()}>
              <AgreeTitle>전체 동의합니다.</AgreeTitle>
              <Radio
                checked={
                  optional1Agree &&
                  optional2Agree &&
                  ageAgree &&
                  essential1Agree &&
                  essential2Agree
                }
              />
            </AgreeRowWrapper>
            <AgreeRowWrapper onClick={() => setEssential1Agree((v) => !v)}>
              <TermsTitle>이용약관 동의(필수)</TermsTitle>
              <TermsLink
                target={"_blank"}
                href="/terms"
                onClick={(e) => e.stopPropagation()}
              >
                약관보기
              </TermsLink>
              <Radio checked={essential1Agree} style={{ marginLeft: 16 }} />
            </AgreeRowWrapper>
            <AgreeRowWrapper onClick={() => setEssential2Agree((v) => !v)}>
              <TermsTitle>개인정보 수집 이용 동의 (필수)</TermsTitle>
              <TermsLink
                target={"_blank"}
                href="/privacy"
                onClick={(e) => e.stopPropagation()}
              >
                약관보기
              </TermsLink>
              <Radio checked={essential2Agree} style={{ marginLeft: 16 }} />
            </AgreeRowWrapper>
            <AgreeRowWrapper onClick={() => setOptional1Agree((v) => !v)}>
              <TermsTitle>개인정보 수집 이용 동의 (선택)</TermsTitle>
              <TermsLink
                target={"_blank"}
                href="/privacy-optional"
                onClick={(e) => e.stopPropagation()}
              >
                약관보기
              </TermsLink>
              <Radio checked={optional1Agree} style={{ marginLeft: 16 }} />
            </AgreeRowWrapper>
            <AgreeRowWrapper onClick={() => setOptional2Agree((v) => !v)}>
              <TermsTitle>혜택/정보 수신 동의 (선택)</TermsTitle>
              <TermsLink
                target={"_blank"}
                href="/marketing-agreement"
                onClick={(e) => e.stopPropagation()}
              >
                약관보기
              </TermsLink>
              <Radio checked={optional2Agree} style={{ marginLeft: 16 }} />
            </AgreeRowWrapper>
            <AgreeRowWrapper onClick={() => setAgeAgree((v) => !v)}>
              <TermsTitle>본인은 만 14세 이상입니다. (필수)</TermsTitle>
              <Radio checked={ageAgree} style={{ marginLeft: 16 }} />
            </AgreeRowWrapper>
          </AgreeWrapper>
          {/* <input type="submit" title="가입하기" /> */}
          <Button
            style={{
              background: "#29292A",
              color: "white",
              border: "none",
              marginBottom: 12,
            }}
          >
            가입하기
          </Button>
        </form>
      </ContentContainer>
    </LoginContainer>
  );
}
