import styled from "@emotion/styled";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IcAccount } from "../assets/bottomMenu/IcAccount.svg";
import { ReactComponent as IcAccountActive } from "../assets/bottomMenu/IcAccountActive.svg";
import { ReactComponent as IcHeart } from "../assets/bottomMenu/IcHeart.svg";
import { ReactComponent as IcHeartActive } from "../assets/bottomMenu/IcHeartActive.svg";
import { ReactComponent as IcHome } from "../assets/bottomMenu/IcHome.svg";
import { ReactComponent as IcHomeActive } from "../assets/bottomMenu/IcHomeActive.svg";
import { ReactComponent as IcMore } from "../assets/bottomMenu/IcMore.svg";
import { ReactComponent as IcMoreActive } from "../assets/bottomMenu/IcMoreActive.svg";
import { ReactComponent as IcSearch } from "../assets/bottomMenu/IcSearch.svg";
import { ReactComponent as IcSearchActive } from "../assets/bottomMenu/IcSearchActive.svg";

const BottomTabBarContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  z-index: 120;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--grey-40, #e0e0e1);
  background: var(--white, #fff);
  height: 60px;
  padding: 16px 28px;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div``;
const searchPathNames = ["/search", "/promotions"];

const BottomTabBar: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (pathname.includes('/login')) return null;
  if (pathname.includes('/signup')) return null;
  if (pathname.includes('/find-id')) return null;
  if (pathname.includes('/find-pw')) return null;
  if (pathname.includes('/pw-reset')) return null;

  if (pathname.includes('/introduction')) return null;
  if (pathname.includes('/partnership')) return null;
  if (pathname.includes('/adopt-enterprise')) return null;

  if (pathname.includes('/promotions')) return null;
  if (pathname.includes('/products')) return null;
  if (pathname.includes('/payment')) return null;
  if (pathname.includes('/terms')) return null;
  if (pathname.includes('/privacy')) return null;
  if (pathname.includes('/mypage') && (!pathname.includes('/mypage-menu') && (!pathname.includes('/mypage/like')))) return null;

  return (
    <BottomTabBarContainer>
      <ButtonWrapper onClick={() => navigate("/")}>
        {pathname === "/" ? <IcHomeActive /> : <IcHome />}
      </ButtonWrapper>
      <ButtonWrapper onClick={() => navigate("/categories")}>
        {pathname.includes("/categories") ? <IcMoreActive /> : <IcMore />}
      </ButtonWrapper>
      <ButtonWrapper onClick={() => navigate("/search")}>
        {pathname.includes("/search") ? <IcSearchActive /> : <IcSearch />}
      </ButtonWrapper>
      <ButtonWrapper onClick={() => navigate("/mypage/like")}>
        {pathname.includes("/mypage/like") ? <IcHeartActive /> : <IcHeart />}
      </ButtonWrapper>
      <ButtonWrapper onClick={() => navigate("/mypage-menu")}>
        {pathname.includes("/mypage-menu") ? (
          <IcAccountActive />
        ) : (
          <IcAccount />
        )}
      </ButtonWrapper>
    </BottomTabBarContainer>
  );
};

export default BottomTabBar;
