import { useCallback } from "react";
import { Link } from "react-router-dom";

interface GlobalErrorProps {
  error: any;
}

export default function GlobalError({ error }: GlobalErrorProps) {
  const clickHandler = useCallback(() => {
    window.location.replace("/");
  }, []);
  if (!error) {
    return <div>잘못된 페이지 입니다.</div>;
  }

  return (
    <div className="w-full h-full bg-kiki-room text-white flex flex-col justify-center items-center gap-[1.67vw]">
      <h1 className="text-[5.21vw] font-[600]">Oops!</h1>
      <h2 className="text-[3.13vw] font-[500]">{error.name}</h2>
      <div className="flex flex-col justify-center items-center">
        <p className="max-w-[40vw] text-[1.04vw] text-center leading-[150%]">
          {error.message}
        </p>
        <span className="text-[1.04vw] text-center leading-[150%]">
          Please go back to&nbsp;
          <Link to={"#"} onClick={clickHandler} className="text-kiki-default">
            home
          </Link>
          &nbsp;or&nbsp;
          <Link
            to={
              "https://docs.google.com/forms/d/e/1FAIpQLSee8-1LwV-2DgfamvFitP7wHerF_MTt4koEDpcqHDQ65kLHxA/viewform"
            }
            target="_blank"
            className="text-kiki-default"
          >
          </Link>
        </span>
      </div>
    </div>
  );
}
