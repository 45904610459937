import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { ProductReview, deleteReview } from "../../api/reviewApi";
import Button from "../Common/Button";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    gap: 16px;
    padding-bottom: 60px;
  }
`;
const Title = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 500;
`;
const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const DateWrapper = styled.div`
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;
const ContentWrapper = styled.pre`
  max-width: 753px;
  word-break: break-all;
  white-space: pre-line;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
`;
const ProductTitle = styled.div`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
`;
const UploadsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const ReviewImg = styled.img`
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 40px;
  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px 10px;
    background-color: white;
  }
`;

export default function ReviewDetail() {
  const location = useLocation();
  const review: ProductReview | null = location.state?.review || null;
  const navigate = useNavigate();

  if (!review) return <div />;
  return (
    <Container>
      <Title>{review.title}</Title>
      <RateWrapper>
        <Rating
          initialValue={review.review_point}
          fillColor="#F00"
          emptyColor="#efeff0"
          disableFillHover
          allowHover={false}
          SVGstyle={{ width: 24 }}
        />
        <DateWrapper>{dayjs(review.created).format("YYYY-MM-DD")}</DateWrapper>
      </RateWrapper>
      <ContentWrapper>{review.content}</ContentWrapper>
      <ProductTitle>{review.product.title}</ProductTitle>
      <UploadsWrapper>
        {review.image_urls.map((t: string) => (
          <ReviewImg src={t} />
        ))}
      </UploadsWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            width: isMobile ? "100%" : 65,
            height: 42,
            fontSize: 15,
            fontWeight: 500,
          }}
          onClick={() => {
            if (window.confirm("리뷰를 삭제하시겠습니까?")) {
              deleteReview(review.uuid).finally(() => {
                navigate(-1);
              });
            }
          }}
        >
          삭제
        </Button>
        {/* <Button
          style={{
            width: 60,
            height: 42,
            fontSize: 15,
            fontWeight: 500,
            background: "#29292A",
            color: "white",
          }}
          // onClick={onEditClick}
        >
          수정
        </Button> */}
      </ButtonsWrapper>
    </Container>
  );
}
