import ReviewHistory from "../components/MyPage/ReviewHistory";
import MyPageLayout from "../components/MyPageLayout";

export default function MyReviewPage() {
  return (
    <MyPageLayout menu="review">
      <ReviewHistory />
    </MyPageLayout>
  );
}
