import PointRefund from "../components/MyPage/PointRefund";
import MyPageLayout from "../components/MyPageLayout";

export default function MyPointRefundPage() {
  return (
    <MyPageLayout menu="point">
      <PointRefund />
    </MyPageLayout>
  );
}
