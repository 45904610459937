import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { Controller, useForm } from "react-hook-form";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import { updateProfile, validateEmail } from "../../api/authApi";
import { CommonContainer } from "../../components/Common";
import Button from "../../components/Common/Button";
import Input from "../../components/Common/Input";
import { userState } from "../../store/authStore";
import { alertExceptionMsg } from "../../utils/exception.util";

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Desc = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
`;
const Mark = styled.span`
  color: #f00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
`;
const InputsWrapper = styled.div`
  margin-bottom: 60px;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Label = styled.div`
  min-width: 110px;
  width: 110px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

interface InputTypes {
  first_name: string;
  email: string;
  verification_code: string;
  address_a: string;
  address_b: string;
  company_code: string;
}

export default function ProfileInfo() {
  const [user, setUser] = useRecoilState(userState);
  const [openPostcode, setOpenPostcode] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  const onSubmit = async (inputValues: InputTypes) => {
    if (user) {
      if (inputValues.email !== user.email && !isEmailVerified) {
        alert("이메일 중복확인을 해주세요.");
        return;
      }

      updateProfile(
        user.uuid,
        inputValues.email,
        inputValues.first_name,
        inputValues.address_a,
        inputValues.address_b,
        inputValues.company_code
      )
        .then((newUser) => {
          alert("성공적으로 개인 정보를 수정하였습니다.");
          setUser(newUser);
        })
        .catch((err) => {
          alertExceptionMsg(err.response?.data, "개인정보 수정에 실패하였습니다.");
        });
    }
    // signup(inputValues).then((user) => {
    //   setUser(user);
    // });
  };

  const selectAddress = (data: any) => {
    setValue("address_a", data.address);
    setOpenPostcode(false);
  };

  const _validateEmail = async () => {
    try {
      const email = watch("email");
      await validateEmail(email);
      setIsEmailVerified(true);
    } catch (e) {
      alert("해당 이메일은 이미 사용중입니다.");
    }
  };

  return (
    <ContentContainer>
      <ReactModal
        isOpen={openPostcode}
        ariaHideApp={true}
        onRequestClose={() => setOpenPostcode(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <DaumPostcodeEmbed
          onComplete={selectAddress} // 값을 선택할 경우 실행되는 이벤트
          autoClose={true} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
          defaultQuery="" // 팝업을 열때 기본적으로 입력되는 검색어
        />
        <Button
          onClick={() => setOpenPostcode(false)}
          style={{ marginTop: 10 }}
        >
          닫기
        </Button>
      </ReactModal>
      <Desc>
        <Mark>*</Mark> 필수 입력사항
      </Desc>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputsWrapper>
          <InputWrapper>
            <Label>
              아이디 <Mark>*</Mark>
            </Label>
            <Input
              disabled
              placeholder="아이디"
              style={{ height: 42 }}
              value={user?.username}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>
              이름 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"first_name"}
              rules={{
                required: "필수 항목입니다.",
              }}
              defaultValue={user?.first_name}
              render={({ field }) => (
                <Input
                  placeholder="이름"
                  style={{ height: 42 }}
                  errors={errors.first_name?.message}
                  {...field}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>
              이메일 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"email"}
              rules={{
                required: "필수 항목입니다.",
              }}
              defaultValue={user?.email}
              render={({ field }) => (
                <Input
                  placeholder="이메일"
                  style={{ height: 42 }}
                  errors={errors.email?.message}
                  {...field}
                />
              )}
            />
            <Button
              style={{
                height: 42,
                marginLeft: 8,
                fontSize: 15,
                fontWeight: "500",
                color: "#474748",
                maxWidth: 92,
              }}
              disabled={isEmailVerified || watch("email") === user?.email}
              onClick={(e: any) => {
                e.preventDefault();
                _validateEmail();
              }}
            >
              중복확인
            </Button>
          </InputWrapper>
          <InputWrapper>
            <Label>
              주소 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"address_a"}
              rules={{
                required: "필수 항목입니다.",
              }}
              defaultValue={user?.profile?.address_a}
              render={({ field }) => (
                <Input
                  placeholder="주소"
                  style={{ height: 42 }}
                  errors={errors.address_a?.message}
                  {...field}
                />
              )}
            />
            <Button
              style={{
                height: 42,
                marginLeft: 8,
                fontSize: 15,
                fontWeight: "500",
                color: "#474747",
                maxWidth: 80,
              }}
              onClick={(e: any) => {
                e.preventDefault();
                setOpenPostcode(true);
              }}
            >
              검색
            </Button>
          </InputWrapper>
          <InputWrapper>
            <Label />
            <Controller
              control={control}
              name={"address_b"}
              rules={{
                required: "필수 항목입니다.",
              }}
              defaultValue={user?.profile?.address_b}
              render={({ field }) => (
                <Input
                  placeholder="나머지 주소"
                  style={{ height: 42 }}
                  errors={errors.address_b?.message}
                  {...field}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>
              생년월일 <Mark>*</Mark>
            </Label>
            <Input
              value={user?.profile?.birthday}
              style={{ height: 42 }}
              disabled
            />
          </InputWrapper>
          <InputWrapper>
            <Label>회사 코드</Label>
            <Controller
              control={control}
              rules={{
                minLength: { value: 6, message: "회사코드는 6자리입니다." },
                maxLength: { value: 6, message: "회사코드는 6자리입니다." },
              }}
              name={"company_code"}
              defaultValue={user?.profile?.company_code}
              render={({ field }) => (
                <Input
                  placeholder=""
                  style={{ height: 42 }}
                  errors={errors.company_code?.message}
                  {...field}
                />
              )}
            />
          </InputWrapper>
        </InputsWrapper>
        <Button
          style={{
            background: "#29292A",
            color: "white",
            border: "none",
            marginBottom: 12,
          }}
        >
          수정
        </Button>
      </form>
    </ContentContainer>
  );
}
