import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface ProductReviewImage {
  uuid: string;
  image: string;
  created: string;
  modified: string;
}

export function getProductReviewImageList(
  productUuid: string,
  limit?: number,
  offset?: number,
): Promise<PaginationType<ProductReviewImage>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  return nollyAxios
    .get(`/api/products/${productUuid}/product_review_images${query}`)
    .then(({ data }) => data);
}
