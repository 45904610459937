import styled from "@emotion/styled";
import Button from "../Common/Button";
import Input, { InputChangeEvent } from "../Common/Input";
import { useRecoilValue } from "recoil";
import { userState } from "../../store/authStore";
import NumberInput from "../Common/NumberInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const CurrentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
const CurrentTitle = styled.div`
  display: flex;
  width: 110px;
  align-items: flex-start;
  gap: 2px;
`;
const CurrentValue = styled.div`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const UsePointWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const UsePointContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export default function PurchasePoint({
  price,
  point,
  setPointSent,
}: {
  price: number;
  point?: number;
  setPointSent: (v: number) => void;
}) {
  const user = useRecoilValue(userState);
  const userPoint = user?.point?.amount || 0;

  const onTotalUse = () => {
    const _min = Math.min(price, userPoint);
    setPointSent(_min);
  };

  const _onSetPoint = (e: InputChangeEvent) => {
    const _point = Number(e.target.value);
    const _min = Math.min(_point, userPoint, price);
    setPointSent(_min);
  };

  const onSetPoint = (n: number) => {
    const _min = Math.min(n, userPoint, price);
    setPointSent(_min);
  };

  return (
    <Container>
      <CurrentWrapper>
        <CurrentTitle>현재 포인트</CurrentTitle>
        <CurrentValue>{userPoint.toLocaleString()}원</CurrentValue>
      </CurrentWrapper>
      <UsePointWrapper>
        <CurrentTitle>사용 포인트</CurrentTitle>
        <UsePointContentWrapper>
          {/* <Input
            style={{ height: 42, marginRight: 8 }}
            type={"number"}
            value={point}
            // onChange={(e) => setPointSent(Number(e.target.value))}
            onChange={_onSetPoint}
            placeholder="사용할 포인트를 입력해주세요."
            max={userPoint}
          /> */}
          <NumberInput
            value={point}
            onSet={onSetPoint}
            onChange={_onSetPoint}
            placeholder="사용할 포인트를 입력해주세요."
            style={{ height: 42, marginRight: 8 }}
            localeString
          />
          <Button
            style={{
              height: 42,
              width: 135,
              padding: "0 16px",
              fontSize: 15,
              fontWeight: "500",
              marginLeft: 8,
            }}
            onClick={() => onTotalUse()}
          >
            모두 사용
          </Button>
        </UsePointContentWrapper>
      </UsePointWrapper>
    </Container>
  );
}
