import styled from "@emotion/styled";
import React from "react";
import LeftArrowIcon from "../../assets/LeftArrowIcon.svg";
import RightArrowIcon from "../../assets/RightArrowIcon.svg";
import { IsActiveProps } from "../Product/ProductCommon";
import { isMobile } from "react-device-detect";

const IconImg = styled.img`
  cursor: pointer;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;
const PageNumberWrapper = styled.div<IsActiveProps>`
  display: flex;
  width: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: ${(props) => (props.isActive ? "1px solid black" : "")};
  font-weight: ${(props) => (props.isActive ? "700" : "400")};
  cursor: pointer;
`;

export type PaginationValue = string | number | ReadonlyArray<string>;

interface Props {
  style?: React.CSSProperties;
  curIndex?: number;
  maxCount?: number;
  pageSize?: number;
  paginate?: (i: number) => void;
}

const maxPageToShow = 5;
const Pagination: React.FunctionComponent<Props> = ({
  style,
  curIndex = 1,
  maxCount = 0,
  pageSize = 35,
  paginate,
}) => {
  const maxPage = Math.ceil(maxCount / pageSize);
  const end = Math.min(Math.ceil(curIndex / maxPageToShow) * maxPageToShow, maxPage) + 1;
  const start = Math.floor((curIndex - 1) / maxPageToShow) * maxPageToShow + 1;

  const numbers = Array.from(
    { length: end - start },
    (_, index) => start + index
  );
  const _paginate = (i: number) => {
    if (i < 1 || i > maxPage) return;
    if (paginate) paginate(i);
  };

  // if (isMobile) return null;
  if (maxCount === 0) return <div />;

  return (
    <PaginationContainer style={style}>
      <IconImg src={LeftArrowIcon} onClick={() => _paginate(curIndex - 1)} />
      {numbers.map((k) => (
        <PageNumberWrapper
          isActive={curIndex === k}
          key={k}
          onClick={() => _paginate(k)}
        >
          {k}
        </PageNumberWrapper>
      ))}
      <IconImg src={RightArrowIcon} onClick={() => _paginate(curIndex + 1)} />
    </PaginationContainer>
  );
};

export default Pagination;
