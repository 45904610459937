import styled from "@emotion/styled";
import ImgEmpty from "../../assets/ImgEmpty.svg";
import Button from "../Common/Button";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Container = styled.div`
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const Text = styled.div`
  color: var(--grey-90, #474748);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

export default function EmptyOrder() {
  const navigate = useNavigate();
  return (
    <Container>
      <img src={ImgEmpty} />
      <Text>
        아직 주문이 없어요! <br />
        우리 함께 놀리할까요?
      </Text>
      <Button
        style={{
          width: isMobile ? "100%" :  200,
          height: 42,
          background: "#29292A",
          color: "white",
          fontSize: 15,
          fontWeight: "500",
        }}
        onClick={()=> navigate('/')}
      >
        둘러보기
      </Button>
    </Container>
  );
}
