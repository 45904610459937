import styled from "@emotion/styled";
import { CommonContainer } from "../components/Common";
import { useEffect, useState } from "react";
import { About, getIntro } from "../api/aboutApi";

const AskContainer = styled(CommonContainer)`
  padding: 60px 0px 40px 0px;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
    height: calc(100vh - 150px);
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  min-height: 600px;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    min-height: 100%;
    padding: 16px 14px;
    height: calc(100vh - 150px);
    padding-bottom: 20px;
  }
`;
const IframWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function iframe() {
  return {
    __html:
      '<iframe src="./agree2.html" width="100%" height="100%" frameBorder="0" ></iframe>',
  };
}

export default function PrivacyPage() {
  const [about, setAbout] = useState<About | null>(null);
  useEffect(() => {
    getIntro().then(setAbout).catch(console.error);
  }, []);
  return (
    <AskContainer>
      <ContentContainer>
        {/* <IframWrapper dangerouslySetInnerHTML={iframe()} /> */}
        <iframe
          src="./agree2.html"
          width="100%"
          height="100%"
          frameBorder="0"
          onLoad={() => {
            const iframe = document.querySelector("iframe");
            const innerDoc =
              iframe?.contentDocument || iframe?.contentWindow?.document;

            // 폰트 스타일을 추가
            if (innerDoc) {
              const style = innerDoc?.createElement("style");
              if (style) {
                style.innerHTML = `
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 100;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
                          format("opentype");
                    }
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 300;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
                          format("opentype");
                    }
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 400;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
                          format("opentype");
                    }
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 500;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
                          format("opentype");
                    }
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 700;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
                          format("opentype");
                    }
                    @font-face {
                      font-family: "Noto Sans KR";
                      font-style: normal;
                      font-weight: 900;
                      src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
                          format("woff2"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
                          format("woff"),
                        url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
                          format("opentype");
                    }
                    * {
                      font-family: "Noto Sans KR", sans-serif !important;
                    }
              `;
                innerDoc.head.appendChild(style);
              }
            }
          }}
        ></iframe>
      </ContentContainer>
    </AskContainer>
  );
}
