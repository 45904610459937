import nollyAxios from "./nollyAxios";
import { PaginationType, Product } from "./productApi";

export interface User {
  uuid: string;
  username: string;
  first_name: string;
  created: string;
  modified: string;
}
export type Seller = {
  uuid: string;
  user: User;
  profile_image: string;
  bio: string;
  review_count: number;
  review_avg: number;
  created: string;
  modified: string;
};

export function sellerDetail(product_seller_uuid: string): Promise<Seller> {
  return nollyAxios
    .get(`/api/product_sellers/${product_seller_uuid}`)
    .then(({ data }) => data);
}

export function sellerProducts(
  product_seller_uuid: string,
  limit?: number,
  offset?: number,
): Promise<PaginationType<Product>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  return nollyAxios
    .get(`/api/product_sellers/${product_seller_uuid}/products${query}`)
    .then(({ data }) => data);
}
