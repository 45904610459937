import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import { ReactComponent as AloneMobile } from "../assets/moreMenu/aloneMobile.svg";
import { ReactComponent as LoverMobile } from "../assets/moreMenu/loverMobile.svg";
import { ReactComponent as FamilyMobile } from "../assets/moreMenu/familyMobile.svg";
import { ReactComponent as BucketMobile } from "../assets/moreMenu/bucketMobile.svg";
import { ReactComponent as FlexMobile } from "../assets/moreMenu/flexMobile.svg";
import { ReactComponent as MountainMobile } from "../assets/moreMenu/mountainMobile.svg";
import { ReactComponent as FieldMobile } from "../assets/moreMenu/fieldMobile.svg";
import { ReactComponent as SeaMobile } from "../assets/moreMenu/seaMobile.svg";
import { ReactComponent as PickMobile } from "../assets/moreMenu/pickMobile.svg";
import { ReactComponent as Flex2Mobile } from "../assets/moreMenu/flex2Mobile.svg";

import { CommonContainer } from "../components/Common";
import { IsActiveProps } from "../components/Product/ProductCommon";
import { useState } from "react";
import { ProductTopic } from "../types/enums";

const ListContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

const TopicsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
`;
const TopicWrapper = styled.div<IsActiveProps>`
  display: flex;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  border-radius: 4px;
  background: ${(props) => (props.isActive ? "#F00" : "#29292A")};
  color: white;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;
`;
const CategoriesRow = styled.div`
  display: flex;
  padding: 0px 14px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
`;

const CategoryItem = styled.a`
  border-radius: 8px;
  width: 100%;
`;

// Mobile only
export default function CategoriesPage() {
  const [topic, setTopic] = useState<keyof typeof ProductTopic>("ACTIVITY");

  if (!isMobile) return null;

  return (
    <ListContainer>
      <ContentContainer>
        <TopicsWrapper>
          <TopicWrapper
            isActive={topic === "ACTIVITY"}
            onClick={() => {
              setTopic("ACTIVITY");
            }}
          >
            액티비티
          </TopicWrapper>
          <TopicWrapper
            isActive={topic === "WORKACTION"}
            onClick={() => {
              setTopic("WORKACTION");
            }}
          >
            워케이션
          </TopicWrapper>
          <TopicWrapper
            isActive={topic === "CLASS"}
            onClick={() => {
              setTopic("CLASS");
            }}
          >
            클래스
          </TopicWrapper>
        </TopicsWrapper>
        {topic === "ACTIVITY" && (
          <CategoriesWrapper>
            <CategoryItem href="/p/activity/alone">
              <AloneMobile />
            </CategoryItem>
            <CategoryItem href="/p/activity/with_lover">
              <LoverMobile />
            </CategoryItem>
            <CategoryItem href="/p/activity/with_family">
              <FamilyMobile />
            </CategoryItem>
            <CategoryItem href="/p/activity/bucket_list">
              <BucketMobile />
            </CategoryItem>
            <CategoryItem href="/p/activity/flex">
              <FlexMobile />
            </CategoryItem>
          </CategoriesWrapper>
        )}
        {topic === "WORKACTION" && (
          <CategoriesWrapper>
            <CategoryItem href="/p/workaction/mountain">
              <MountainMobile />
            </CategoryItem>
            <CategoryItem href="/p/workaction/field">
              <FieldMobile />
            </CategoryItem>
            <CategoryItem href="/p/workaction/sea">
              <SeaMobile />
            </CategoryItem>
            <CategoryItem href="/p/workaction/pick">
              <PickMobile />
            </CategoryItem>
          </CategoriesWrapper>
        )}
        {topic === "CLASS" && (
          <CategoriesWrapper>
            <CategoryItem href="/p/class/alone">
              <AloneMobile />
            </CategoryItem>
            <CategoryItem href="/p/class/with_lover">
              <LoverMobile />
            </CategoryItem>
            <CategoryItem href="/p/class/with_family">
              <FamilyMobile />
            </CategoryItem>
            <CategoryItem href="/p/class/bucket_list">
              <BucketMobile />
            </CategoryItem>
            <CategoryItem href="/p/class/flex">
              <Flex2Mobile />
            </CategoryItem>
          </CategoriesWrapper>
        )}
        {/* <CategoriesWrapper>
          <CategoriesRow>
            <SkyMobile onClick={() => navigate("/p/activity/sky_diving")} />
            <LeisureMobile onClick={() => navigate("/p/activity/leisure")} />
          </CategoriesRow>
          <CategoriesRow>
            <RunMobile onClick={() => navigate("/p/activity/running")} />
            <WaterMobile onClick={() => navigate("/p/activity/water_activity")} />
          </CategoriesRow>
          <CategoriesRow>
            <DanceMobile onClick={() => navigate("/p/activity/dance")} />
            <BoardMobile onClick={() => navigate("/p/activity/skate_boarding")} />
          </CategoriesRow>
          <CategoriesRow>
            <SkiMobile onClick={() => navigate("/p/activity/ski")} />
            <ClimbMobile onClick={() => navigate("/p/activity/climbing")} />
          </CategoriesRow>
        </CategoriesWrapper> */}
      </ContentContainer>
    </ListContainer>
  );
}
