import styled from "@emotion/styled";
import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getNoticeDetail } from "../../api/noticeApi";

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const BadgeWrapper = styled.div`
  display: flex;
  width: 100px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0;
    justify-content: flex-start;
  }
`;
const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  border-radius: 4px;
  background: var(--grey-100, #29292a);
  color: white;
`;

const Title = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    padding: 10px 0px;
    border-bottom: none;
    justify-content: flex-start;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  width: 160px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
    justify-content: flex-start;
    width: 100%;
  }
`;

const Content = styled.pre`
  display: flex;
  padding: 40px 32.5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  background: var(--grey-20, #f9f9fa);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
`;

interface Props extends React.PropsWithChildren {
  uuid: string;
  title: string;
  createdAt: string;
  category: string;
  topic: string;
}

const NoticeItem: React.FunctionComponent<Props> = ({
  uuid,
  title,
  createdAt,
  category,
  topic,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");

  useLayoutEffect(() => {
    setIsOpen(false);
  }, [topic]);

  useEffect(() => {
    if (uuid && isOpen && !content) {
      getNoticeDetail(uuid).then((res) => setContent(res.content));
      setIsOpen(true);
    }
  }, [isOpen]);

  return (
    <Container>
      <Header onClick={() => setIsOpen((v) => !v)}>
        <BadgeWrapper>
          <Badge>{category}</Badge>
        </BadgeWrapper>
        <Title style={isOpen ? { color: "#F00", fontWeight: '600'} : {}}>{title}</Title>
        <DateWrapper>{dayjs(createdAt).format("YYYY-MM-DD")}</DateWrapper>
      </Header>
      {isOpen && content && <Content>{content}</Content>}
    </Container>
  );
};

export default NoticeItem;
