import styled from "@emotion/styled";
import React, { ChangeEvent } from "react";

const ButtonContainer = styled.button`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid var(--grey-50, #cbcbcc);
  background: var(--white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.036px;
  &::placeholder {
    color: var(--grey-60, #b2b2b3);
  }
  &:disabled {
    color: var(--grey-60, #b2b2b3);
    background-color: #EFEFF0;
    cursor: auto;
  }
`;

export type ButtonValue = string | number | ReadonlyArray<string>;
export type ButtonChangeEvent = ChangeEvent<HTMLButtonElement>;

interface Props extends React.PropsWithChildren {
  placeholder?: string;
  value?: ButtonValue;
  style?: React.CSSProperties;
  onClick?: any;
  disabled?: boolean;
}

const Button: React.FunctionComponent<Props> = ({
  value = "",
  style,
  placeholder,
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <ButtonContainer
      value={value}
      placeholder={placeholder}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
