import styled from "@emotion/styled";
import { useState } from "react";
import InputMask from "react-input-mask";
import { findIdByMobile } from "../../api/authApi";
import { registerSms, verifySms } from "../../api/smsApi";
import Button from "../Common/Button";
import Input from "../Common/Input";

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
const Label = styled.div`
  color: var(--grey-70, #939394);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const VerificationCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;
const VerificationCodeDesc = styled.div`
  color: var(--red-100, #f00);
  font-size: 14px;
  font-weight: 400;
`;

export default function FindIdByMobile({
  setFoundId,
}: {
  setFoundId: (v: string) => void;
}) {
  const [firstName, setFirstName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [vCode, setVcode] = useState("");
  const [sessionToken, setSessionToken] = useState("");

  const handleMobileFind = async () => {
    if (sessionToken) {
      checkWithMobile();
    } else {
      handleClickSendMobile();
    }
  };

  const handleClickSendMobile = async () => {
    try {
      const { session_token } = await registerSms(mobileNo);
      setSessionToken(session_token);
    } catch (e) {}
  };

  const checkWithMobile = async () => {
    try {
      await verifySms(mobileNo, sessionToken, vCode);
      const { username } = await findIdByMobile(firstName, sessionToken);
      setFoundId(username);
    } catch (e) {}
  };

  return (
    <>
      <InputWrapper>
        <Label>이름</Label>
        <Input
          disabled={!!sessionToken}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          placeholder="이름을 입력해주세요."
          style={{ marginBottom: 12 }}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>휴대폰 번호</Label>
        <InputMask
          disabled={!!sessionToken}
          style={{
            width: "100%",
            paddingLeft: 16,
            paddingRight: 16,
            height: 60,
            borderRadius: 4,
            border: "1px solid #cbcbcc",
            background: "#fff",
            fontSize: 16,
            fontWeight: 400,
          }}
          value={mobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          mask="999-9999-9999"
          maskChar=""
          placeholder="010-1234-5678"
        />
      </InputWrapper>
      {sessionToken && (
        <>
          <InputWrapper>
            <Label>인증번호</Label>
            <VerificationCodeWrapper>
              <Input
                value={vCode}
                onChange={(e) => setVcode(e.currentTarget.value)}
                placeholder="인증번호 7자리"
                countDown={sessionToken}
              />
              <Button
                style={{ width: 120, fontSize: 15, color: "#474748" }}
                onClick={() => handleClickSendMobile()}
              >
                재발송
              </Button>
            </VerificationCodeWrapper>
            <VerificationCodeDesc>
              인증번호를 입력해주세요.
            </VerificationCodeDesc>
          </InputWrapper>
        </>
      )}
      <Button
        onClick={() => handleMobileFind()}
        style={{
          background: "#29292A",
          color: "white",
          border: "none",
          marginBottom: 12,
        }}
      >
        확인
      </Button>
    </>
  );
}
