import axios, { AxiosResponse } from "axios";
import get from "lodash/get";
import nollyAxios from "./nollyAxios";

export interface S3Data {
  url: string;
  fields: {
    AWSAccessKeyId: string;
    key: string;
    policy: string;
    signature: string;
    "x-amz-security-token"?: string;
  };
}

// export const uploadFileToS3 = async (file: File, S3Data: S3Data) => {
export const uploadFileToS3 = async (file: File, S3Data: RequestUpload) => {
  const _options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  const fields = S3Data.fields;

  Object.keys(fields).map((k: string) => {
    if (k !== "x-amz-security-token") formData.append(k, get(fields, k));
    return true;
  });
  get(S3Data.fields, "x-amz-security-token") &&
    formData.append(
      "x-amz-security-token",
      S3Data.fields["x-amz-security-token"]!
    );

  formData.append("file", file);

  const response = await axios.post(S3Data.url, formData, _options);
  return response.data;
};

export interface RequestUpload {
  url: string;
  fields: Fields;
}

export interface Fields {
  "Content-Type": string;
  key: string;
  "x-amz-algorithm": string;
  "x-amz-credential": string;
  "x-amz-date": string;
  "x-amz-security-token": string;
  policy: string;
  "x-amz-signature": string;
}

export function requestUpload(filenames: string[]): Promise<RequestUpload[]> {
  const payload = { filenames };
  return nollyAxios
    .post(`/api/product_reviews/upload_media`, payload)
    .then(({ data }) => data);
}
