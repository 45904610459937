import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { PaginationType } from "../../api/productApi";
import { Purchase, getPruchases } from "../../api/purchaseApi";
import Pagination from "../Common/Pagenation";
import EmptyOrder from "./EmptyOrder";
import OrderItem from "./OrderItem";
import { dateOptions } from "../../types/enums";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import { IsActiveProps } from "../Product/ProductCommon";
import { PAGE_SIZE } from "../../utils/common.util";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const HeaderDesc = styled.div`
  color: var(--grey-70, #939394);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.024px;
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 24px;
`;
const OptionItem = styled.div<IsActiveProps>`
  display: flex;
  height: 42px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  border-radius: 4px;
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--grey-100, #29292a)"
      : "1px solid var(--grey-100, #CBCBCC)"};
  background: var(--white, #fff);
`;

const firstOption = {
  label: "1개월",
  value: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
};

export default function OrderHistory() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<Purchase>>(null);
  const [date, setDate] = useState(dateOptions[0]);

  useEffect(() => {
    getPruchases(PAGE_SIZE, (page - 1) * PAGE_SIZE, date.value).then(setRes);
  }, [page, date]);

  return (
    <Container>
      {isMobile ? (
        Number(res?.results.length) > 0 && (
          <OptionsWrapper>
            <OptionItem
              isActive={date === firstOption}
              onClick={() => setDate(firstOption)}
            >
              1개월
            </OptionItem>
            <OptionItem
              isActive={date === dateOptions[0]}
              onClick={() => setDate(dateOptions[0])}
            >
              3개월
            </OptionItem>
            <OptionItem
              isActive={date === dateOptions[1]}
              onClick={() => setDate(dateOptions[1])}
            >
              6개월
            </OptionItem>
            <OptionItem
              isActive={date === dateOptions[2]}
              onClick={() => setDate(dateOptions[2])}
            >
              1년
            </OptionItem>
          </OptionsWrapper>
        )
      ) : (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>주문내역</HeaderTitle>
            <HeaderDesc>
              최대 지난 1년까지의 주문을 확인할 수 있습니다.
            </HeaderDesc>
          </HeaderTitleWrapper>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            options={dateOptions}
            value={date}
            isSearchable={false}
            onChange={(a) =>
              setDate({ label: a?.label || "", value: a?.value || "" })
            }
            styles={{
              container: (base) => ({ width: "160px", height: "52px" }),
              control: (base) => ({ ...base, cursor: "pointer" }),
            }}
            placeholder={"3개월"}
            components={{ IndicatorSeparator: () => null }}
          />
        </Header>
      )}
      {!res?.results || (res.results.length === 0 && <EmptyOrder />)}
      {res?.results && res.results.length > 0 && (
        <ItemsWrapper>
          {res?.results.map((t) => {
            return (
              <div
                key={`order-item-${t.uuid}`}
                style={{ width: "100%" }}
                onClick={() =>
                  navigate(`/mypage/order/detail`, { state: { purchase: t } })
                }
              >
                <OrderItem purchase={t} />
              </div>
            );
          })}
          <Pagination
            curIndex={page}
            paginate={setPage}
            maxCount={res?.count}
          />
        </ItemsWrapper>
      )}
    </Container>
  );
}
