import styled from "@emotion/styled";
import DatePicker, {
  Day,
  DayValue,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Sheet from "react-modal-sheet";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useSetRecoilState } from "recoil";
import { likeProduct, removeLikeProduct } from "../api/likeApi";
import { ProductDetail } from "../api/productApi";
import {
  ProductOptionDay,
  ProductOptionSeat,
  getProductOptionDays,
  getProductOptionSeatByOptionDay,
} from "../api/productOptionApi";
import { ReactComponent as HeartLineIconSvg } from "../assets/HeartLineIcon.svg";
import { ReactComponent as HeartLineIconActive } from "../assets/HeartLineIconActive.svg";
import { ReactComponent as BtnMinusSvg } from "../assets/BtnMinus.svg";
import { ReactComponent as BtnPlusSvg } from "../assets/BtnPlus.svg";
import UploadIconSvg from "../assets/UploadIcon.svg";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import PriceText from "../components/PriceText";
import ProductCarousel from "../components/Product/ProductCarousel";
import ProductHelpCenter from "../components/Product/ProductHelpCenter";
import ProductInfo from "../components/Product/ProductInfo";
import ProductIntroduction from "../components/Product/ProductIntroduction";
import ProductMap from "../components/Product/ProductMap";
import ProductQuestion from "../components/Product/ProductQuestion";
import ProductReviewContent from "../components/Product/ProductReviewContent";
import ProductSeller from "../components/Product/ProductSeller";
import ProductSellerPhoneInfo from "../components/Product/ProductSellerPhoneInfo";
import { loginPopupState } from "../store/commonStore";
import { myCustomLocale } from "../utils/calendar.util";
import share from "../utils/share";
import { IsActiveProps } from "./Product/ProductCommon";
import { OPTION_DAYS_PAGE_SIZE } from "../utils/common.util";

const ProductContainer = styled(CommonContainer)`
  /* padding: 40px 0px 60px 0px; */
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
    padding-bottom: 100px;
  }
`;
const ShortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;
const ShortContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;
const ShortContentTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const ShortTitleDescContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const UploadIcon = styled.img`
  cursor: pointer;
`;
const ShortTitleDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const ShortTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    max-width: 300px;
  }
`;
const ShortDesc = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
`;
const Location = styled.span`
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--grey-30, #efeff0);
  color: var(--grey-70, #939394);
  font-size: 12px;
  font-weight: 600;
`;

const ShortBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 14px;
`;

const LikeParticipateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const LikeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
const LikeText = styled.div`
  color: var(--grey-90, #474748);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
`;

const OptionWrapper = styled.div`
  display: flex;
  padding: 16px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  cursor: pointer;
`;
const OptionTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--grey-100, #29292a);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
`;
const OptionPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const OptionPrice = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.032px;
`;
const OptionDiscount = styled.div`
  color: var(--grey-60, #b2b2b3);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
  text-decoration: line-through;
`;
const OptionSeat = styled(OptionDiscount)`
  text-decoration-line: none;
`;

const SelectedOptionWrapper = styled.div`
  display: flex;
  padding: 20px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
`;
const SelectedOptionTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--grey-100, #29292a);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
`;
const SelectedOptionPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const SelectedoptionPrice = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.032px;
`;
const SelectedoptionDiscount = styled.div`
  color: var(--grey-60, #b2b2b3);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
  text-decoration: line-through;
`;

const DateWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid var(--grey-50, #cbcbcc);
  background: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.032px;
  cursor: default;
`;

const BottomFixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: white;
  padding: 8px 14px;
  border-top: 1px solid var(--grey-40, #e0e0e1);
  z-index: 101;
`;

const MenusWrapper = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  height: 100%;
`;
const MenuItem = styled.div<IsActiveProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  height: 100%;
  align-self: stretch;
  color: ${(props) => (props.isActive ? "#F00" : "#707071")};
  border-bottom: ${(props) => (props.isActive ? "2px solid #F00" : "none")};
`;
const SelectedPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const SelectedQuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const QunatityNum = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const dayStringFromDay = (d: Day) => {
  return dayjs(`${d.year}-${d.month}-${d.day}`).format("YYYY-MM-DD");
};

const dayjsToDay = (d: Dayjs) => {
  return {
    year: d.year(),
    month: d.month() + 1,
    day: d.date(),
  };
};

export default function MobileProductDetailInfo({
  res,
}: {
  res: ProductDetail | null;
}) {
  const [optionDays, setOptionDays] = useState<ProductOptionDay[]>([]);
  const setLoginPopup = useSetRecoilState(loginPopupState);
  const [selectedOptionDay, setSelectedOptionDay] =
    useState<ProductOptionDay | null>(null);

  const [optionSeats, setOptionSeats] = useState<ProductOptionSeat[]>([]);
  const [selectedOptionSeat, setSelectedOptionSeat] =
    useState<ProductOptionSeat | null>(null);

  const navigate = useNavigate();

  const [likeUuid, setLikeUuid] = useState<null | string>(null);
  const [likeCount, setLikeCount] = useState<number>(0);

  const [selectedDay, setSelectedDay] = useState<DayValue>(dayjsToDay(dayjs()));

  const [curMonth, setCurMonth] = useState<Dayjs>(dayjs());
  const [quantity, setQuantity] = useState<number>(0);
  const [maxQuantity, setMaxQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [menu, setMenu] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (res) {
      const startOfMonth = dayjs(curMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = dayjs(curMonth).endOf("month").format("YYYY-MM-DD");
      setIsLoading(true);
      getProductOptionDays(res.uuid, startOfMonth, endOfMonth, OPTION_DAYS_PAGE_SIZE, 0)
        .then(({ results }) => setOptionDays(results))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [curMonth, res]);

  useEffect(() => {
    if (res) {
      setLikeUuid(res.like_uuid);
      setLikeCount(res.like_count);
    }
  }, [res]);

  useEffect(() => {
    if (selectedDay) {
      const filtered = optionDays.filter(({ option_day }) => {
        return (
          option_day ===
          dayjs(
            `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
          ).format("YYYY-MM-DD")
        );
      });
      if (filtered.length > 0) setSelectedOptionDay(filtered[0]);
    }
  }, [selectedDay, optionDays]);

  useEffect(() => {
    if (!id) return;
    if (!selectedOptionDay?.uuid) return;

    getProductOptionSeatByOptionDay(id, selectedOptionDay.uuid).then(
      ({ results }) => setOptionSeats(results)
    );
  }, [id, selectedOptionDay]);

  const toggle = () => {
    if (!window.localStorage.getItem("accessToken")) {
      setLoginPopup({ next: `/products/${res?.uuid}`, state: {} });
      return;
    }
    if (res) {
      if (likeUuid) {
        removeLikeProduct(likeUuid).then(() => {
          setLikeUuid(null);
          setLikeCount((v) => v - 1);
        });
      } else {
        likeProduct(res.uuid).then(({ uuid }) => {
          setLikeUuid(uuid);
          setLikeCount((v) => v + 1);
        });
      }
    }
  };

  const disabledDays = useMemo(() => {
    const days: Day[] = [];
    const optionDayArr = optionDays.map((t) => t.option_day);

    const startDate = dayjs(curMonth).startOf("month");
    const endDate = startDate.endOf("month");

    let currentDate = startDate;
    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const str = `${dayjs(currentDate).format("YYYY-MM-DD")}`;
      if (!optionDayArr.includes(str)) {
        days.push({
          year: currentDate.year(),
          month: currentDate.month() + 1,
          day: currentDate.date(),
        });
      }
      currentDate = currentDate.add(1, "day");
    }
    return days;
  }, [optionDays, curMonth]);

  const allDisabledDays = useMemo(() => {
    const days: Day[] = [];
    const optionDayArr = optionDays.map((t) => t.option_day);

    const startDate = dayjs(curMonth).subtract(2, "month").startOf("month");
    const endDate = startDate.add(4, "month").endOf("month");

    let currentDate = startDate;
    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const str = `${dayjs(currentDate).format("YYYY-MM-DD")}`;
      if (!optionDayArr.includes(str)) {
        days.push({
          year: currentDate.year(),
          month: currentDate.month() + 1,
          day: currentDate.date(),
        });
      }
      currentDate = currentDate.add(1, "day");
    }
    return days;
  }, [optionDays, curMonth]);

  const onSelectSeat = (seatUuid: string) => {
    const selectedSeat = optionSeats.find(({ uuid }) => uuid === seatUuid);
    if (!selectedSeat) return;
    setSelectedOptionSeat(selectedSeat);
    setQuantity(1);
    setMaxQuantity(selectedSeat.available_seat);
  };

  useEffect(() => {
    setSelectedOptionSeat(null);
  }, [selectedDay]);

  const renderCustomInput = ({ ref }: any) => (
    <input
      readOnly
      ref={ref} // necessary
      value={"날짜선택"}
      style={{
        width: "150px",
        textAlign: "center",
        height: "42px",
        borderRadius: 4,
        border: "1px solid var(--grey-100, #29292A)",
        background: "var(--white, #FFF)",
        cursor: "pointer",
      }}
      onTouchEndCapture={() => {
        setTimeout(() => {
          setEventListener();
        }, 1000);
      }}
      className="my-custom-input-class" // a styling class
    />
  );
  const registerObserve = () => {
    setInterval(() => {
      const monthEls = Array.from(
        document.getElementsByClassName("Calendar__monthText")
      );
      const yearEls = Array.from(
        document.getElementsByClassName("Calendar__yearText")
      );
      yearEls.forEach((y) => {
        const _year = Number(y.innerHTML.replace(/[^0-9]/g, ""));
        y.innerHTML = _year + "년";
      });
      monthEls.forEach((y) => {
        const _month = Number(y.innerHTML.replace(/[^0-9]/g, ""));
        let monthOffset = "";
        if (_month < 10) monthOffset = "0";
        y.innerHTML = monthOffset + _month + "월";
      });
    }, 100);
  };

  const setEventListener = () => {
    console.log("set enven linstcA");
    registerObserve();
    setCurMonth(dayjs());
    const cells = document.getElementsByClassName("Calendar__monthArrowWrapper");
    handleChangedMonth();
    for (var i = 0; i < cells.length; i++) {
      const eachCell = cells[i];
      eachCell.addEventListener("touchstart", (e) => {
        setIsLoading(true);
        setTimeout(() => {
          handleChangedMonth();
        }, 2000);
      });
    }
    const years = document.getElementsByClassName("Calendar__yearSelectorText");
    for (var i = 0; i < years.length; i++) {
      const eachCell = years[i];
      eachCell.addEventListener("touchend", () => {
        setIsLoading(true);
        setTimeout(() => {
          const _monthEls = document.getElementsByClassName(
            "Calendar__monthText"
          );
          const monthEls = Array.from(_monthEls).filter((element) => {
            const ariaHidden = element.getAttribute("aria-hidden");
            return !ariaHidden || ariaHidden === "false";
          });

          const _yearEls =
            document.getElementsByClassName("Calendar__yearText");
          const yearEls = Array.from(_yearEls).filter((element) => {
            const ariaHidden = element.getAttribute("aria-hidden");
            return !ariaHidden || ariaHidden === "false";
          });

          let year = "";
          let month = "";
          if (monthEls.length > 0) {
            const monthEl = monthEls[0];
            month = monthEl.innerHTML;
          }
          if (yearEls.length > 0) {
            const yearEl = yearEls[0];
            year = yearEl.innerHTML;
          }
          const _year = Number(year.replace(/[^0-9]/g, ""));
          const _month = Number(month.replace(/[^0-9]/g, ""));
          setCurMonth(
            dayjs()
              .set("year", _year)
              .set("month", _month - 1)
          );
        }, 2000);
      });
    }

    const months = document.getElementsByClassName(
      "Calendar__monthSelectorItem"
    );
    for (var i = 0; i < months.length; i++) {
      const eachCell = months[i];
      eachCell.addEventListener("touchstart", () => {
        setIsLoading(true);
        setTimeout(() => {
          const _monthEls = document.getElementsByClassName(
            "Calendar__monthText"
          );
          const monthEls = Array.from(_monthEls).filter((element) => {
            const ariaHidden = element.getAttribute("aria-hidden");
            return !ariaHidden || ariaHidden === "false";
          });

          const _yearEls =
            document.getElementsByClassName("Calendar__yearText");
          const yearEls = Array.from(_yearEls).filter((element) => {
            const ariaHidden = element.getAttribute("aria-hidden");
            return !ariaHidden || ariaHidden === "false";
          });

          let year = "";
          let month = "";
          if (monthEls.length > 0) {
            const monthEl = monthEls[0];
            month = monthEl.innerHTML;
          }
          if (yearEls.length > 0) {
            const yearEl = yearEls[0];
            year = yearEl.innerHTML;
          }
          const _year = Number(year.replace(/[^0-9]/g, ""));
          const _month = Number(month.replace(/[^0-9]/g, ""));
          setCurMonth(
            dayjs()
              .set("year", _year)
              .set("month", _month - 1)
          );
        }, 1000);
      });
    }
  };
  const handleChangedMonth = () => {
    console.log("month changed");
    const _monthEls = document.getElementsByClassName("Calendar__monthText");
    const monthEls = Array.from(_monthEls).filter((element) => {
      const ariaHidden = element.getAttribute("aria-hidden");
      return !ariaHidden || ariaHidden === "false";
    });

    const _yearEls = document.getElementsByClassName("Calendar__yearText");
    const yearEls = Array.from(_yearEls).filter((element) => {
      const ariaHidden = element.getAttribute("aria-hidden");
      return !ariaHidden || ariaHidden === "false";
    });

    let year = dayjs().year();
    let month = dayjs().month() + 1;
    if (monthEls.length > 0) {
      const monthEl = monthEls[0];
      month = Number(monthEl.innerHTML.split("월")[0]);
    }
    if (yearEls.length > 0) {
      const yearEl = yearEls[0];
      year = Number(yearEl.innerHTML.split("년")[0]);
    }
    setCurMonth(
      dayjs()
        .set("year", year)
        .set("month", month - 1)
    );
  };

  const onShare = async () => {
    const shareRes = await share({
      title: res?.title,
      url: window.location.origin + "/products/" + res?.uuid,
    });
    console.log("shar res: ", shareRes);
  };

  const content = useMemo(() => {
    if (!res) return null;

    if (menu === 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            maxWidth: "100%",
          }}
        >
          <ProductCarousel imgs={res?.thumbnail_image_urls || []} />
          <ShortContentWrapper style={{ marginBottom: 24 }}>
            <ShortContentTopWrapper>
              <Location>{res?.location}</Location>
              <ShortTitleDescContainer>
                <ShortTitleDescWrapper>
                  <ShortTitle>{res?.title}</ShortTitle>
                  <ShortDesc>{res?.description}</ShortDesc>
                </ShortTitleDescWrapper>
                <UploadIcon src={UploadIconSvg} onClick={onShare} />
              </ShortTitleDescContainer>
              <PriceText
                price={res?.origin_price || 0}
                currentPrice={res?.current_price || 0}
                discountPct={res?.sale_percentage || 0}
                priceFontSize={24}
                discountFontSize={24}
                strikeFontSize={16}
              />
              <ProductSeller productSellerUuid={res?.seller_uuid || ""} />
            </ShortContentTopWrapper>
          </ShortContentWrapper>
          <ProductSellerPhoneInfo />
          <ProductInfo productDetail={res} />
          <ProductIntroduction
            image={res?.image || ""}
            content={res?.content || ""}
          />
          <ProductHelpCenter
            refund_policy={res?.refund_policy || ""}
            faq={res?.faq || ""}
          />
        </div>
      );
    } else if (menu === 1) {
      return (
        <ProductMap
          lat={res?.lat}
          lon={res?.lon}
          title={res?.start_place}
          url={res?.map_url}
        />
      );
    } else if (menu === 2) {
      return <ProductReviewContent porductUuid={res?.uuid || ""} />;
    } else if (menu === 3) {
      return (
        <ProductQuestion
          productUuid={res?.uuid || ""}
          productTitle={res?.title || ""}
        />
      );
    }
    return null;
  }, [menu, res]);

  return (
    <ProductContainer>
      <MenusWrapper>
        <MenuWrapper>
          <MenuItem isActive={menu === 0} onClick={() => setMenu(0)}>
            상품설명
          </MenuItem>
        </MenuWrapper>
        <MenuWrapper>
          <MenuItem isActive={menu === 1} onClick={() => setMenu(1)}>
            찾아오는 길
          </MenuItem>
        </MenuWrapper>
        <MenuWrapper>
          <MenuItem isActive={menu === 2} onClick={() => setMenu(2)}>
            상품후기
          </MenuItem>
        </MenuWrapper>
        <MenuWrapper>
          <MenuItem isActive={menu === 3} onClick={() => setMenu(3)}>
            상품문의
          </MenuItem>
        </MenuWrapper>
      </MenusWrapper>
      <ContentContainer>
        {content}
        <Sheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          style={{ zIndex: 100 }}
          snapPoints={[0.55, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <ShortBottomWrapper>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    // marginTop: "24px",
                  }}
                >
                  <DateWrapper style={{ flex: 1, marginRight: 8 }}>
                    {selectedDay
                      ? dayjs(
                          `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
                        ).format("YYYY-MM-DD")
                      : dayjs().format("YYYY-MM-DD")}
                  </DateWrapper>
                  <DatePicker
                    locale={myCustomLocale}
                    renderInput={renderCustomInput} // render a custom input
                    onChange={setSelectedDay}
                    inputPlaceholder="날짜선택"
                    shouldHighlightWeekends
                    disabledDays={isLoading ? allDisabledDays : disabledDays}
                    minimumDate={dayjsToDay(dayjs())}
                  />
                </div>
                <Select
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onChange={(data) => {
                    if (data?.available_seat === 0) {
                      alert("해당 옵션은 현재 예약이 다 차있습니다.");
                      return;
                    }
                    onSelectSeat(data?.uuid || "");
                  }}
                  isSearchable={false}
                  value={null}
                  options={optionSeats}
                  styles={{
                    container: (base) => ({ width: "100%" }),
                    control: (base) => ({ ...base, cursor: "pointer" }),
                  }}
                  placeholder={"상품 옵션을 선택해주세요."}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: ({ data, innerProps, innerRef }) => {
                      if (!data) return null;
                      return (
                        <OptionWrapper ref={innerRef} {...innerProps}>
                          <OptionTitle>{data.title}</OptionTitle>
                          <OptionPriceWrapper>
                            <OptionPrice>{res?.current_price}</OptionPrice>
                            <OptionDiscount>{res?.origin_price}</OptionDiscount>
                            <OptionSeat>
                              {data.available_seat}개 남음
                            </OptionSeat>
                          </OptionPriceWrapper>
                        </OptionWrapper>
                      );
                    },
                  }}
                />
                {selectedOptionSeat && selectedDay && (
                  <SelectedOptionWrapper>
                    <SelectedOptionTitle>
                      {dayStringFromDay(selectedDay)} {res?.duration}
                    </SelectedOptionTitle>
                    <SelectedOptionTitle>
                      {selectedOptionSeat.title}
                    </SelectedOptionTitle>
                    <SelectedPriceWrapper>
                      <SelectedQuantityWrapper>
                        <BtnMinusSvg
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (quantity > 1) {
                              setQuantity((v) => v - 1);
                            }
                          }}
                        />
                        <QunatityNum>{quantity}</QunatityNum>
                        <BtnPlusSvg
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (quantity < maxQuantity) {
                              setQuantity((v) => v + 1);
                            }
                          }}
                        />
                      </SelectedQuantityWrapper>
                      <SelectedOptionPriceWrapper>
                        <SelectedoptionPrice>
                          {res?.current_price?.toLocaleString()}원
                        </SelectedoptionPrice>
                        <SelectedoptionDiscount>
                          {res?.origin_price?.toLocaleString()}원
                        </SelectedoptionDiscount>
                      </SelectedOptionPriceWrapper>
                    </SelectedPriceWrapper>
                  </SelectedOptionWrapper>
                )}
              </ShortBottomWrapper>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
        <BottomFixedWrapper>
          <LikeParticipateWrapper>
            <LikeWrapper onClick={toggle}>
              {likeUuid ? <HeartLineIconActive /> : <HeartLineIconSvg />}
              <LikeText>{likeCount}</LikeText>
            </LikeWrapper>
            <Button
              onClick={() => {
                if (!window.localStorage.getItem("accessToken")) {
                  if (!res || !selectedOptionDay || !selectedOptionSeat) {
                    setLoginPopup({
                      next: "/products/" + res?.uuid,
                      state: {},
                    });
                  } else {
                    setLoginPopup({
                      next: "/payment",
                      state: {
                        product: res,
                        quantity,
                        selectedOptionDay,
                        selectedOptionSeat,
                      },
                    });
                  }
                  return;
                }
                if (!res || !isOpen) {
                  setIsOpen(true);
                  return;
                }
                if (!res || !selectedOptionDay || !selectedOptionSeat) {
                  alert("날짜와 옵션을 선택해주세요.");
                  return;
                }
                navigate("/payment", {
                  state: {
                    product: res,
                    quantity,
                    selectedOptionDay,
                    selectedOptionSeat,
                  },
                });
              }}
              style={{
                background: "#000",
                color: "white",
                border: "none",
              }}
            >
              구매하기
            </Button>
          </LikeParticipateWrapper>
        </BottomFixedWrapper>
      </ContentContainer>
    </ProductContainer>
  );
}
