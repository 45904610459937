import nollyAxios from "./nollyAxios";

export interface CompanyCodeRes {
  uuid: string;
  code: string;
  name: string;
}

export function getCompanyNameByCode(code: string): Promise<CompanyCodeRes> {
  return nollyAxios
    .post(`/api/companies/validate_code`, { code })
    .then(({ data }) => data);
}
