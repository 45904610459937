import styled from "@emotion/styled";
import Select from "react-select";
import { deleteUser } from "../../api/authApi";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { userState } from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
import Input from "../Common/Input";
import { useEffect, useMemo, useState } from "react";
import Radio from "../Common/Radio";
import NumberInput from "../Common/NumberInput";
import { BankName } from "../../types/enums";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  flex: 1 0 0;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const Notice = styled.div`
  align-self: stretch;
  color: var(--grey-90, #474748);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%; /* 27px */
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const SubTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  align-self: stretch;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
`;
const PoinDesc = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  align-self: stretch;
`;
const RefundablePointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
const RefundableTitle = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;
const RefundablePoint = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  line-height: 150%; /* 33px */
`;

const BankInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;
const BankLabel = styled.div`
  width: 110px;
`;

const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;
const ReasonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const ReasonItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 42px;
  flex: 1 0 0;
  cursor: pointer;
`;
const ReasonText = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const DisclaimerTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;
const DisclaimerText = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
`;
const CheckDisclaimer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;
const CheckDisclaimerText = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 500;
`;

enum ReasonEnum {
  "not_satisfied" = "서비스 불만족",
  "privacy" = "개인정보의 유출 우려",
  "move" = "퇴사 및 이직",
  "insufficient" = "상품의 부족",
  "other" = "기타",
}

export default function Signout() {
  const user = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);
  const navigate = useNavigate();
  const [bankName, setBankeName] = useState("");
  const [accountNum, setAccountNum] = useState<number | undefined>(undefined);
  const [ownerName, setOwnerName] = useState("");
  const [selectedReason, setSelectedReason] = useState<
    keyof typeof ReasonEnum | null
  >(null);
  const [reason, setReason] = useState("");
  const [agreeDisclaimer, setAgreeDisclaimer] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!selectedReason || selectedReason === "other") {
      setReason("");
    } else {
      setReason(ReasonEnum[selectedReason]);
    }
  }, [selectedReason]);

  const handleSubmit = async () => {
    if (user) {
      if (!bankName) {
        alert("은행명을 입력해주세요.");
        return;
      }
      if (!accountNum) {
        alert("계좌번호를 입력해주세요.");
        return;
      }
      if (!agreeDisclaimer) {
        alert("유의사항 확인에 동의해주세요.");
        return;
      }
      deleteUser(user.uuid, reason, bankName, accountNum + "", password)
        .then(() => {
          alert("회원탈퇴에 성공하셨습니다.");
          resetUser();
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");
          navigate("/login");
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.errors) {
            const error = e.response.data.errors[0];

            if (error && error.detail === "UserPasswordInvalid")
              return alert("비밀번호를 잘못 입력하였습니다.");

            return alert("회원탈퇴에 실패하였습니다.");
          }
          return alert("회원탈퇴에 실패하였습니다.");
        });
    }
  };

  const options = useMemo(() => {
    return Object.entries(BankName).map(([k, v], idx) => ({
      label: v,
      value: k,
    }));
  }, []);

  return (
    <Container>
      <Header>
        <HeaderTitleWrapper>
          <HeaderTitle>회원탈퇴</HeaderTitle>
        </HeaderTitleWrapper>
      </Header>
      <Notice>
        놀리 회원탈퇴를 원하시나요? <br />
        그동안 함께 해주신 회원님께 진심으로 감사드립니다.
        <br />
        회원탈퇴 전 아래의 ‘유의사항’을 확인하여 주시기 바랍니다.
      </Notice>
      {/* <PointWrapper>
        <SubTitle style={{ marginBottom: 16 }}>소멸 예정 포인트</SubTitle>
        <PoinDesc>
          회원탈퇴 시 회원님께서 보유하고 계신 포인트는 소멸됩니다. 반환을
          원하신다면 계좌번호를 아래에 남겨주세요. <br />
          반드시 본인명의의 계좌로만 반환이 가능하며, 모든 반환이 완료된 것을
          확인하신 후 탈퇴를 진행해 주시기 바랍니다.
        </PoinDesc>
        <RefundablePointWrapper>
          <RefundableTitle>환불 가능 포인트</RefundableTitle>
          <RefundablePoint>
            {user?.point.amount.toLocaleString()} 포인트
          </RefundablePoint>
        </RefundablePointWrapper>
        <BankInputWrapper>
          <BankLabel>은행명</BankLabel>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            isSearchable={false}
            options={options}
            onChange={(a) => setBankeName(a?.value || "PRODUCT")}
            styles={{
              container: (base) => ({ ...base, width: "100%", height: 42 }),
              control: (base) => ({
                ...base,
                cursor: "pointer",
                width: 282,
                height: 42,
              }),
            }}
            placeholder={"은행을 선택해주세요."}
            components={{ IndicatorSeparator: () => null }}
          />
        </BankInputWrapper>
        <BankInputWrapper>
          <BankLabel>계좌번호</BankLabel>
          <NumberInput
            value={accountNum}
            onSet={(setAccountNum)}
            onChange={(e) => setAccountNum(Number(e.currentTarget.value))}
            placeholder="계좌번호"
            style={{ height: 42, width: 282 }}
          />
        </BankInputWrapper>
        <BankInputWrapper>
          <BankLabel>예금주 명</BankLabel>
          <Input
            value={ownerName}
            style={{ height: 42, width: 282 }}
            onChange={(e) => setOwnerName(e.currentTarget.value)}
            placeholder="예금주명"
          />
        </BankInputWrapper>
      </PointWrapper> */}
      <ReasonWrapper>
        <SubTitle>탈퇴 사유</SubTitle>
        <ReasonRow>
          <ReasonItem onClick={() => setSelectedReason("not_satisfied")}>
            <Radio checked={selectedReason === "not_satisfied"} />
            <ReasonText>{ReasonEnum["not_satisfied"]}</ReasonText>
          </ReasonItem>
          <ReasonItem onClick={() => setSelectedReason("privacy")}>
            <Radio checked={selectedReason === "privacy"} />
            <ReasonText>{ReasonEnum["privacy"]}</ReasonText>
          </ReasonItem>
        </ReasonRow>
        <ReasonRow>
          <ReasonItem onClick={() => setSelectedReason("move")}>
            <Radio checked={selectedReason === "move"} />
            <ReasonText>{ReasonEnum["move"]}</ReasonText>
          </ReasonItem>
          <ReasonItem onClick={() => setSelectedReason("insufficient")}>
            <Radio checked={selectedReason === "insufficient"} />
            <ReasonText>{ReasonEnum["insufficient"]}</ReasonText>
          </ReasonItem>
        </ReasonRow>
        <ReasonRow>
          <ReasonItem onClick={() => setSelectedReason("other")}>
            <Radio checked={selectedReason === "other"} />
            <ReasonText>{ReasonEnum["other"]}</ReasonText>
          </ReasonItem>
          {selectedReason === "other" && (
            <Input
              placeholder="기타 불편사항을 100자 이내로 작성해주세요."
              style={{ height: 42 }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )}
        </ReasonRow>
      </ReasonWrapper>
      <DisclaimerWrapper>
        <SubTitle>탈퇴 시 유의사항</SubTitle>
        <div style={{ width: "100%" }}>
          <DisclaimerTitle>
            1. 동일 아이디 또는 이메일 가입 불가 및 회원정보 삭제
          </DisclaimerTitle>
          <DisclaimerText>
            탈퇴 처리 시 즉각적으로 회원정보가 삭제되며 동일한 아이디 또는
            이메일로 회원가입이 불가합니다.
          </DisclaimerText>
        </div>
        <div style={{ width: "100%" }}>
          <DisclaimerTitle>2. 잔여 포인트</DisclaimerTitle>
          <DisclaimerText>
            탈퇴 시 포인트는 소멸되며 모든 고객 정보가 삭제되어 조회가
            불가능합니다.<br/> 반드시 포인트 {">"} 환불 페이지에서 환불을 완료하신 후
            탈퇴를 진행해 주시기 바랍니다. 요청이 없으시면 효력이 상실됩니다.
            {/* 탈퇴시 포인트는 소멸됩니다. 회원 탈퇴 시 모든 정보가 삭제되어 조회가
            불가능합니다. 반드시 잔여 포인트를 계좌번호를 통해 반환받으신 후
            탈퇴하셔야 합니다. 요청이 없으시면 효력이 상실됩니다. */}
          </DisclaimerText>
        </div>
        <div style={{ width: "100%" }}>
          <DisclaimerTitle>3. 서비스의 정보 삭제</DisclaimerTitle>
          <DisclaimerText>
            회원님께서 구매하신 구매이력 등은 고객지원을 위해 5년 동안만
            보관되며 그 이후에는 삭제됩니다.
          </DisclaimerText>
        </div>
        <div style={{ width: "100%" }}>
          <DisclaimerTitle>4. 불량이용 및 이용제한에 관한 기록</DisclaimerTitle>
          <DisclaimerText>
            개인정보취급방침에 따라 불량이용 및 이용제한에 관한 기록은 1년 동안
            삭제되지 않고 보관됩니다.
          </DisclaimerText>
        </div>
        <CheckDisclaimer onClick={() => setAgreeDisclaimer((v) => !v)}>
          <Radio checked={agreeDisclaimer} />
          <CheckDisclaimerText>유의사항을 확인하였습니다.</CheckDisclaimerText>
        </CheckDisclaimer>
      </DisclaimerWrapper>
      <DisclaimerWrapper>
        <SubTitle>비밀번호 확인</SubTitle>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <BankLabel>아이디</BankLabel>
            <div>{user?.username}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <BankLabel>비밀번호</BankLabel>
            <Input
              type={"password"}
              style={{ height: 42 }}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </div>
        </div>
      </DisclaimerWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          gap: 8,
        }}
      >
        <Button
          style={{ width: 70, fontSize: 15 }}
          onClick={() => handleSubmit()}
        >
          확인
        </Button>
        <Button
          style={{
            background: "#29292A",
            color: "white",
            border: "none",
            width: 70,
            fontSize: 15,
          }}
          onClick={() => navigate(-1)}
        >
          취소
        </Button>
      </div>
    </Container>
  );
}
