import styled from "@emotion/styled";
import { useState } from "react";
import { findIdByEmail } from "../../api/authApi";
import { registerEmail, verifyEmail } from "../../api/emailApi";
import Button from "../Common/Button";
import Input from "../Common/Input";

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Label = styled.div`
  color: var(--grey-70, #939394);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const VerificationCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;
const VerificationCodeDesc = styled.div`
  color: var(--red-100, #f00);
  font-size: 14px;
  font-weight: 400;
`;

export default function FindIdByEmail({
  setFoundId,
}: {
  setFoundId: (v: string) => void;
}) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [vCode, setVcode] = useState("");
  const [sessionToken, setSessionToken] = useState("");

  const handleEmailFind = async () => {
    if (sessionToken) {
      checkWithEmail();
    } else {
      handleClickSendEmail();
    }
  };

  const handleClickSendEmail = async () => {
    try {
      const { session_token } = await registerEmail(email);
      setSessionToken(session_token);
    } catch (e) {}
  };

  const checkWithEmail = async () => {
    try {
      await verifyEmail(email, sessionToken, vCode);
      const { username } = await findIdByEmail(firstName, sessionToken);
      setFoundId(username);
    } catch (e) {}
  };
  return (
    <>
      <InputWrapper>
        <Label>이름</Label>
        <Input
          disabled={!!sessionToken}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          placeholder="이름을 입력해주세요."
          style={{ marginBottom: 12 }}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>이메일</Label>
        <Input
          disabled={!!sessionToken}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder="이메일을 입력해주세요."
          style={{ marginBottom: 12 }}
        />
      </InputWrapper>
      {sessionToken && (
        <>
          <InputWrapper>
            <Label>인증번호</Label>
            <VerificationCodeWrapper>
              <Input
                value={vCode}
                onChange={(e) => setVcode(e.currentTarget.value)}
                placeholder="인증번호 7자리"
                countDown={sessionToken}
              />
              <Button
                style={{ width: 120, fontSize: 15, color: "#474748" }}
                onClick={() => handleClickSendEmail()}
              >
                재발송
              </Button>
            </VerificationCodeWrapper>
            <VerificationCodeDesc>
              인증번호를 입력해주세요.
            </VerificationCodeDesc>
          </InputWrapper>
        </>
      )}
      <Button
        onClick={() => handleEmailFind()}
        style={{
          background: "#29292A",
          color: "white",
          border: "none",
          marginBottom: 12,
        }}
      >
        확인
      </Button>
    </>
  );
}
