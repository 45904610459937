import styled from "@emotion/styled";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { purchase } from "../api/purchaseApi";
import { CommonContainer } from "../components/Common";
import { alertExceptionMsg } from "../utils/exception.util";

const PaymentContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

export default function PaymentResultPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const imp_uid = searchParams.get("imp_uid") || ""; // test
  // const imp_success = (searchParams.get("imp_success") || "false") === "true"; // test
  const error_msg = searchParams.get("error_msg") || ""; // test

  useEffect(() => {
    if (!imp_uid) {
      if (error_msg) alert(error_msg);
      else alert("결제 실패하였습니다.");
      navigate("/payment");
      return;
    } else {
      if (error_msg) {
        alert(error_msg);
        navigate("/payment");
        return;
      } else {
        handlePurchaseResult();
      }
      // if (!imp_success) {
      //   else alert("결제 실패하였습니다.");
    }
  }, [imp_uid, error_msg]);

  const handlePurchaseResult = () => {
    // if (!imp_success) {
    //   alert("결제 실패하였습니다.");
    //   navigate("/payment");
    //   return;
    // }

    const purchaseInfo = localStorage.getItem("cur_purchase_info");
    if (!purchaseInfo) {
      alert("결제 정보가 없습니다.");
      navigate("/payment");
      return;
    }

    const { productUuid, optionDayUuid, optionSeatUuid, pointSent, quantity } =
      JSON.parse(purchaseInfo);

    purchase(
      productUuid,
      optionDayUuid,
      optionSeatUuid,
      pointSent || 0,
      quantity,
      imp_uid
    )
      .then(() => {
        alert("결제 성공하였습니다.");
        navigate("/mypage/order");
      })
      .catch((err) => {
        alertExceptionMsg(err.response?.data, "결제에 실패하였습니다.");
      });
  };

  return (
    <PaymentContainer>
      <ContentContainer></ContentContainer>
    </PaymentContainer>
  );
}
