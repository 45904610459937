import { QuestionTopic } from "../types/enums";
import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Question {
  uuid: string;
  title: string;
  content: string;
  topic: keyof typeof QuestionTopic;
  has_answer: boolean;
  created: string;
  modified: string;
}

export function getQuestionList(
  limit?: number,
  offset?: number
): Promise<PaginationType<Question>> {
  let query = "?";
  query += `offset=${offset}&`;
  query += `limit=${limit}&`;
  return nollyAxios.get(`/api/questions${query}`).then(({ data }) => data);
}

export interface QuestionDetail extends Question {
  content: string;
  images: string[];
}

export function getQuestionDetail(
  questionUuid: string
): Promise<QuestionDetail> {
  return nollyAxios
    .get(`/api/questions/${questionUuid}`)
    .then(({ data }) => data);
}

export function createQuestion(
  topic: string,
  title: string,
  content: string
): Promise<Question> {
  const payload = { title, content, topic };
  return nollyAxios.post(`/api/questions`, payload).then(({ data }) => data);
}

export interface Answer {
  uuid: string;
  title: string;
  content: string;
  created: string;
  modified: string;
}

export function getAnswerList(
  questionUuid: string
): Promise<PaginationType<Answer>> {
  return nollyAxios
    .get(`/api/questions/${questionUuid}/answers?limit=${30}&offset=${0}`)
    .then(({ data }) => data);
}
