import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
/// components ///
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { me, refreshToken } from "../api/authApi";
import Layout from "../components/Common/Layout";
import GlobalError from "../components/GlobalError";
import ActivitiesPage from "../pages/ActivitiesPage";
import AdoptPage from "../pages/AdoptPage";
import AskPage from "../pages/AskPage";
import CareersPage from "../pages/CareersPage";
import FindIdPage from "../pages/FindIdPage";
import FindPwPage from "../pages/FindPwPage";
import HelpCenterPage from "../pages/HelpCenterPage";
import IntroductionPage from "../pages/IntroductionPage";
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";
import MarketingPage from "../pages/MarketingPage";
import MyAskCreatePage from "../pages/MyAskCreatePage";
import MyAskPage from "../pages/MyAskPage";
import MyLikePage from "../pages/MyLikePage";
import MyOrderDetailPage from "../pages/MyOrderDetailPage";
import MyOrderPage from "../pages/MyOrderPage";
import MyPointPage from "../pages/MyPointPage";
import MyPointRefundPage from "../pages/MyPointRefundPage";
import MyProfilePage from "../pages/MyProfilePage";
import MyReviewCreatePage from "../pages/MyReviewCreatePage";
import MyReviewDetailPage from "../pages/MyReviewDetailPage";
import MyReviewPage from "../pages/MyReviewPage";
import MySignoutPage from "../pages/MySignoutPage";
import NoticePage from "../pages/NoticePage";
import PartnershipPage from "../pages/PartnetshipPage";
import PaymentPage from "../pages/PaymentPage";
import PrivacyOptionalPage from "../pages/PrivacyOptionalPage";
import PrivacyPage from "../pages/PrivacyPage";
import ProductPage from "../pages/ProductPage";
import PromotionPage from "../pages/PromotionPage";
import PwResetPage from "../pages/PwResetPage";
import SearchPage from "../pages/SearchPage";
import SellerPage from "../pages/SellerPage";
import SignupPage from "../pages/SignupPage";
import TermsPage from "../pages/TermsPage";
import { userState } from "../store/authStore";
import PaymentResultPage from "../pages/PaymentResultPage";

export default function Index() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useRecoilState(userState);
  const resetUser = useResetRecoilState(userState);

  useEffect(() => {
    if (window.localStorage.getItem("refreshToken")) {
      (async () => {
        try {
          await refreshToken();
          const user = await me();
          setUser(user);
        } catch (e) {
          resetUser();
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      resetUser();
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const nextPath = window.localStorage.getItem("nextPath");
      const nextState = window.localStorage.getItem("nextState");
      if (!nextPath || !nextState) return;

      window.localStorage.removeItem("nextPath");
      window.localStorage.removeItem("nextState");
      navigate(nextPath, { state: JSON.parse(nextState) });
    }
  }, [user]);

  if (isLoading) return null;

  return (
    <Layout>
      {user ? (
        <Routes>
          <Route path="/" element={<MainPage />} />
          {/* <Route path="/activities/:category" element={<ActivitiesPage />} /> */}
          {/* <Route path="/classes/:category" element={<ActivitiesPage />} />
          <Route path="/workations/:category" element={<ActivitiesPage />} /> */}
          <Route path="/search" element={<SearchPage />} />
          <Route path="/pw-reset" element={<PwResetPage />} />
          <Route path="/introduction" element={<IntroductionPage />} />
          <Route path="/partnership" element={<PartnershipPage />} />
          <Route path="/promotions/:id" element={<PromotionPage />} />
          <Route path="/sellers/:id" element={<SellerPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/products/:id/ask" element={<AskPage />} />
          <Route path="/adopt-enterprise" element={<AdoptPage />} />
          <Route path="/notices" element={<NoticePage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/payment-result" element={<PaymentResultPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/help-center" element={<HelpCenterPage />} />
          <Route path="/mypage/order/detail" element={<MyOrderDetailPage />} />
          <Route path="/mypage/order" element={<MyOrderPage />} />
          <Route path="/mypage/like" element={<MyLikePage />} />
          <Route
            path="/mypage/review/create"
            element={<MyReviewCreatePage />}
          />
          <Route
            path="/mypage/review/detail"
            element={<MyReviewDetailPage />}
          />
          <Route path="/mypage/review" element={<MyReviewPage />} />
          <Route path="/mypage/point/refund" element={<MyPointRefundPage />} />
          <Route path="/mypage/point" element={<MyPointPage />} />
          <Route path="/mypage/ask/create" element={<MyAskCreatePage />} />
          <Route path="/mypage/ask" element={<MyAskPage />} />
          <Route path="/mypage/profile" element={<MyProfilePage />} />
          <Route path="/mypage/exit" element={<MySignoutPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/privacy-optional" element={<PrivacyOptionalPage />} />
          <Route path="/marketing-agreement" element={<MarketingPage />} />
          <Route path="/p/:topic/:category" element={<ActivitiesPage />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/mypage/exit" element={<MySignoutPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/find-id" element={<FindIdPage />} />
          <Route path="/find-pw" element={<FindPwPage />} />
          <Route path="/pw-reset" element={<PwResetPage />} />
          <Route path="/introduction" element={<IntroductionPage />} />
          <Route path="/partnership" element={<PartnershipPage />} />
          {/* <Route path="/activities/:category" element={<ActivitiesPage />} />
          <Route path="/classes/:category" element={<ActivitiesPage />} />
          <Route path="/workations/:category" element={<ActivitiesPage />} /> */}
          <Route path="/search" element={<SearchPage />} />
          <Route path="/promotions/:id" element={<PromotionPage />} />
          <Route path="/sellers/:id" element={<SellerPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/privacy-optional" element={<PrivacyOptionalPage />} />
          <Route path="/marketing-agreement" element={<MarketingPage />} />
          <Route path="/products/:id/ask" element={<AskPage />} />
          <Route path="/adopt-enterprise" element={<AdoptPage />} />
          <Route path="/notices" element={<NoticePage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/help-center" element={<HelpCenterPage />} />
          <Route path="/p/:topic/:category" element={<ActivitiesPage />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      )}
      {/* <Route element={<PrivateRoute />}> */}
      {/* <Route path="/" element={} /> */}
      {/* </Route> */}
      {/* <Route path="/" element={}/> */}
    </Layout>
  );
}

function NotFound() {
  return <GlobalError error={null} />;
}
