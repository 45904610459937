import { User } from "./authApi";
import nollyAxios from "./nollyAxios";
import { PaginationType, Product } from "./productApi";
import { ProductOptionDay } from "./productOptionApi";

export type PurchaseType = {
  uuid: string;
  origin_price: number;
  sale_amount: number;
  point_spent: number;
  amount_spent: number;
  purchase_method: string;
  imp_uid: string;
  created: string;
  modified: string;
};

export function purchase(
  product_uuid: string,
  option_day_uuid: string,
  option_seat_uuid: string,
  point_spent: number,
  quantity: number = 1,
  imp_uid?: string,
): Promise<PurchaseType> {
  const payload: any = {
    option_day_uuid,
    option_seat_uuid,
    point_spent,
    quantity
  };
  if (imp_uid) payload.imp_uid = imp_uid;

  return nollyAxios
    .post(`/api/products/` + product_uuid + "/product_purchases", payload)
    .then(({ data }) => data);
}

export interface Purchase {
  uuid: string;
  product: Product;
  option_day: ProductOptionDay;
  origin_price: number;
  sale_amount: number;
  point_spent: number;
  quantity: number;
  amount_spent: number;
  purchase_method: string;
  status: "PAID" | "REFUND_REQUESTED" | "REFUNDED_MANUAL" | "REFUNDED_AUTO";
  created: string;
  modified: string;
}

export function getPruchases(
  limit?: number,
  offset?: number,
  creaetd__gte?: string
): Promise<PaginationType<Purchase>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  if (creaetd__gte) query += `creaetd__gte=${creaetd__gte}`;
  return nollyAxios
    .get(`/api/product_purchases${query}`)
    .then(({ data }) => data);
}

interface Seller {
  uuid: string;
  user: User;
  profile_image: string;
  bio: string;
  review_count: number;
  review_avg: number;
  created: string;
  modified: string;
}

interface ProductT {
  uuid: string;
  title: string;
  start_place: string;
  seller: Seller;
  created: string;
  modified: string;
}

export interface PurchaseDetail {
  uuid: string;
  product: ProductT;
  origin_price: number;
  sale_amount: number;
  point_spent: number;
  amount_spent: number;
  purchase_method: string;
  status: string;
  quantity: number;
  created: string;
  modified: string;
}

export function getPruchaseDetail(
  uuid: string
): Promise<PurchaseDetail> {
  return nollyAxios
    .get(`/api/product_purchases/${uuid}`)
    .then(({ data }) => data);
}

export function cancelPurchase(
  purchaseUuid: string
): Promise<void> {
  return nollyAxios
    .put(`/api/product_purchases/${purchaseUuid}/cancel`)
    .then(({ data }) => data);
}
