import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Banner, getSideBanners } from "../../api/bannerApi";
import Logo from "../../assets/Logo.svg";
import MoreIcon from "../../assets/MoreIcon.svg";
import MoreIconActive from "../../assets/MoreIconActive.svg";
import Alone from "../../assets/moreMenu/alone.svg";
import Lover from "../../assets/moreMenu/lover.svg";
import Family from "../../assets/moreMenu/family.svg";
import Bucket from "../../assets/moreMenu/bucket.svg";
import Flex from "../../assets/moreMenu/flex1.svg";
import Flex2 from "../../assets/moreMenu/flex2.svg";
import Mountain from "../../assets/moreMenu/mountain.svg";
import Field from "../../assets/moreMenu/field.svg";
import Sea from "../../assets/moreMenu/sea.svg";
import Pick from "../../assets/moreMenu/pick.svg";
import { CommonContainer } from "../Common";
import { IsActiveProps } from "../Product/ProductCommon";
import SearchInput from "./SearchInput";
import UserMenu from "./UserMenu";
import { noticeState } from "../../store/commonStore";
import { isMobile } from "react-device-detect";

const HeaderContainer = styled.header`
  width: 100%;
  padding-top: 24px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  z-index: 101;
  background-color: white;
  /* position: relative; */
`;
const Container = styled(CommonContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
`;
const Left = styled.div``;
const Right = styled.div`
  flex: 1;
`;
const TopContainer = styled(CommonContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const TopLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ImgLogo = styled.img`
  /* margin-right: 24px;
  cursor: pointer; */
`;
const LogoLink = styled.a`
  margin-right: 24px;
`;
const BottomContainer = styled(CommonContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TextBtn = styled.div<IsActiveProps>`
  cursor: pointer;
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${(props) => (props.isActive ? "#FF0000" : "black")};
  box-shadow: ${(props) => (props.isActive ? "inset 0 -3px 0 red" : "none")};
`;
const MoreBtn = styled(TextBtn)`
  font-size: 16px;
  font-weight: 500;
  border-bottom: none;
  color: ${(props) => (props.isActive ? "#FF0000" : "#939394")};
`;
const BannerItem = styled.div`
  cursor: pointer;
`;
const Icon = styled.img``;
const LeftButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const RightButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const ActivityWrapper = styled.div`
  position: absolute;
  top: 126px;
  left: 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  background: var(--grey-20, #f9f9fa);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
`;
const ActivityContainer = styled.div`
  display: flex;
  width: 1024px;
  padding: 40px 0px;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
`;
const ActivityRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;
const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  opacity: 0.4;
  background: var(--black, #000);
`;
const EachItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const EachItemWrapper = styled.a``;
const EachItemImg = styled.img`
  border-radius: 6px;
  cursor: pointer;
`;

const MoreWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 119px;
  left: 0px;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  background: var(--grey-20, #f9f9fa);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
`;
const MoreContainer = styled.div`
  display: flex;
  width: 1024px;
  padding: 24px 0px;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
`;
const BannersWrapper = styled.div`
  display: flex;
  width: 450px;
  height: 286px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
`;
const MoreRightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;
const MoreRightDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--grey-40, #e0e0e1);
  background: var(--white, #fff);
`;

const MoreRightDetailItemTitle = styled.div`
  display: flex;
  padding: 9px 14px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-60, #b2b2b3);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.026px;
`;
const MoreRightDetailItem = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  padding: 16px 14px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-100, #29292a);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.026px;
`;

export default function Header() {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const [menuHover, setMenuHover] = useState(0);
  const clearHovers = () => {
    setHover(false);
    setMenuHover(0);
  };
  const setNotice = useSetRecoilState(noticeState);

  const [sideBanners, setSideBanners] = useState<Banner[]>([]);

  useEffect(() => {
    getSideBanners().then((data) => {
      setSideBanners(data.results);
    });
  }, []);

  return (
    <>
      {hover && menuHover !== 0 && <Dimmed />}
      <HeaderContainer onMouseLeave={() => clearHovers()}>
        <Container>
          <Left>
            <LogoLink href="/">
              <ImgLogo src={Logo} />
            </LogoLink>
          </Left>
          <Right>
            <TopContainer onMouseEnter={() => clearHovers()}>
              <TopLeft>
                <SearchInput
                  onSearch={(v) => navigate(`/search?q=${v}`)}
                  style={{ width: "280px" }}
                />
              </TopLeft>
            </TopContainer>
            <BottomContainer onMouseEnter={() => setHover(true)}>
              <LeftButtonsWrapper>
                <TextBtn
                  isActive={menuHover === 1}
                  onMouseEnter={() => setMenuHover(1)}
                >
                  액티비티
                </TextBtn>
                <TextBtn
                  isActive={menuHover === 2}
                  onMouseEnter={() => setMenuHover(2)}
                >
                  워케이션
                </TextBtn>
                <TextBtn
                  isActive={menuHover === 3}
                  onMouseEnter={() => setMenuHover(3)}
                >
                  클래스
                </TextBtn>
              </LeftButtonsWrapper>
              <RightButtonsWrapper>
                <MoreBtn
                  isActive={menuHover === 4}
                  onMouseEnter={() => setMenuHover(4)}
                >
                  <Icon src={menuHover === 4 ? MoreIconActive : MoreIcon} />
                  더보기
                </MoreBtn>
                <UserMenu />
              </RightButtonsWrapper>
            </BottomContainer>
          </Right>
          {hover && menuHover === 1 && (
            <ActivityWrapper onMouseEnter={() => setMenuHover(1)}>
              <ActivityContainer>
                <ActivityRow>
                  <EachItems>
                    <EachItemWrapper href={`/p/activity/alone`}>
                      <EachItemImg src={Alone} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/activity/with_lover`}>
                      <EachItemImg src={Lover} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/activity/with_family`}>
                      <EachItemImg src={Family} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/activity/bucket_list`}>
                      <EachItemImg src={Bucket} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/activity/flex`}>
                      <EachItemImg src={Flex} />
                    </EachItemWrapper>
                  </EachItems>
                </ActivityRow>
              </ActivityContainer>
            </ActivityWrapper>
          )}
          {hover && menuHover === 2 && (
            <ActivityWrapper onMouseEnter={() => setMenuHover(2)}>
              <ActivityContainer>
                <ActivityRow>
                  <EachItems>
                    <EachItemWrapper href={`/p/workaction/mountain`}>
                      <EachItemImg src={Mountain} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/workaction/field`}>
                      <EachItemImg src={Field} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/workaction/sea`}>
                      <EachItemImg src={Sea} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/workaction/nollys_pick`}>
                      <EachItemImg src={Pick} />
                    </EachItemWrapper>
                  </EachItems>
                </ActivityRow>
              </ActivityContainer>
            </ActivityWrapper>
          )}
          {hover && menuHover === 3 && (
            <ActivityWrapper onMouseEnter={() => setMenuHover(3)}>
              <ActivityContainer>
                <ActivityRow>
                  <EachItems>
                    <EachItemWrapper href={`/p/class/alone`}>
                      <EachItemImg src={Alone} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/class/with_lover`}>
                      <EachItemImg src={Lover} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/class/with_family`}>
                      <EachItemImg src={Family} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/class/bucket_list`}>
                      <EachItemImg src={Bucket} />
                    </EachItemWrapper>
                    <EachItemWrapper href={`/p/class/flex`}>
                      <EachItemImg src={Flex2} />
                    </EachItemWrapper>
                  </EachItems>
                </ActivityRow>
              </ActivityContainer>
            </ActivityWrapper>
          )}
          {hover && menuHover === 4 && (
            <MoreWrapper onMouseEnter={() => setMenuHover(4)}>
              <MoreContainer>
                <BannersWrapper>
                  {sideBanners.map(({ uuid, pc_image, mobile_image, url }) => (
                    <BannerItem key={uuid} onClick={() => window.open(url)}>
                      <img src={isMobile ? mobile_image : pc_image} />
                    </BannerItem>
                  ))}
                  {/* <img src={Banner1} />
                  <img src={Banner2} /> */}
                </BannersWrapper>
                <MoreRightContainer>
                  <MoreRightDetailContainer>
                    <MoreRightDetailItemTitle>
                      Let's Nolly
                    </MoreRightDetailItemTitle>
                    <MoreRightDetailItem href={"/introduction"}>
                      서비스 소개
                    </MoreRightDetailItem>
                    <MoreRightDetailItem href={"/adopt-enterprise"}>
                      기업 도입 안내
                    </MoreRightDetailItem>
                    <MoreRightDetailItem href={"/partnership"}>
                      제휴 안내
                    </MoreRightDetailItem>
                  </MoreRightDetailContainer>
                  <MoreRightDetailContainer>
                    <MoreRightDetailItemTitle>공지</MoreRightDetailItemTitle>
                    <MoreRightDetailItem
                      onClick={() => {
                        setNotice("NOTICE");
                        navigate("/notices");
                      }}
                    >
                      공지사항
                    </MoreRightDetailItem>
                    <MoreRightDetailItem
                      onClick={() => {
                        setNotice("EVENT");
                        navigate("/notices");
                      }}
                    >
                      이벤트
                    </MoreRightDetailItem>
                    <MoreRightDetailItem
                      onClick={() => {
                        setNotice("PRESS");
                        navigate("/notices");
                      }}
                    >
                      보도자료
                    </MoreRightDetailItem>
                    <MoreRightDetailItem href={"/careers"}>
                      인재채용
                    </MoreRightDetailItem>
                    <MoreRightDetailItem href={"/help-center"}>
                      고객센터
                    </MoreRightDetailItem>
                  </MoreRightDetailContainer>
                </MoreRightContainer>
              </MoreContainer>
            </MoreWrapper>
          )}
        </Container>
      </HeaderContainer>
    </>
  );
}
