import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilValue } from "recoil";
import {
  Banner,
  getBottomBanners,
  getMiddleBanners,
  getTopBanners,
} from "../api/bannerApi";
import { Insta, getInstas } from "../api/instaApi";
import {
  PaginationType,
  ProductCategoryItem,
  getProductCategoryItems,
} from "../api/productApi";
import { Promotion, getPromotionList } from "../api/promotionApi";
import CategoryImgSvg from "../assets/CategoryImg.svg";
import BannerCarousel from "../components/BannerCarousel";
import { CommonContainer } from "../components/Common";
import Instagram from "../components/Instagram";
import MainRowItems from "../components/Main/MainRowItems";
import { topicState } from "../store/productStore";
import { ProductCategory } from "../types/enums";

const MainContainer = styled(CommonContainer)`
  padding: 24px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding-bottom: 16px;
    padding-top: 0px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const CategoryContainterWrapper = styled.div`
  width: 100%;
  padding: 0px 60px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;
const CategoryContainter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  margin-top: 24px;
  max-width: 100%;
  overflow: scroll;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 0px 16px;
    gap: 16px;
    margin-bottom: 24px;
  }
`;
const CategoryItemWrapper = styled.a`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  cursor: pointer;
`;
const CategoryImgWrapper = styled.div`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CategoryImg = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 50%;
`;
const CategogryTitle = styled.div`
  color: var(--grey-60, #b2b2b3);
  font-size: 14px;
  font-weight: 500;
`;

const GalleryTitle = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.056px;
  margin-bottom: 24px;
`;
const GalleryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const GalleryImgWrapper = styled.div`
  position: relative;
  flex: 1;
`;
const SubGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;
const SubGalleryColContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

const productCategoryList = Object.keys(
  ProductCategory
) as (keyof typeof ProductCategory)[];
const productCategoryBgList = [
  "#29292A",
  "#EFEFF0",
  "#DF9393",
  "#BFBFFF",
  "#F0E154",
  "#29292A",
  "#FFCBCB",
  "#8CE68C",
  "blue",
];

export default function MainPage() {
  const [topBanners, setTopBanners] = useState<Banner[]>([]);
  const [middleBanners, setMiddleBanners] = useState<Banner[]>([]);
  const [bottomBanners, setBottomBanners] = useState<Banner[]>([]);
  const [promotions, setPromotions] =
    useState<PaginationType<Promotion> | null>(null);
  const [instas, setInstas] = useState<PaginationType<Insta> | null>(null);
  const [productCategoryItems, setProductCategoryItems] =
    useState<PaginationType<ProductCategoryItem> | null>(null);
  const topic = useRecoilValue(topicState);

  useEffect(() => {
    getTopBanners(topic).then((data) => {
      setTopBanners(data.results);
    });
    getMiddleBanners(topic).then((data) => {
      setMiddleBanners(data.results);
    });
    getBottomBanners(topic).then((data) => {
      setBottomBanners(data.results);
    });

    getPromotionList(topic).then(setPromotions);
    getInstas().then(setInstas).catch(console.error);
    getProductCategoryItems()
      .then(setProductCategoryItems)
      .catch(console.error);
  }, [topic]);

  const instarResults = useMemo(() => {
    if (!instas) return [];
    return instas.results;
  }, [instas]);

  return (
    <MainContainer>
      <ContentContainer>
        {!isMobile && (
          <BannerCarousel
            banners={topBanners}
            height={257}
            showIndicators={true}
            name={"main"}
          />
        )}
        {/* <CategoryContainterWrapper> */}
        <CategoryContainter>
          {productCategoryItems?.results.map(({ title, image, url }, idx) => (
            <CategoryItemWrapper href={url} key={`category-${idx}`}>
              <CategoryImgWrapper
                style={{ backgroundColor: productCategoryBgList[idx] }}
              >
                <CategoryImg src={image} />
              </CategoryImgWrapper>
              <CategogryTitle>{title}</CategogryTitle>
            </CategoryItemWrapper>
          ))}
          {/* {isMobile ? (
            <>
              {productCategoryItems?.results.map(
                ({ url, title, image }, idx) => (
                  <CategoryItemWrapper href={url} key={`category-${idx}`}>
                    <CategoryImgWrapper
                      style={{ backgroundColor: productCategoryBgList[idx] }}
                    >
                      <CategoryImg src={image} />
                    </CategoryImgWrapper>
                    <CategogryTitle>{title}</CategogryTitle>
                  </CategoryItemWrapper>
                )
              )}
            </>
          ) : (
            <>
              {productCategoryItems?.results.map(
                ({ title, image, url }, idx) => (
                  <CategoryItemWrapper href={url} key={`category-${idx}`}>
                    <CategoryImgWrapper
                      style={{ backgroundColor: productCategoryBgList[idx] }}
                    >
                      <CategoryImg src={image} />
                    </CategoryImgWrapper>
                    <CategogryTitle>{title}</CategogryTitle>
                  </CategoryItemWrapper>
                )
              )}
            </>
          )} */}
        </CategoryContainter>
        {/* </CategoryContainterWrapper> */}
        {isMobile && (
          <BannerCarousel
            banners={topBanners}
            height={257}
            showIndicators
            name="mid1"
          />
        )}
        {promotions?.results[0] && (
          <MainRowItems
            key={promotions?.results[0].uuid}
            title={promotions?.results[0].title}
            contents={promotions?.results[0].main_products}
            slug={promotions?.results[0].slug}
            oneline
          />
        )}
        <BannerCarousel banners={middleBanners} height={130} name="mid2" />
        {promotions?.results[1] && (
          <MainRowItems
            key={promotions?.results[1].uuid}
            title={promotions?.results[1].title}
            contents={promotions?.results[1].main_products}
            slug={promotions?.results[1].slug}
          />
        )}
        <BannerCarousel banners={bottomBanners} height={130} name="mid2" />
        {promotions?.results[2] && (
          <MainRowItems
            key={promotions?.results[2].uuid}
            title={promotions?.results[2].title}
            contents={promotions?.results[2].main_products}
            slug={promotions?.results[2].slug}
          />
        )}
        <GalleryTitle>Let's nolly!</GalleryTitle>
        {isMobile ? (
          <GalleryContainer>
            <GalleryImgWrapper>
              <Instagram insta={instarResults[0]} width="100%" height="540px" />
            </GalleryImgWrapper>
          </GalleryContainer>
        ) : (
          <GalleryContainer>
            <GalleryImgWrapper>
              <Instagram insta={instarResults[0]} width="100%" height="540px" />
            </GalleryImgWrapper>
            <SubGalleryContainer>
              <SubGalleryColContainer>
                <GalleryImgWrapper>
                  <Instagram
                    insta={instarResults[1]}
                    width="100%"
                    height="260px"
                  />
                </GalleryImgWrapper>
                <GalleryImgWrapper>
                  <Instagram
                    insta={instarResults[2]}
                    width="100%"
                    height="260px"
                  />
                </GalleryImgWrapper>
              </SubGalleryColContainer>
              <SubGalleryColContainer>
                <GalleryImgWrapper>
                  <Instagram
                    insta={instarResults[3]}
                    width="100%"
                    height="260px"
                  />
                </GalleryImgWrapper>
                <GalleryImgWrapper>
                  <Instagram
                    insta={instarResults[4]}
                    width="100%"
                    height="260px"
                  />
                </GalleryImgWrapper>
              </SubGalleryColContainer>
            </SubGalleryContainer>
          </GalleryContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
}
