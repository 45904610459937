import {
  ProductCategory,
  ProductDifficulty,
  ProductSort,
  ProductTopic,
} from "../types/enums";
import nollyAxios from "./nollyAxios";

export interface Product {
  uuid: string;
  topic: string;
  title: string;
  location: string;
  is_new: boolean;
  is_best: boolean;
  origin_price: number;
  current_price: number;
  sale_percentage: number;
  sale_amount: number;
  main_thumbnail_image: string;
  image: string;
  like_uuid: string | null;
  created: string;
  modified: string;
}
export interface ProductDetail extends Product {
  description: string;
  product_code: string;
  content: string;
  seller_uuid: string;
  review_avg: number;
  review_count: number;
  sold_count: number;
  like_count: number;
  duration: string;
  start_place: string;
  min_capacity: number;
  max_capacity: number;
  difficulty: keyof typeof ProductDifficulty;
  refund_policy: string;
  faq: string;
  inclusive: string;
  exclusive: string;
  min_age: number;
  lat: number;
  lon: number;
  map_location: string;
  map_url: string;
  thumbnail_image_urls: string[];
}

export type PaginationType<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export function productList(
  topic?: keyof typeof ProductTopic,
  q?: string,
  category?: keyof typeof ProductCategory,
  limit?: number,
  offset?: number,
  ordering?: keyof typeof ProductSort
): Promise<PaginationType<Product>> {
  let query = "?";
  if (topic) query += `topic=${topic}&`;
  if (category) query += `category=${category}&`;
  if (q) query += `q=${q}&`;
  if (ordering) query += `ordering=${ordering}&`;
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}`;
  return nollyAxios.get(`/api/products` + query).then(({ data }) => data);
}

export function productDetail(productUuid: string): Promise<ProductDetail> {
  return nollyAxios
    .get(`/api/products/` + productUuid)
    .then(({ data }) => data);
}

export interface ProductCategoryItem {
  uuid: string;
  title: string;
  order: number;
  image: string;
  url: string;
  // product_category: keyof typeof ProductCategory;
  product_category: string;
  created: string;
  modified: string;
}

export function getProductCategoryItems(): Promise<
  PaginationType<ProductCategoryItem>
> {
  return nollyAxios.get(`/api/product_category_items?&offset=${0}&limit=${30}`).then(({ data }) => data);
}
