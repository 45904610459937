import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Rating } from "react-simple-star-rating";
import { PaginationType } from "../../api/productApi";
import { Purchase, getPruchases } from "../../api/purchaseApi";
import { createReview } from "../../api/reviewApi";
import { requestUpload, uploadFileToS3 } from "../../api/uploadApi";
import ImgPictureUpload from "../../assets/ImgPictureUpload.svg";
import Button from "../Common/Button";
import Input from "../Common/Input";
import TextArea from "../Common/TextArea";
import { Controller, useForm } from "react-hook-form";
import { PAGE_SIZE } from "../../utils/common.util";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;
const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const InputLabelWrapper = styled.div`
  display: flex;
  width: 110px;
  min-width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const RequiredSpan = styled.span`
  color: #f00;
  font-size: 14px;
  font-weight: 400;
`;
const UploadsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    cursor: pointer;
  }
`;
const ReviewImg = styled.img`
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
`;
export default interface IFile {
  url: string;
  name: string;
}

type OptionType = {
  value: string;
  label: string;
  id: string;
};

interface InputTypes {
  title: string;
  content: string;
}
export default function ReviewCreate({ review }: { review?: any }) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [res, setRes] = useState<null | PaginationType<Purchase>>(null);
  const navigate = useNavigate();

  const [selectedProductUuid, setSelectedProductUuid] = useState("");
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [reviewPoint, setReviewPoint] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  useEffect(() => {
    getPruchases(PAGE_SIZE, 0).then(setRes);
  }, []);

  const onSelectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles([
      ...selectedFiles,
      ...Array.from(event.target.files || []),
    ]);
  };

  const options = useMemo(() => {
    if (!res) return [];

    return res.results.map((t) => ({
      label: t.product.title,
      value: t.product.uuid,
      id: t.uuid,
    }));
  }, [res]);

  // const handleSubmit = async () => {
  const onSubmit = async (inputValues: InputTypes) => {
    if (!selectedProductUuid) {
      alert("구매 상품을 선택해주세요.");
      return;
    }
    if (reviewPoint === 0) {
      alert("리뷰 별점을 1~5 사이로 골라주세요");
      return;
    }

    try {
      const filenames = selectedFiles.map((t) => t.name);
      const uploadRes = await requestUpload(filenames);
      const uploadFileNames = uploadRes.map(({ fields }) => {
        return fields.key;
      }) as string[];

      uploadRes.forEach(async (t, idx) => {
        await uploadFileToS3(selectedFiles[idx], t);
      });
      await createReview(
        selectedProductUuid,
        reviewPoint,
        inputValues.title,
        inputValues.content,
        uploadFileNames
      );
      alert("리뷰를 성공적으로 생성하였습니다.");
      navigate(-1);
    } catch (e) {}
  };

  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>리뷰 쓰기</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <FormContainer>
          <InputRowWrapper>
            <InputLabelWrapper>
              구매 상품 <RequiredSpan>*</RequiredSpan>
            </InputLabelWrapper>
            <Select
              isSearchable={false}
              menuPlacement="auto"
              menuPosition="fixed"
              options={options}
              getOptionValue={(option) => option.id}
              value={selectedOption}
              styles={{
                container: (base: any) => ({
                  ...base,
                  width: "100%",
                  height: "42px",
                }),
                control: (base: any) => ({ ...base, cursor: "pointer" }),
              }}
              onChange={(a: any) => {
                setSelectedProductUuid(a?.value || "");
                setSelectedOption(a);
              }}
              placeholder={"구매하신 상품을 선택해주세요."}
              components={{ IndicatorSeparator: () => null }}
            />
          </InputRowWrapper>
          <InputRowWrapper style={{ alignItems: "flex-start" }}>
            <InputLabelWrapper style={{ marginTop: 10 }}>
              별점 <RequiredSpan>*</RequiredSpan>
            </InputLabelWrapper>
            <Rating
              initialValue={reviewPoint}
              fillColor="#F00"
              emptyColor="#efeff0"
              onClick={(v) => setReviewPoint(v)}
            />
          </InputRowWrapper>
          <InputRowWrapper>
            <InputLabelWrapper>
              제목 <RequiredSpan>*</RequiredSpan>
            </InputLabelWrapper>
            <Controller
              control={control}
              name={"title"}
              rules={{
                required: "필수 항목입니다.",
              }}
              render={({ field }) => (
                <Input
                  placeholder="제목을 입력해주세요."
                  style={{ height: 42 }}
                  errors={errors.title?.message}
                  {...field}
                />
              )}
            />
          </InputRowWrapper>
          <InputRowWrapper style={{ alignItems: "flex-start" }}>
            <InputLabelWrapper style={{ marginTop: 10 }}>
              내용 <RequiredSpan>*</RequiredSpan>
            </InputLabelWrapper>
            <Controller
              control={control}
              name={"content"}
              rules={{
                required: "필수 항목입니다.",
              }}
              render={({ field }) => (
                <TextArea
                  placeholder="놀리, 어떠셨나요? 즐거운 경험을 함께 공유해 주세요!"
                  style={{ height: 268 }}
                  errors={errors.content?.message}
                  {...field}
                />
              )}
            />
          </InputRowWrapper>
          <InputRowWrapper style={{ alignItems: "flex-start" }}>
            <InputLabelWrapper style={{ marginTop: 10 }} />
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFiles}
              id={"file-upload"}
              style={{ display: "none" }}
            />

            <UploadsWrapper>
              {selectedFiles.length < 5 ? (
                <label htmlFor="file-upload">
                  <img src={ImgPictureUpload} />
                </label>
              ) : (
                <label
                  htmlFor="file-disabled-upload"
                  onClick={() =>
                    alert("리뷰 등록시 사진은 최대 5개까지 등록가능합니다.")
                  }
                >
                  <img src={ImgPictureUpload} />
                </label>
              )}
              {selectedFiles.map((file, idx) => {
                return (
                  <ReviewImg
                    src={URL.createObjectURL(file)}
                    key={"review-img-" + idx}
                  />
                );
              })}
            </UploadsWrapper>
          </InputRowWrapper>
          <InputRowWrapper style={{ alignItems: "flex-start" }}>
            <InputLabelWrapper style={{ marginTop: 10 }} />
            <Button
              style={{ background: "#29292A", color: "white" }}
              // onClick={handleSubmit}
            >
              등록
            </Button>
          </InputRowWrapper>
        </FormContainer>
      </form>
    </Container>
  );
}
