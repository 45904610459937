import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { login, me } from "../api/authApi";
import { CommonContainer } from "../components/Common";
import Button from "../components/Common/Button";
import Input from "../components/Common/Input";
import { userState } from "../store/authStore";

const LoginContainer = styled(CommonContainer)`
  padding: 80px 14px 120px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
    padding-top: 40px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 60px;
`;
const FindWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 60px;
`;

const FindText = styled.span`
  color: var(--grey-90, #474748);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.028px;
  cursor: pointer;
`;
const FindSplitter = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 12px;
  background: #e0e0e1;
  margin-right: 8px;
  margin-left: 8px;
`;

interface InputTypes {
  username: string;
  password: string;
}
export default function LoginPage() {
  // const setUser = useSetRecoilState(userState);
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const location = useLocation();
  const next = location.state?.next || "/";
  const state = location.state?.state || {};
  // const loginpopup = useRecoilValue(loginPopupState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  const onSubmit = async (inputValues: InputTypes) => {
    login(inputValues)
      .then((data) => {
        if (data.access)
          window.localStorage.setItem("accessToken", `Bearer ${data.access}`);
        if (data.refresh)
          window.localStorage.setItem("refreshToken", data.refresh);
        me().then((user) => {
          setUser(user);
          window.localStorage.setItem("nextPath", next || "/");
          window.localStorage.setItem("nextState", JSON.stringify(state));
          // setTimeout(() => {
          //   navigate(next || "/", { state });
          // });
        });
      })
      .catch(() => {
        alert("아이디 또는 비밀번호를 확인해주세요.");
      });
  };

  return (
    <LoginContainer>
      <ContentContainer>
        {!isMobile && <Title>로그인</Title>}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Controller
            control={control}
            name={"username"}
            rules={{
              required: "필수 항목입니다.",
            }}
            // defaultValue={"s@bbl.dev"}
            render={({ field }) => (
              <Input
                placeholder="아이디"
                style={{ marginBottom: 12 }}
                errors={errors.username?.message}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={"password"}
            rules={{
              required: "필수 항목입니다.",
            }}
            // defaultValue={"dnjfdydlf"}
            render={({ field }) => (
              <Input
                type={"password"}
                placeholder="비밀번호"
                style={{ marginBottom: 12 }}
                errors={errors.password?.message}
                {...field}
              />
            )}
          />
          <FindWrapper>
            <FindText onClick={() => navigate("/find-id")}>
              아이디 찾기
            </FindText>
            <FindSplitter />
            <FindText onClick={() => navigate("/find-pw")}>
              비밀번호 찾기
            </FindText>
          </FindWrapper>
          <Button
            style={{
              background: "#29292A",
              color: "white",
              border: "none",
              marginBottom: 12,
            }}
          >
            로그인
          </Button>
          <Button
            style={{
              background: "white",
              color: "#29292A",
              border: "1px solid #29292A",
            }}
            onClick={() => navigate("/signup")}
          >
            회원가입
          </Button>
        </form>
      </ContentContainer>
    </LoginContainer>
  );
}
