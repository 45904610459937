import styled from "@emotion/styled";
import { CommonContainer } from "../components/Common";
import { useEffect, useState } from "react";
import { About, getIntro } from "../api/aboutApi";
import { ListTitle, ListTitleWrapper } from "../components/Product/ListHeader";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

// const ListTitleWrapper = styled.div`
//   display: flex;
//   gap: 8px;
//   align-self: stretch;
//   flex-direction: column;
// `;
// const ListTitle = styled.div`
//   color: var(--black, #000);
//   font-size: 24px;
//   font-weight: 600;
//   letter-spacing: -0.048px;
// `;
const ListDescription = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 400;
`;

const ContentImg = styled.img`
  width: 100%;
`;

export default function IntroductionPage() {
  const [about, setAbout] = useState<About | null>(null);
  useEffect(() => {
    getIntro().then(setAbout).catch(console.error);
  }, []);
  return (
    <AskContainer>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            <ListTitle>Nolly 소개</ListTitle>
            <ListDescription>
              매일 새로운 경험을! 함께 놀자 놀리!
            </ListDescription>
          </ListTitleWrapper>
        </HeaderWrapper>
        <ContentImg src={about?.image} />
      </ContentContainer>
    </AskContainer>
  );
}
