import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Purchase, cancelPurchase } from "../../api/purchaseApi";
import IcRight from "../../assets/IcChevronRightSmall.svg";
import Button from "../Common/Button";
import { isMobile } from "react-device-detect";
import { PayMethods } from "../../types/enums";
import { useMemo } from "react";
import { alertExceptionMsg } from "../../utils/exception.util";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-40, #e0e0e1);
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
`;
const HeaderDate = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 600;
  flex: 1;
`;
const HeaderLink = styled.div`
  display: flex;
  align-items: center;
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;
const Content = styled.div`
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Profile = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
`;

const InfoTitle = styled.div`
  width: 100%;
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const RowLeft = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  gap: 14px;
`;
const RowRight = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  gap: 14px;
`;
const RowSplitter = styled.div`
  width: 1px;
  height: 12px;
  background: #e0e0e1;
`;
const RowTitle = styled.div`
  color: #707071;
  font-size: 14px;
  font-weight: 400;
`;
const RowContent = styled.div`
  color: #707071;
  font-size: 14px;
  font-weight: 400;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

interface Props extends React.PropsWithChildren {
  purchase: Purchase;
}

const OrderItem: React.FunctionComponent<Props> = ({ purchase }) => {
  const {
    uuid,
    created,
    product,
    amount_spent,
    purchase_method,
    quantity,
    option_day,
    status,
  } = purchase;
  const isPassed = dayjs(option_day.option_day).isBefore(dayjs());
  const { title, main_thumbnail_image } = product;
  const navigate = useNavigate();

  const statusText = useMemo(() => {
    if (status === "REFUND_REQUESTED") {
      return "주문 취소 요청";
    } else if (status === "REFUNDED_MANUAL") {
      // return "수동 환불 완료";
      return "차감 환불 완료";
    } else if (status === "REFUNDED_AUTO") {
      return "자동 환불 완료";
    } else {
      return "주문취소";
    }
  }, [status]);
  return (
    <Container>
      <Header>
        <HeaderDate>{dayjs(created).format("YYYY-MM-DD")}</HeaderDate>
        <HeaderLink>
          주문내역 상세보기
          <img src={IcRight} />
        </HeaderLink>
      </Header>
      <Content>
        {/* <Profile src={image || ""} /> */}
        <Profile
          style={{
            backgroundImage: `url(${main_thumbnail_image})`,
            backgroundSize: "contain",
          }}
        />
        <InfoWrapper>
          <InfoTitle>{title}</InfoTitle>
          <Row>
            <RowLeft>
              <RowTitle>사용여부</RowTitle>
              <RowContent>{(isPassed && status === "PAID") ? "사용" : "미사용"}</RowContent>
            </RowLeft>
            <RowSplitter />
            <RowRight>
              <RowTitle>사용일</RowTitle>
              <RowContent>
                {dayjs(option_day.option_day).format("YYYY-MM-DD")}
              </RowContent>
            </RowRight>
          </Row>
          <Row>
            <RowLeft>
              <RowTitle>결제방법</RowTitle>
              <RowContent>
                {purchase_method
                  ? PayMethods[
                      purchase?.purchase_method as keyof typeof PayMethods
                    ] || "포인트"
                  : "포인트"}
              </RowContent>
            </RowLeft>
            <RowSplitter />
            <RowRight>
              <RowTitle>결제금액</RowTitle>
              <RowContent>
                {(product.current_price * quantity).toLocaleString()}원
              </RowContent>
            </RowRight>
          </Row>
          <ButtonsWrapper>
            {isPassed && status === "PAID" ? (
              <Button
                style={{
                  width: isMobile ? "100%" : 130,
                  height: 42,
                  fontSize: 15,
                  fontWeight: 500,
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  navigate(`/mypage/review/create`);
                }}
              >
                리뷰 쓰기
              </Button>
            ) : (
              <Button
                style={{
                  width: isMobile ? "100%" : 130,
                  height: 42,
                  fontSize: 15,
                  fontWeight: 500,
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (status === "REFUND_REQUESTED") {
                    alert("이미 주문 취소가 요청되었습니다.");
                    return;
                  }
                  const result = window.confirm(
                    "7일 이전 취소시 별도의 수수료가 발생하지 않으며, 이후 고객센터 1:1 문의를 통해 취소가 가능합니다.\n7일 이전 취소시 이용약관에 의거 별도의 수수료가 발생합니다."
                  );
                  if (result) {
                    cancelPurchase(uuid)
                      .then(() => {
                        alert("주문을 성공적으로 취소하였습니다.");
                        navigate(0);
                      })
                      .catch((err) => {
                        alertExceptionMsg(
                          err.response?.data,
                          "주문 취소를 실패하였습니다."
                        );
                      });
                  }
                }}
              >
                {statusText}
              </Button>
            )}
            <Button
              style={{
                width: isMobile ? "100%" : 130,
                height: 42,
                fontSize: 15,
                fontWeight: 500,
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                navigate(`/products/${product.uuid}/ask`, {
                  state: { title: product.title },
                });
              }}
            >
              1:1 문의
            </Button>
          </ButtonsWrapper>
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default OrderItem;
