import { atom } from "recoil";
import { ProductTopic } from "../types/enums";

export { topicState, productTitleState, imageDetailState };

const topicState = atom<keyof typeof ProductTopic>({
  key: "Topic",
  default: "ACTIVITY",
});

const productTitleState = atom<string>({
  key: "ProductTitle",
  default: "",
});


interface ImageDetailState {
  list: string[],
  idx: number
}

const imageDetailState = atom<null | ImageDetailState>({
  key: "ImageDetail",
  // default: ["https://picsum.photos/200?q", "https://picsum.photos/200?w"],
  default: null,
});
