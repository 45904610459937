import React, { useState } from "react";
import Header from "../Header";
import styled from "@emotion/styled";
import Footer from "../Footer";

const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 1 0;
`;

const Layout: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LayoutContainer>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
