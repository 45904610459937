import styled from "@emotion/styled";
import { ProductTitle } from "./ProductCommon";
import { ProductDetail } from "../../api/productApi";
import { ProductDifficulty } from "../../types/enums";

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    gap: 20px;
  }
`;
const InfoRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const InfoRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 16px;
  }
`;
const InfoSplitter = styled.div`
  width: 100%;
  height: 1px;
  background: #efeff0;
  @media screen and (max-width: 768px) {
    height: 0px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    white-space: normal;
    margin-bottom: 16px;
  }
`;
const ContentTitle = styled.div`
  color: var(--black, #000);
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const ContentDesc = styled.div`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis; /* 생략 부분에 "..." 추가 */
`;

export default function ProductInfo({
  productDetail,
}: {
  productDetail?: ProductDetail | null;
}) {
  if (!productDetail) return <div />;
  return (
    <InfoWrapper>
      <ProductTitle>상품 정보</ProductTitle>
      <InfoRowsWrapper>
        <InfoRowWrapper>
          <ContentWrapper>
            <ContentTitle>모이는 장소</ContentTitle>
            <ContentDesc>{productDetail.start_place}</ContentDesc>
          </ContentWrapper>
          <ContentWrapper>
            <ContentTitle>진행시간</ContentTitle>
            <ContentDesc>{productDetail.duration}</ContentDesc>
          </ContentWrapper>
        </InfoRowWrapper>
        <InfoSplitter />
        <InfoRowWrapper>
          <ContentWrapper>
            <ContentTitle>참여 가능 연령</ContentTitle>
            <ContentDesc>만 {productDetail.min_age}살 이상</ContentDesc>
          </ContentWrapper>
          <ContentWrapper>
            <ContentTitle>참여 인원</ContentTitle>
            <ContentDesc>
              최대 {productDetail.max_capacity}명, 최소{" "}
              {productDetail.min_capacity}명 이상
            </ContentDesc>
          </ContentWrapper>
        </InfoRowWrapper>
        <InfoSplitter />
        <InfoRowWrapper>
          <ContentWrapper>
            <ContentTitle>포함 사항</ContentTitle>
            <ContentDesc>{productDetail.inclusive}</ContentDesc>
          </ContentWrapper>
          <ContentWrapper>
            <ContentTitle>불포함 사항</ContentTitle>
            <ContentDesc>{productDetail.exclusive}</ContentDesc>
          </ContentWrapper>
        </InfoRowWrapper>
        <InfoSplitter />
        <InfoRowWrapper>
          <ContentWrapper>
            <ContentTitle>난이도</ContentTitle>
            <ContentDesc>
              {ProductDifficulty[productDetail.difficulty]}
            </ContentDesc>
          </ContentWrapper>
        </InfoRowWrapper>
      </InfoRowsWrapper>
    </InfoWrapper>
  );
}
