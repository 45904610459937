import styled from "@emotion/styled";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { updatePassword } from "../../api/authApi";
import { CommonContainer } from "../../components/Common";
import Button from "../../components/Common/Button";
import Input from "../../components/Common/Input";
import { userState } from "../../store/authStore";

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Desc = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
`;
const Mark = styled.span`
  color: #f00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024px;
`;
const InputsWrapper = styled.div`
  margin-bottom: 60px;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Label = styled.div`
  min-width: 110px;
  width: 110px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

interface InputTypes {
  password_a: string;
  password_b: string;
}

export default function ProfilePassword() {
  const [user, setUser] = useRecoilState(userState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<InputTypes>({
    mode: "onBlur",
  });

  useEffect(() => {
    console.log("erros: ", errors);
  }, [errors]);

  const onSubmit = async (inputValues: InputTypes) => {
    if (user) {
      updatePassword(user?.uuid, inputValues.password_a, inputValues.password_b)
        .then(() => {
          alert("성공적으로 비밀번호가 변경되었습니다.");
          reset();
        })
        .catch(() => {
          alert("비밀번호 변경에 실패하였습니다.");
        });
    }
  };

  return (
    <ContentContainer>
      <Desc>
        <Mark>*</Mark> 필수 입력사항
      </Desc>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <InputsWrapper>
          <InputWrapper>
            <Label>
              비밀번호 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"password_a"}
              rules={{
                required: "필수 항목입니다.",
              }}
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder="비밀번호"
                  style={{ height: 42 }}
                  errors={errors.password_a?.message}
                  {...field}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>
              비밀번호 확인 <Mark>*</Mark>
            </Label>
            <Controller
              control={control}
              name={"password_b"}
              rules={{
                required: "필수 항목입니다.",
              }}
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder="비밀번호 확인"
                  style={{ height: 42 }}
                  errors={errors.password_b?.message}
                  {...field}
                />
              )}
            />
          </InputWrapper>
        </InputsWrapper>
        <Button
          style={{
            background: "#29292A",
            color: "white",
            border: "none",
            marginBottom: 12,
          }}
        >
          수정
        </Button>
      </form>
    </ContentContainer>
  );
}
