import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PaginationType, Product, productList } from "../api/productApi";
import { CommonContainer } from "../components/Common";
import ContentItem from "../components/Main/ContentItem";
import Pagination from "../components/Common/Pagenation";
import ListHeader from "../components/Product/ListHeader";
import {
  ProductCategory,
  ProductSort,
  ProductTopic,
  isProductCategory,
  isProductTopic,
} from "../types/enums";
import { PAGE_SIZE, PRODUCTS_PAGE_SIZE } from "../utils/common.util";

const ListContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;
const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    gap: 16px;
  }
`;
export default function ActivitiesPage() {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<keyof typeof ProductSort>("sold_count");

  const { category, topic: _topic } = useParams();
  const upperCased = category?.toUpperCase() || "";
  const topic = _topic?.toUpperCase() || "";
  const [res, setRes] = useState<null | PaginationType<Product>>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isProductCategory(upperCased) && isProductTopic(topic)) {
      productList(topic, undefined, upperCased, PRODUCTS_PAGE_SIZE, (page - 1) * PRODUCTS_PAGE_SIZE, sortBy).then(setRes);
    }
  }, [topic, upperCased, page, sortBy]);

  if (!isProductTopic(topic)) navigate("/");

  return (
    <ListContainer>
      <ContentContainer>
        <ListHeader
          title={`${(isProductTopic(topic) && ProductTopic[topic]) || ""} - ${
            (isProductCategory(upperCased) && ProductCategory[upperCased]) || ""
          }`}
          subTitle={res?.count + ""}
          sortBy={sortBy}
          onChangeSortBy={setSortBy}
        />
        <ItemsWrapper>
          {res?.results.map((content) => (
            <ContentItem key={content.uuid} content={content} />
          ))}
        </ItemsWrapper>
        <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} pageSize={PRODUCTS_PAGE_SIZE} />
      </ContentContainer>
    </ListContainer>
  );
}
