import styled from "@emotion/styled";
import { ProductDetail } from "../../api/productApi";
import {
  ProductOptionDay,
  ProductOptionSeat,
} from "../../api/productOptionApi";

const InfoDetailWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const InfoImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 4px;
`;
const InfoDetailRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;
const InfoDetailRightTop = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
`;
const InfoDetailRightBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
const OptionInfoTitle = styled.div`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 600;
`;
const OptionInfoDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    gap: 8px;
  }
`;
const OptionInfoDetailEach = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 400;
`;
const OptionInfoDetailSplitter = styled.div`
  width: 1px;
  height: 12px;
  background: #e0e0e1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default function PurchaseProduct({
  product,
  selectedOptionDay,
  selectedOptionSeat,
  quantity,
}: {
  product: ProductDetail | null;
  selectedOptionDay: ProductOptionDay | null;
  selectedOptionSeat: ProductOptionSeat | null;
  quantity: number;
}) {
  return (
    <InfoDetailWrapper>
      <InfoImage src={product?.thumbnail_image_urls[0]} />
      <InfoDetailRightWrapper>
        <InfoDetailRightTop>
          {product?.title ||
            "[한강/이색데이트] 한강 카약 체험 & 썬셋 카약 프로그램"}
        </InfoDetailRightTop>
        <InfoDetailRightBottomWrapper>
          <OptionInfoTitle>옵션정보</OptionInfoTitle>
          <OptionInfoDetailWrapper>
            <OptionInfoDetailEach>
              {selectedOptionDay?.option_day || "2023-08-20"}
            </OptionInfoDetailEach>
            <OptionInfoDetailSplitter />
            <OptionInfoDetailEach>
              {selectedOptionSeat?.title || "보드렌탈 + 프립왕복버스"}
            </OptionInfoDetailEach>
            <OptionInfoDetailSplitter />
            <OptionInfoDetailEach>{quantity}개</OptionInfoDetailEach>
          </OptionInfoDetailWrapper>
        </InfoDetailRightBottomWrapper>
      </InfoDetailRightWrapper>
    </InfoDetailWrapper>
  );
}
