import React, { useEffect, useState } from "react";

declare global {
  interface Window {
    kakao: any;
  }
}

export function KakaoMap({
  lat = 33.450701,
  lng = 126.570667,
  title,
  url,
}: {
  lat?: number;
  lng?: number;
  title?: string;
  url?: string;
}) {
  useEffect(() => {
    if (!window.kakao) return;

    const position = new window.kakao.maps.LatLng(lat, lng);
    let container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
    let options = {
      draggable: false,
      center: position,
      level: 3, //지도의 레벨(확대, 축소 정도)
    };

    let map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
    map.setDraggable(false);

    // 마커를 생성합니다
    if (title) {
      var iwContent = `<div style="padding:5px;">${title}</div>`; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다

      // 인포윈도우를 생성합니다
      var infowindow = new window.kakao.maps.InfoWindow({
        position,
        content: iwContent,
      });

      const marker = new window.kakao.maps.Marker({ position, content: title });
      marker.setMap(map);
      infowindow.open(map, marker);
    } else {
      const marker = new window.kakao.maps.Marker({
        position,
        clickable: true,
      });
      marker.setMap(map);
    }
    map.relayout();
  }, [lat, lng, title, url, window.kakao]);

  if (!lat || !lng) return null;

  return (
    <div
      id="map"
      style={{ width: "100%", height: "300px", cursor: 'pointer' }}
      onClick={() =>
        window.open(url ? url : `https://map.kakao.com/link/map/${lat},${lng}`)
      }
    />
  );
}
