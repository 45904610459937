import styled from "@emotion/styled";
import ChevronRightIconSvg from "../../assets/ChevronRightIcon.svg";
import { StartIcon } from "../Icon/StarIcon";
import { useEffect, useState } from "react";
import { Seller, sellerDetail } from "../../api/sellerApi";
import { useNavigate } from "react-router-dom";

const SellerWrapper = styled.a`
  display: flex;
  padding-bottom: 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  text-decoration: none;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;
const RightArrowIcon = styled.img``;
const Profile = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const SellerInfo = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
`;
const SellerTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.032px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SellerDesc = styled.div`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
`;

export default function ProductSeller({
  productSellerUuid,
}: {
  productSellerUuid: string;
}) {
  const navigate = useNavigate();
  const [seller, setSeller] = useState<Seller | null>(null);

  useEffect(() => {
    if (productSellerUuid) {
      sellerDetail(productSellerUuid).then(setSeller);
    }
  }, [productSellerUuid]);

  return (
    // <SellerWrapper onClick={() => navigate(`/sellers/` + productSellerUuid)}>
    <SellerWrapper href={`/sellers/` + productSellerUuid}>
      <LeftWrapper>
        <Profile src={seller?.profile_image} />
        <SellerInfo>
          <SellerTitle>{seller?.user.username}</SellerTitle>
          <StartIcon />
          <SellerDesc>
            {seller?.review_avg.toFixed(1)} (리뷰 {seller?.review_count}개)
          </SellerDesc>
        </SellerInfo>
      </LeftWrapper>
      <RightArrowIcon src={ChevronRightIconSvg} />
    </SellerWrapper>
  );
}
