import { PAGE_SIZE } from "../utils/common.util";
import nollyAxios from "./nollyAxios";
import { PaginationType, Product } from "./productApi";

export type Topic = "ACTIVITY" | "WORKACTION" | "CLASS";

export type ProductReview = {
  uuid: string;
  review_point: number;
  title: string;
  content: string;
  image_urls: string[];
  like_count: number;
  like_uuid: null | string;
  user: {
    uuid: string;
    username: string;
  };
  product: Product;
  created: string;
  modified: string;
};

export type ProductSort =
  | "sold_count"
  | "review_count"
  | "current_price"
  | "PRICE_DESC";

export type ProductReviewOrdering = "-created" | "-like_count";

export function createReview(
  productUuid: string,
  review_point: number,
  title: string,
  content: string,
  filenames?: string[]
): Promise<ProductReview> {
  const payload: any = {
    review_point,
    title,
    content,
  };
  if (filenames) {
    payload.filenames = filenames;
  }
  return nollyAxios
    .post(`/api/products/${productUuid}/product_reviews`, payload)
    .then(({ data }) => data);
}

export function getReviewList(
  productUuid: string,
  limit?: number,
  offset?: number,
  ordering?: ProductReviewOrdering
): Promise<PaginationType<ProductReview>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  if (ordering) query += `ordering=${ordering}`;
  return nollyAxios
    .get(
      `/api/products/${productUuid}/product_reviews${query}`
    )
    .then(({ data }) => data);
}

export function deleteReview(
  productReviewUuid: string
): Promise<void> {
  return nollyAxios
    .delete(`/api/product_reviews/${productReviewUuid}`)
    .then(({ data }) => data);
}

export type ProductReviewLike = {
  uuid: string;
  created: string;
  modified: string;
};

export function likeReview(
  productReviewUuid: string
): Promise<ProductReviewLike> {
  return nollyAxios
    .post(`/api/product_reviews/${productReviewUuid}/product_review_likes`)
    .then(({ data }) => data);
}

export function removeLikeReview(productReviewLikeUuid: string): Promise<void> {
  return nollyAxios
    .delete(`/api/product_review_likes/${productReviewLikeUuid}`)
    .then(({ data }) => data);
}

export function getMyReviewList(
  limit: number = PAGE_SIZE,
  offset: number = 0,
): Promise<PaginationType<ProductReview>> {
  let query = "?";
  query += `offset=${offset}&`;
  query += `limit=${limit}&`;
  return nollyAxios
    .get(`/api/product_reviews${query}`)
    .then(({ data }) => data);
}
