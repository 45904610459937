import styled from "@emotion/styled";
import { useCallback, useState } from "react";
import SearchIcon from "../../assets/SearchIcon.svg";

const Container = styled.div`
  display: flex;
  padding: 8px 24px 8px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-30, #efeff0);
`;

const Icon = styled.img``;
const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
`;

interface Props extends React.PropsWithChildren {
  placeholder?: string;
  style?: React.CSSProperties;
  onSearch?: (v: string) => void;
}

const SearchInput: React.FunctionComponent<Props> = ({
  style,
  // placeholder = "‘부산', ‘강릉'등 단어로 검색해보세요.",
  placeholder = "오늘 뭐 할까?",
  onSearch,
}) => {
  const [text, setText] = useState("");
  const activeEnter = useCallback(
    (e: any) => {
      if (e.key === "Enter" && onSearch) {
        onSearch(text);
        // setTimeout(() => {
        //   setText("");
        // }, 0);
      }
    },
    [text, onSearch]
  );
  // export default function SearchInput({ style }): { style: React.CSSProperties } {
  return (
    <Container style={style}>
      <Icon src={SearchIcon} />
      <Input
        onKeyDown={activeEnter}
        placeholder={placeholder}
        value={text}
        onChange={(r) => setText(r.target.value)}
      />
    </Container>
  );
};

export default SearchInput;
