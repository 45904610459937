export function maskString(str: string) {
  if (str.length <= 3) return str;

  const firstThreeChars = str.slice(0, 3);
  const maskedChars = "*".repeat(str.length - 3);

  return firstThreeChars + maskedChars;
}

// export const PAGE_SIZE = 30;
// export const OPTION_DAYS_PAGE_SIZE = 35;
export const PAGE_SIZE = 5;
export const PRODUCTS_PAGE_SIZE = 8;
export const OPTION_DAYS_PAGE_SIZE = 35;
export const REVIEW_PAGE_SIZE = 5;