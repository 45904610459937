import { ProductTopic } from "../types/enums";
import nollyAxios from "./nollyAxios";
import { PaginationType, Product } from "./productApi";

export interface Promotion {
  uuid: string;
  is_active: boolean;
  title: string;
  slug: string;
  image: string;
  main_products: Product[];
  created: string;
  modified: string;
}

export function getPromotionList(
  topic?: keyof typeof ProductTopic
): Promise<PaginationType<Promotion>> {
  return nollyAxios
    .get(`/api/promotions?topic=${topic}&offset=${0}&limit=${30}`)
    .then(({ data }) => data);
}

export interface PromotionDetail {
  uuid: string;
  is_active: boolean;
  title: string;
  slug: string;
  image: string;
  products: Product[];
  created: string;
  modified: string;
}
export function getPromotionDetail(
  promotionUuid: string
): Promise<PromotionDetail> {
  return nollyAxios
    .get(`/api/promotions/` + promotionUuid)
    .then(({ data }) => data);
}

export function getPromotionDetailBySlug(
  slug: string
): Promise<PromotionDetail> {
  return nollyAxios.get(`/api/promotions/` + slug).then(({ data }) => data);
}
