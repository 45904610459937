import styled from "@emotion/styled";
import Button from "../Common/Button";
import Input from "../Common/Input";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../store/authStore";
import { isMobile } from "react-device-detect";
import { useMemo, useState } from "react";
import { BankName } from "../../types/enums";
import Select from "react-select";
import NumberInput from "../Common/NumberInput";
import { requestRefund } from "../../api/pointPurchaseApi";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const RefundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
`;
const RefundTitleWrapper = styled.div`
  display: flex;
  padding-bottom: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
`;
const RefundDesc = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;
const RefundableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
const RefundableLabel = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;
const RefundableValue = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  line-height: 150%; /* 33px */
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
`;
const InputRow = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;
const InputLabel = styled.div`
  display: flex;
  width: 110px;
  min-width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Info = styled.div`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 40px;
`;

export default function PointRefund() {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);

  const [bankName, setBankeName] = useState<undefined | keyof typeof BankName>(
    undefined
  );
  const [accountNum, setAccountNum] = useState<undefined | number>(undefined);
  const [ownerName, setOwnerName] = useState("");

  const handleSubmit = async () => {
    if (!bankName || !accountNum || !ownerName) {
      alert("은행명, 계좌번호, 예금주 명을 입력하고 요청해주세요.");
      return;
    }
    try {
      await requestRefund(
        user?.point?.amount || 0,
        BankName[bankName],
        accountNum + "",
        ownerName
      );
      alert("환불 요청을 성공하였습니다.");
      navigate(-1);
    } catch (e) {
      alert("환불 요청에 실패하였습니다.");
    }
  };

  const options = useMemo(() => {
    return Object.entries(BankName).map(([k, v], idx) => ({
      label: v,
      value: k,
    }));
  }, []);
  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>포인트</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      <RefundWrapper>
        <RefundTitleWrapper>환불</RefundTitleWrapper>
        <RefundDesc>
          회원님께서 직접 직접 결제하여 충전한 포인트만 환불할 수 있습니다.
          (기업 자동충전 포인트는 환불이 불가합니다.)<br/>잔액 전체 환불만 가능하며,
          환불한 금액은 5영업일(토·일·공휴일 제외) 이내에 환불 계좌로
          입금됩니다.
        </RefundDesc>
        <RefundableWrapper>
          <RefundableLabel>환불 가능 포인트</RefundableLabel>
          <RefundableValue>
            {user?.point?.amount.toLocaleString()} 포인트
          </RefundableValue>
        </RefundableWrapper>
        <InputsWrapper>
          <InputRow>
            <InputLabel>은행명</InputLabel>
            {/* <Input
              placeholder="은행명"
              style={{ height: 42, width: isMobile ? "100%" : 282 }}
              value={bankName}
              onChange={(e) => setBankeName(e.target.value)}
            /> */}
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              isSearchable={false}
              options={options}
              onChange={(a) => setBankeName(a?.value as keyof typeof BankName)}
              styles={{
                container: (base) => ({
                  ...base,
                  width: isMobile ? "100%" : 282,
                  height: 42,
                }),
                control: (base) => ({
                  ...base,
                  cursor: "pointer",
                  width: "100%",
                  height: 42,
                }),
              }}
              placeholder={"은행을 선택해주세요."}
              components={{ IndicatorSeparator: () => null }}
            />
          </InputRow>
          <InputRow>
            <InputLabel>계좌번호</InputLabel>
            <NumberInput
              value={accountNum}
              onSet={setAccountNum}
              onChange={(e) => setAccountNum(Number(e.currentTarget.value))}
              placeholder="계좌번호"
              style={{ height: 42, width: isMobile ? "100%" : 282 }}
            />
            {/* <Input
              placeholder="계좌번호"
              style={{ height: 42, width: isMobile ? "100%" : 282 }}
              value={accountNum}
              onChange={(e) => setAccountNum(e.target.value)}
            /> */}
          </InputRow>
          <InputRow>
            <InputLabel>예금주 명</InputLabel>
            <Input
              placeholder="예금주명"
              style={{ height: 42, width: isMobile ? "100%" : 282 }}
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </InputRow>
          {/* <InputRow>
            <InputLabel />
            <Button
              style={{
                height: 42,
                width: isMobile ? "100%" : 282,
                color: "#474748",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              계좌 인증
            </Button>
          </InputRow> */}
        </InputsWrapper>
        <Info>
          * 반드시 본인 명의의 계좌로만 환불이 가능합니다.
          <br />
          * 한 번 환불을 신청하면 취소가 불가합니다.
          <br />* 이용약관에 따라 충전한 포인트의 잔액이 80% 이하일 때만 환불
          신청이 가능하므로 전체 충전 금액의 20% 이상을 사용하지 않으면 환불이
          불가합니다.
        </Info>
        <ButtonsWrapper>
          {/* <Button
            style={{
              width: isMobile ? "100%" : 65,
              height: 42,
              fontSize: 15,
              fontWeight: 500,
              background: "#29292A",
              color: "white",
            }}
            onClick={() => navigate(-1)}
          >
            취소
          </Button> */}
          <Button
            style={{
              width: isMobile ? "100%" : 65,
              height: 42,
              fontSize: 15,
              fontWeight: 500,
            }}
            onClick={handleSubmit}
          >
            확인
          </Button>
        </ButtonsWrapper>
      </RefundWrapper>
    </Container>
  );
}
