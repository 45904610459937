import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ProductDetail, productDetail } from "../api/productApi";
import MobileProductDetailInfo from "../components/MobileProductDetailInfo";
import ProductDetailInfo from "../components/ProductDetailInfo";
import { useSetRecoilState } from "recoil";
import { productTitleState } from "../store/productStore";

export default function ProductPage() {
  const [res, setRes] = useState<null | ProductDetail>(null);
  const setProductTitle = useSetRecoilState(productTitleState);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      productDetail(id).then(setRes);
    }
  }, [id]);

  useEffect(() => {
    if (res) {
      setProductTitle(res.title);
    }
  }, [res]);

  if (isMobile) {
    return <MobileProductDetailInfo res={res} />;
  }
  return <ProductDetailInfo res={res} />;
}
