import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Career, getCareerList } from "../api/careerApi";
import { PaginationType } from "../api/productApi";
import IcMail from "../assets/IcMail.svg";
import IcPin from "../assets/IcPin.svg";
import { CommonContainer } from "../components/Common";
import { KakaoMap } from "../components/KakaoMap";
import Pagination from "../components/Common/Pagenation";
import CareerItem from "../components/Career/CareerItem";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE } from "../utils/common.util";

const AskContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 24px 0px 24px 0px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0px 14px;
  }
`;

const ListTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
`;
const ListDescription = styled.div`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0px 14px;
  }
`;
const SubTitle = styled.div`
  color: var(--black, #000);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const NoticesWrapper = styled.div`
  width: 100%;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0px 14px;
  }
`;
const InfoHeader = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const InfoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export default function CareersPage() {
  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<Career>>(null);

  useEffect(() => {
    getCareerList(PAGE_SIZE, (page - 1) * PAGE_SIZE).then(setRes);
  }, [page]);

  return (
    <AskContainer>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            {!isMobile && <ListTitle>인재채용</ListTitle>}
            <ListDescription>
              놀리와 ‘인생의 새로운 즐거움’을 함께할 분들을 기다립니다.
              <br />
              매일이 새롭고 즐거운 삶, 가치 있는 일을 꿈꿉니다.
            </ListDescription>
          </ListTitleWrapper>
        </HeaderWrapper>
        <SubTitleWrapper>
          <SubTitle>채용 중인 공고</SubTitle>
        </SubTitleWrapper>
        <NoticesWrapper>
          {res?.results.map(({ title, created, uuid, is_open }) => (
            <CareerItem
              uuid={uuid}
              key={uuid}
              title={title}
              category={is_open ? "채용중" : "채용마감"}
              createdAt={created}
            />
          ))}
        </NoticesWrapper>
        <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} />
        <SubTitleWrapper>
          <SubTitle>채용 문의 및 오시는 길</SubTitle>
        </SubTitleWrapper>
        <InfoWrapper>
          <InfoHeader>
            <InfoContent>
              <img src={IcMail} />
              recruit@nolly.life
            </InfoContent>
            <InfoContent>
              <img src={IcPin} />
              서울특별시 마포구 양화로 120, 2F 주식회사 놀리
            </InfoContent>
          </InfoHeader>
          <KakaoMap lat={37.5538964} lng={126.9203366} />
        </InfoWrapper>
      </ContentContainer>
    </AskContainer>
  );
}
