import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ProductDetail } from "../api/productApi";
import { ProductOptionDay, ProductOptionSeat } from "../api/productOptionApi";
import { purchase } from "../api/purchaseApi";
import { CommonContainer } from "../components/Common";
import Button from "../components/Common/Button";
import Radio from "../components/Common/Radio";
import PurchasePoint from "../components/Purchase/PurchasePoint";
import PurchasePrice from "../components/Purchase/PurchasePrice";
import PurchaseProduct from "../components/Purchase/PurchaseProduct";
import { userState } from "../store/authStore";
import { PaymentMethodEnum } from "../types/enums";
import { RequestPayParams, RequestPayResponse } from "../utils/iamport.utils";
import { alertExceptionMsg } from "../utils/exception.util";
import { AxiosError } from "axios";
import IcKakaopay from "../assets/payment_icon_yellow_small.png";

const PaymentContainer = styled(CommonContainer)`
  padding: 40px 0px 60px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
  }
`;
const ContentContainer = styled(CommonContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const SubTitleWrapper = styled.div`
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-40, #e0e0e1);
  color: var(--grey-100, #29292a);
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
`;
const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
`;
const PaymentMethodEachWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;
const PaymentMethodTitle = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
`;
const AgreementWrapper = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 500;
`;

export default function PaymentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const product: ProductDetail | null = location.state?.product || null;
  const quantity: number = Number(location.state?.quantity) || 0;
  const selectedOptionDay: ProductOptionDay | null =
    location.state?.selectedOptionDay || null;
  const selectedOptionSeat: ProductOptionSeat | null =
    location.state?.selectedOptionSeat || null;

  const currentPrice = product?.current_price || 0;

  const [paymentMethod, setPaymentMethod] =
    useState<keyof typeof PaymentMethodEnum>("welcome");
  const [paymentway, setPaymentWay] = useState("card");
  const [pointSent, setPointSent] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!product || !selectedOptionDay || !selectedOptionSeat) navigate(-1);
  }, []);

  const totalPrice = useMemo(() => {
    return (product?.current_price || 0) * quantity - (pointSent || 0);
  }, [currentPrice, pointSent, quantity]);

  const onClickPayment = () => {
    if (!window.IMP) return;
    if (!product || !selectedOptionDay || !selectedOptionSeat) return;
    if (totalPrice === 0) {
      purchase(
        product?.uuid,
        selectedOptionDay?.uuid,
        selectedOptionSeat?.uuid,
        pointSent || 0,
        quantity
      )
        .then(() => {
          alert("결제 성공하였습니다.");
          navigate("/mypage/order");
        })
        .catch((err) => {
          alertExceptionMsg(err.response?.data, "결제에 실패하였습니다.");
        });
      return;
    }

    const { IMP } = window;
    IMP.init("imp76742540"); // 가맹점 식별코드

    let _paymentMethod: string = paymentMethod;
    const isProd = window.location.host === "www.nolly.life";
    if (isProd) {
      if (paymentMethod === "html5_inicis") {
        _paymentMethod = "html5_inicis.MOI2072621";
      } else if (paymentMethod === "kakaopay") {
        _paymentMethod = "kakaopay.CAUHW53CQ6";
      } else if (paymentMethod === "welcome") {
        _paymentMethod = "welcome.IMP2000003";
      }
    } else {
      if (paymentMethod === "html5_inicis") {
        _paymentMethod = "html5_inicis.INIpayTest";
      } else if (paymentMethod === "kakaopay") {
        _paymentMethod = "kakaopay.TC0ONETIME";
      } else if (paymentMethod === "welcome") {
        _paymentMethod = "welcome.welcometst";
      }
    }
    const data: RequestPayParams = {
      pg: _paymentMethod, // PG사 : https://portone.gitbook.io/docs/sdk/javascript-sdk/payrq#undefined-1 참고
      pay_method: paymentway, // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      // amount: product.current_price, // 결제금액
      amount: totalPrice, // 결제금액
      name: product?.title,
      buyer_name: user?.username,
      buyer_tel: user?.profile?.mobile_no || "010-1234-1234",
      buyer_email: user?.email,
      m_redirect_url: `${window.location.origin}/payment-result`,
    };
    localStorage.setItem(
      "cur_purchase_info",
      JSON.stringify({
        productUuid: product.uuid,
        optionDayUuid: selectedOptionDay?.uuid,
        optionSeatUuid: selectedOptionSeat?.uuid,
        pointSent: pointSent || 0,
        quantity,
      })
    );

    IMP.request_pay(data, callback);
  };

  function callback(response: RequestPayResponse) {
    const { imp_uid, merchant_uid } = response;
    if (
      !product ||
      !selectedOptionDay ||
      !selectedOptionSeat ||
      !quantity ||
      Number.isNaN(quantity)
    )
      return;

    // if (success) {
    if (imp_uid) {
      purchase(
        product?.uuid,
        selectedOptionDay?.uuid,
        selectedOptionSeat?.uuid,
        pointSent || 0,
        quantity,
        imp_uid
      )
        .then(() => {
          alert("결제 성공하였습니다.");
          navigate("/mypage/order");
        })
        .catch((err) => {
          alertExceptionMsg(err.response?.data, "결제에 실패하였습니다.");
        });
    } else {
      alert(`결제 실패하였습니다.`);
    }
  }

  return (
    <PaymentContainer>
      <ContentContainer>
        <HeaderWrapper>
          <ListTitleWrapper>
            <ListTitle>결제하기</ListTitle>
          </ListTitleWrapper>
        </HeaderWrapper>
        <ProductInfoWrapper>
          <SubTitleWrapper>상품정보</SubTitleWrapper>
          <PurchaseProduct
            product={product}
            quantity={quantity}
            selectedOptionDay={selectedOptionDay}
            selectedOptionSeat={selectedOptionSeat}
          />
        </ProductInfoWrapper>
        <ProductInfoWrapper>
          <SubTitleWrapper>결제수단</SubTitleWrapper>
          <PaymentMethodWrapper>
            <PaymentMethodEachWrapper
              onClick={() => {
                setPaymentMethod("welcome");
                setPaymentWay("card");
              }}
            >
              <Radio
                checked={paymentMethod === "welcome" && paymentway === "card"}
              />
              <PaymentMethodTitle>신용/체크카드</PaymentMethodTitle>
            </PaymentMethodEachWrapper>
            <PaymentMethodEachWrapper
              onClick={() => {
                setPaymentMethod("welcome");
                setPaymentWay("trans");
              }}
            >
              <Radio
                checked={paymentMethod === "welcome" && paymentway === "trans"}
              />
              <PaymentMethodTitle>실시간 계좌이체</PaymentMethodTitle>
            </PaymentMethodEachWrapper>
            <PaymentMethodEachWrapper
              onClick={() => setPaymentMethod("kakaopay")}
            >
              <Radio checked={paymentMethod === "kakaopay"} />
              <PaymentMethodTitle>
                <img src={IcKakaopay} style={{ width: 58 }} />
              </PaymentMethodTitle>
            </PaymentMethodEachWrapper>
          </PaymentMethodWrapper>
        </ProductInfoWrapper>
        <ProductInfoWrapper>
          <SubTitleWrapper>포인트</SubTitleWrapper>
          <PurchasePoint
            point={pointSent}
            setPointSent={setPointSent}
            price={(product?.current_price || 0) * quantity}
          />
        </ProductInfoWrapper>
        <ProductInfoWrapper>
          <SubTitleWrapper>결제금액</SubTitleWrapper>
          <PurchasePrice
            price={(product?.origin_price || 0) * quantity}
            discount={(product?.sale_amount || 0) * quantity}
            pointSent={pointSent || 0}
            totalPrice={
              (product?.current_price || 0) * quantity - (pointSent || 0)
            }
            paymentMethod={paymentMethod}
            paymentWay={paymentway}
          />
        </ProductInfoWrapper>
        <AgreementWrapper>
          위 내용을 모두 확인하였으며, 결제에 동의합니다.
        </AgreementWrapper>
        <Button
          style={{
            background: "#29292A",
            borderRadius: 4,
            fontSize: 18,
            fontWeight: "600",
            color: "white",
            width: isMobile ? "100%" : "400px",
            height: "60px",
          }}
          onClick={onClickPayment}
        >
          결제하기
        </Button>
        {/* <button onClick={onClickPayment}>결제하기</button> */}
      </ContentContainer>
    </PaymentContainer>
  );
}
