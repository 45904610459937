export const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue !== null) {
      try {
        setSelf(JSON.parse(savedValue));
      } catch {
        setSelf(savedValue);
      }
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, typeof newValue === 'string' ? newValue : JSON.stringify(newValue));
    });
  };

export const sessionStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = sessionStorage.getItem(key);

    if (savedValue !== null) {
      try {
        setSelf(JSON.parse(savedValue));
      } catch {
        setSelf(savedValue);
      }
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? sessionStorage.removeItem(key)
        : sessionStorage.setItem(key, typeof newValue === 'string' ? newValue : JSON.stringify(newValue));
    });
  };
