import React from "react";
import ReactLoading from "react-loading";
import { useRecoilValue } from "recoil";
import { loadingState } from "../../store/commonStore";

const Loading = () => {
  const isLoading =  useRecoilValue(loadingState);

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: "fixed",
        // backgroundColor: "rgba(0,0,0,0.5)",
        width: "100%",
        left: 0,
        top: 0,
        height: "100%",
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading type="balls" color={"#FF0000"} width={100} />
    </div>
  );
};

export default Loading;
