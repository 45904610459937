import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginationType } from "../../api/productApi";
import { ProductReview, getMyReviewList } from "../../api/reviewApi";
import Button from "../Common/Button";
import Pagination from "../Common/Pagenation";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE } from "../../utils/common.util";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const HeaderDesc = styled.div`
  color: var(--grey-70, #939394);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.024px;
`;
const RowContainer = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 20px 14px;
    display: block;
    border-bottom: 1px solid var(--grey-30, #efeff0);
  }
`;
const Left = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 8px;
    border-bottom: none;
  }
`;
const Right = styled.div`
  display: flex;
  width: 160px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 0;
    border-bottom: none;
  }
`;
const LeftHeader = styled(Left)`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 600;
`;
const RightHeader = styled(Right)`
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const BottomWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export default function ReviewHistory() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [res, setRes] = useState<null | PaginationType<ProductReview>>(null);

  useEffect(() => {
    getMyReviewList(PAGE_SIZE, (page - 1) * PAGE_SIZE).then(setRes);
  }, [page]);

  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>내 리뷰 관리</HeaderTitle>
            <HeaderDesc>구매한 상품만 리뷰 작성이 가능합니다</HeaderDesc>
          </HeaderTitleWrapper>
        </Header>
      )}
      {isMobile && (
        <BottomWrapper>
          <Button
            style={{
              width: "100%",
              height: 42,
              fontSize: 15,
              fontWeight: 500,
              background: "#29292A",
              color: "white",
            }}
            onClick={() => navigate("/mypage/review/create")}
          >
            리뷰 쓰기
          </Button>
        </BottomWrapper>
      )}
      <RowContainer>
        {!isMobile && (
          <Row>
            <LeftHeader>제목</LeftHeader>
            <RightHeader>작성일</RightHeader>
          </Row>
        )}
        {res?.results.map((t: ProductReview) => (
          <Row
            key={`${t.uuid}`}
            onClick={() =>
              navigate("/mypage/review/detail", { state: { review: t } })
            }
          >
            <Left>{t.title}</Left>
            <Right>{dayjs(t.created).format("YYYY-MM-DD")}</Right>
          </Row>
        ))}
        {isMobile && (
          <BottomWrapper style={{ justifyContent: "center", marginTop: 15 }}>
            <Pagination
              curIndex={page}
              paginate={setPage}
              maxCount={res?.count}
              pageSize={PAGE_SIZE}
            />
          </BottomWrapper>
        )}
        {!isMobile && (
          <BottomWrapper>
            <Pagination
              curIndex={page}
              paginate={setPage}
              maxCount={res?.count}
            />
            <Button
              style={{
                width: 95,
                height: 42,
                fontSize: 15,
                fontWeight: 500,
                background: "#29292A",
                color: "white",
              }}
              onClick={() => navigate("/mypage/review/create")}
            >
              리뷰 쓰기
            </Button>
          </BottomWrapper>
        )}
      </RowContainer>
    </Container>
  );
}
