import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useRecoilValue, useResetRecoilState } from "recoil";
import IcProfile from "../../assets/IcProfile.svg";
import { userState } from "../../store/authStore";
import { IsActiveProps } from "../Product/ProductCommon";

const TopRight = styled.div`
  display: flex;
`;
const LoginLink = styled.span<IsActiveProps>`
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  color: ${(props) => (props.isActive ? "#f00" : "939394")};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: var(--red-100, #f00);
  }
`;
const SignupLink = styled(LoginLink)`
  cursor: pointer;
`;
const options = [
  { value: "order", label: "주문내역" },
  { value: "like", label: "찜한 상품 관리" },
  { value: "review", label: "내 리뷰 관리" },
  { value: "point", label: "포인트" },
  { value: "ask", label: "1:1 문의" },
  { value: "profile", label: "개인정보수정" },
  { value: "exit", label: "회원탈퇴" },
  { value: "logout", label: "로그아웃" },
];

export default function UserMenu() {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);
  const location = useLocation();

  if (user) {
    return (
      <TopRight>
        <Select
          value={null}
          menuPlacement="auto"
          menuPosition="fixed"
          options={options}
          isSearchable={false}
          maxMenuHeight={400}
          onInputChange={() => {}}
          onChange={(t) => {
            if (t?.value === "logout") {
              resetUser();
              window.localStorage.removeItem("accessToken");
              window.localStorage.removeItem("refreshToken");
              navigate("/login");
            } else {
              navigate("/mypage/" + t?.value);
            }
          }}
          styles={{
            container: (base) => ({ width: "160px", height: "42px" }),
            control: (base) => ({
              ...base,
              border: "none",
              boxShadow: "none",
              cursor: "pointer",
            }),
            option: (base) => ({ ...base, cursor: "pointer" }),
          }}
          placeholder={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={IcProfile} />
              <div>{user.username}</div>
            </div>
          }
          components={{ IndicatorSeparator: () => null }}
        />
      </TopRight>
    );
  }
  return (
    <TopRight>
      <LoginLink
        isActive={location.pathname.includes("/login")}
        onClick={() => navigate("/login")}
      >
        로그인
      </LoginLink>
      <SignupLink
        isActive={location.pathname.includes("/signup")}
        onClick={() => navigate("/signup")}
      >
        회원가입
      </SignupLink>
    </TopRight>
  );
}
