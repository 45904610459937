import React, { useState, useEffect } from "react";

function useCountdown(n?: string) {
  let interval: any = null;

  const [timeLeft, setTimeLeft] = useState(3 * 60); // 초 단위로 카운트 다운 시간 저장

  useEffect(() => {
    if (!n) return;

    setTimeLeft(3 * 60);
  }, [n]);

  useEffect(() => {
    if (!n) return;

    // let interval: any;

    // 타이머가 활성화되고 남은 시간이 0보다 크면 1초마다 감소
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      // 남은 시간이 0이면 타이머를 멈춥니다.
      clearInterval(interval);
    }

    // 컴포넌트가 unmount되면 타이머를 정리합니다.
    return () => clearInterval(interval);
  }, [n, timeLeft, interval]);

  const stopInterval = () => {
    clearInterval(interval);
  };

  return {
    timeLeft,
    stopInterval,
  };
}

export default useCountdown;
