import OneToOneCreate from "../components/MyPage/OneToOneCreate";
import MyPageLayout from "../components/MyPageLayout";

export default function MyAskCreatePage() {
  return (
    <MyPageLayout menu="ask">
      <OneToOneCreate />
    </MyPageLayout>
  );
}
