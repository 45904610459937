import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { PaginationType } from "../../api/productApi";
import { MyProductLike, getProductLikes } from "../../api/productLikeApi";
import ContentItem from "../Main/ContentItem";
import Pagination from "../Common/Pagenation";
import {
  ListTitle,
  ListTitleCount,
  ListTitleWrapper,
} from "../Product/ListHeader";
import { PAGE_SIZE } from "../../utils/common.util";

const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    justify-content: flex-start;
    gap: 16px;
  }
`;

export default function MobileLikeHistory() {
  const [res, setRes] = useState<PaginationType<MyProductLike> | null>(null);
  const [page, setPage] = useState(1);
  const [updateNumber, setUpdateNumber] = useState(0);

  useEffect(() => {
    getProductLikes(PAGE_SIZE, (page - 1) * PAGE_SIZE).then(setRes);
  }, [updateNumber, page]);

  return (
    <>
      <ListTitleWrapper>
        <ListTitle>전체</ListTitle>
        <ListTitleCount>{res?.count}</ListTitleCount>
      </ListTitleWrapper>
      <ItemsWrapper>
        {res?.results.map((content) => (
          <ContentItem
            key={content.uuid}
            content={content.product}
            _likeUuid={content.uuid}
          />
        ))}
      </ItemsWrapper>
      <Pagination curIndex={page} paginate={setPage} maxCount={res?.count} pageSize={PAGE_SIZE} />
    </>
  );
}
