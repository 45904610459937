import styled from "@emotion/styled";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PaginationType } from "../../api/productApi";
import { ReactComponent as Prev } from "../../assets/ChevronLeftIcon.svg";
import { ReactComponent as Next } from "../../assets/ChevronRightIcon.svg";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  ProductReview,
  ProductReviewOrdering,
  getReviewList,
} from "../../api/reviewApi";
import Pagination from "../Common/Pagenation";
import { IsActiveProps, ProductTitle } from "./ProductCommon";
import ProductReviewItem from "./ProductReviewItem";
import {
  ProductReviewImage,
  getProductReviewImageList,
} from "../../api/productReviewImageApi";
import { useSetRecoilState } from "recoil";
import { imageDetailState } from "../../store/productStore";
import SquareImage from "../Common/SquareImgae";
import Button from "../Common/Button";
import { isMobile } from "react-device-detect";
import { PAGE_SIZE, REVIEW_PAGE_SIZE } from "../../utils/common.util";

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;
const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
`;
const SubTitle = styled.span`
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const SortSplitter = styled.div`
  width: 1px;
  height: 12px;
  background: #e0e0e1;
`;
const SortItem = styled.span<IsActiveProps>`
  color: ${(props) => (props.isActive ? "#29292a" : "#B2B2B3")};
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: ${(props) => (props.isActive ? "underline" : "none")};
  cursor: pointer;
`;
const ContentWrapper = styled.div`
  width: 100%;
`;
const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  /* margin-top: 24px; */
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const CreateWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
const CreateText = styled.div`
  color: var(--grey-90, #707071);
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ImgList = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  max-width: 100%;
  overflow: scroll;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;
const MobileImgsWrapper = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }
`;
const MobileImgTitle = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    color: var(--black, #000);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.032px;
  }
`;
const MobileImgList = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;
const MobileImg = styled.div`
  flex: 1 0 0;
  position: relative;
`;
const MobileMore = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  /* height: 180px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  position: relative;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

let initial = true;
export default function ProductReviewContent({
  porductUuid,
}: {
  porductUuid: string;
}) {
  const ref = useRef<SwiperRef | null>(null);
  const el = useRef<null | HTMLDivElement>(null);
  const [res, setRes] = useState<null | PaginationType<ProductReview>>(null);
  const [page, setPage] = useState(1);
  const [imgPage, setImgPage] = useState(1);
  const [sortBy, setSortBy] = useState<ProductReviewOrdering>("-created");
  const setImgDetail = useSetRecoilState(imageDetailState);

  const [imgRes, setImgRes] =
    useState<null | PaginationType<ProductReviewImage>>(null);

  useEffect(() => {
    if (el.current && res && (page > 1 || (!initial && page === 1))) {
      el.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [res, el, page]);

  useEffect(() => {
    if (page !== 1) initial = false;
  }, [page]);

  useEffect(() => {
    if (porductUuid) {
      getReviewList(
        porductUuid,
        REVIEW_PAGE_SIZE,
        (page - 1) * REVIEW_PAGE_SIZE,
        sortBy
      ).then((e) => {
        setRes(e);
      });
    }
  }, [porductUuid, page, sortBy]);

  useEffect(() => {
    if (porductUuid) {
      getProductReviewImageList(
        porductUuid,
        PAGE_SIZE,
        (1 - imgPage) * PAGE_SIZE
      ).then(setImgRes);
    }
  }, [porductUuid, imgPage]);

  const slides = useMemo(() => {
    if (!imgRes) return [];
    return imgRes.results.map((t) => t.image);
  }, [imgRes]);

  return (
    <ReviewWrapper>
      <div style={{ width: "100%" }}>
        <TopWrapper>
          <TitleWrapper>
            <ProductTitle>상품후기</ProductTitle>
            <SubTitle>{res?.count}</SubTitle>
          </TitleWrapper>
          <SortWrapper>
            <SortItem
              isActive={sortBy === "-created"}
              onClick={() => setSortBy("-created")}
            >
              최신순
            </SortItem>
            <SortSplitter />
            <SortItem
              isActive={sortBy === "-like_count"}
              onClick={() => setSortBy("-like_count")}
            >
              추천순
            </SortItem>
          </SortWrapper>
        </TopWrapper>
        <CreateText>구매한 상품만 리뷰 작성이 가능합니다.</CreateText>
        <CreateWrapper>
          <a href={"/mypage/review/create"}>
            <Button
              style={
                isMobile
                  ? {
                      fontSize: 15,
                      height: 42,
                      fontWeight: 500,
                      color: "#29292A",
                    }
                  : {
                      fontSize: 15,
                      fontWeight: 500,
                      maxWidth: "100px",
                      border: "1px solid #29292A",
                      color: "#29292A",
                      height: 42,
                    }
              }
            >
              작성하기
            </Button>
          </a>
        </CreateWrapper>
      </div>
      {slides.length > 0 && (
        <ContentContainer>
          <div style={{ maxWidth: "calc(100% - 84px)", margin: "0 auto" }}>
            <Swiper
              ref={ref}
              modules={[Navigation]}
              slidesPerView={5}
              loop
              spaceBetween={5}
              navigation={{ nextEl: ".arrow-next", prevEl: ".arrow-prev" }}
            >
              {slides.map((t, idx) => (
                <SwiperSlide
                  key={"kd-" + idx}
                  style={{
                    // height: 180,
                    width: 180,
                    zIndex: 100,
                  }}
                >
                  {/* <Img src={t} onClick={() => setImgDetail(slides)} /> */}
                  <SquareImage
                    imageUrl={t}
                    onClick={() => setImgDetail({ list: slides, idx })}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <PrevButton {...{ swiper: ref.current?.swiper }} />
          <NextButton {...{ swiper: ref.current?.swiper }} />
        </ContentContainer>
      )}
      {/* {imgRes && imgRes.results.length > 0 && (
        <ImgList>
          <Prev />
          {imgRes?.results.map((t, idx) => (
            <img
              key={t.uuid}
              src={t.image}
              style={{ width: 180, height: 180, cursor: "pointer" }}
              onClick={() => setImgDetail({ list: slides, idx })}
            />
          ))}
          <Next />
        </ImgList>
      )} */}
      <MobileImgsWrapper>
        <MobileImgTitle>포토리뷰</MobileImgTitle>
        {imgRes && imgRes.results.length > 0 && (
          <MobileImgList>
            {imgRes?.results.slice(0, 3).map((t, idx) => (
              <MobileImg
                key={t.uuid}
                onClick={() => setImgDetail({ list: slides, idx })}
              >
                <SquareImage imageUrl={t.image} />
              </MobileImg>
            ))}
            {imgRes?.results.length > 3 ? (
              <MobileImg
                key={imgRes.results[3].uuid}
                onClick={() => setImgDetail({ list: slides, idx: 3 })}
              >
                <SquareImage imageUrl={imgRes.results[3].image} />
                <MobileMore>더보기</MobileMore>
              </MobileImg>
            ) : (
              <MobileImg />
            )}
          </MobileImgList>
        )}
      </MobileImgsWrapper>
      <ContentWrapper>
        <ItemList>
          <div ref={el} />
          {res?.results.map((a, k) => (
            <ProductReviewItem review={a} key={`review-item-${k}`} />
          ))}
        </ItemList>
        <Pagination
          curIndex={page}
          paginate={setPage}
          maxCount={res?.count}
          pageSize={REVIEW_PAGE_SIZE}
        />
      </ContentWrapper>
    </ReviewWrapper>
  );
}

function PrevButton({ swiper }: { swiper?: SwiperCore }) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slidePrev();
    }
  }, [swiper]);

  return (
    <Arrow onClick={clickHandler} style={{ left: 0 }} className="arrow-prev">
      <Prev />
    </Arrow>
  );
}
function NextButton({ swiper }: { swiper?: SwiperCore }) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slideNext();
    }
  }, [swiper]);

  return (
    <Arrow onClick={clickHandler} style={{ right: 0 }} className="arrow-next">
      <Next />
    </Arrow>
  );
}
