import { isMobile } from "react-device-detect";
import LikeHistory from "../components/MyPage/LikeHistory";
import MyPageLayout from "../components/MyPageLayout";
import MobileLikeHistory from "../components/MyPage/MobileLikeHistory";

export default function MyLikePage() {
  return (
    <MyPageLayout menu="like">
      {isMobile ? <MobileLikeHistory /> : <LikeHistory />}
    </MyPageLayout>
  );
}
