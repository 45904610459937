import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { PaginationType } from "../../api/productApi";
import { MyProductLike, getProductLikes } from "../../api/productLikeApi";
import Pagination from "../Common/Pagenation";
import EmptyOrder from "./EmptyOrder";
import LikeItem from "./LikeItem";
import { PAGE_SIZE } from "../../utils/common.util";

const Container = styled.div`
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export default function LikeHistory() {
  const [res, setRes] = useState<PaginationType<MyProductLike> | null>(null);
  const [page, setPage] = useState(1);
  const [updateNumber, setUpdateNumber] = useState(0);

  useEffect(() => {
    getProductLikes(PAGE_SIZE, (page - 1) * PAGE_SIZE).then(setRes);
  }, [updateNumber, page]);

  return (
    <Container>
      <Header>
        <HeaderTitleWrapper>
          <HeaderTitle>찜한 상품 관리</HeaderTitle>
        </HeaderTitleWrapper>
      </Header>
      {res?.count === 0 ? (
        <EmptyOrder />
      ) : (
        <ItemsWrapper>
          {res?.results.map((t) => {
            // const { id, createdAt, imgUrl, title, price, discountPct } = t;
            const { uuid, created, product } = t;
            const { origin_price, current_price, sale_percentage, title, main_thumbnail_image } = product;
            return (
              <div key={`like-items-${uuid}`} style={{ width: "100%" }}>
                <LikeItem
                  likeUuid={uuid}
                  productUuid={product.uuid}
                  createdAt={created}
                  main_thumbnail_image={main_thumbnail_image}
                  title={title}
                  price={origin_price}
                  currentPrice={current_price}
                  discountPct={sale_percentage}
                  successCb={() => setUpdateNumber((v) => v + 1)}
                />
              </div>
            );
          })}
          <Pagination
            curIndex={page}
            paginate={setPage}
            maxCount={res?.count}
            pageSize={PAGE_SIZE}
          />
        </ItemsWrapper>
      )}
    </Container>
  );
}
