import styled from "@emotion/styled";
import { PaymentMethodEnum } from "../../types/enums";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const Row = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const RowTitle = styled.div`
  color: var(--grey-80, #707071);
  font-size: 16px;
  font-weight: 500;
`;
const RowContent = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const RowBoldContent = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 32px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export default function PurchasePrice({
  price,
  discount,
  pointSent,
  totalPrice,
  paymentMethod,
  paymentWay,
}: {
  price: number;
  discount: number;
  pointSent: number;
  totalPrice: number;
  paymentMethod: keyof typeof PaymentMethodEnum;
  paymentWay: string;
}) {
  return (
    <Container>
      <Row>
        <RowTitle>상품 금액</RowTitle>
        <RowContent>{price.toLocaleString()}원</RowContent>
      </Row>
      <Row>
        <RowTitle>할인 금액</RowTitle>
        <RowContent>{discount.toLocaleString()}원</RowContent>
      </Row>
      <Row>
        <RowTitle>포인트 사용</RowTitle>
        <RowContent>{pointSent.toLocaleString()}원</RowContent>
      </Row>
      <Row>
        <RowTitle>총 결제 금액</RowTitle>
        <RowBoldContent>{totalPrice.toLocaleString()}원</RowBoldContent>
      </Row>
      <Row>
        <RowTitle>결제 방법</RowTitle>
        {totalPrice === 0 ? (
          <RowContent>포인트</RowContent>
        ) : (
          <RowContent>
            {paymentMethod === "welcome" && paymentWay === "trans"
              ? "실시간 계좌이체"
              : PaymentMethodEnum[paymentMethod]}
          </RowContent>
        )}
      </Row>
    </Container>
  );
}
