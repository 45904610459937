import dayjs from "dayjs";

export enum ProductCategory {
  "ALONE" = "나 혼자",
  "WITH_LOVER" = "연인과",
  "WITH_FAMILY" = "가족과",
  "BUCKET_LIST" = "버킷리스트",
  "FLEX" = "FLEX",
  "MOUNTAIN" = "산",
  "FIELD" = "들",
  "SEA" = "바다",
  "NOLLYS_PICK" = "놀리 픽",
}

export function isProductCategory(
  value: string
): value is keyof typeof ProductCategory {
  return value in ProductCategory;
}

export enum MyPageMenu {
  "order" = "주문내역",
  "like" = "찜한 상품 관리",
  "review" = "내 리뷰 관리",
  "point" = "포인트",
  "ask" = "1:1 문의",
  "profile" = "개인정보수정",
  "exit" = "회원탈퇴",
}

export enum ProductSort {
  "sold_count" = "인기 순",
  "review_count" = "리뷰 많은 순",
  "current_price" = "가격 낮은 순",
  "-current_price" = "가격 높은 순",
}

export enum ProductTopic {
  "ACTIVITY" = "액티비티",
  "WORKACTION" = "워케이션",
  "CLASS" = "클래스",
}

export function isProductTopic(
  value: string
): value is keyof typeof ProductTopic {
  return value in ProductTopic;
}

export enum NoticeTopic {
  "ALL" = "전체",
  "NOTICE" = "공지",
  "EVENT" = "이벤트",
  "PRESS" = "발표",
}

export enum PaymentMethodEnum {
  "html5_inicis" = "신용카드",
  "welcome" = "신용카드",
  // "nice" = "신용카드",
  "kakaopay" = "카카오페이",
  "mobilians" = "휴대폰 결제",
}
export enum QuestionTopic {
  // "PRODUCT" = "상품",
  PAYMENT_CARD_ERROR = "카드결제 오류문의",
  POINT_SPEND = "포인트 사용문의",
  REFUND = "환불 관련 문의",
  EVENT = "이벤트 문의",
  PRODUCT_SCHEDULE_UPDATE = "상품 일정 변경 문의",
  SIGN_UP_LOG_IN = "로그인/회원가입 오류 문의",
  GENERAL = "이용 중 오류 문의",
  OFFER = "놀리에게 제안",
  OTHER = "기타",
}

export enum BankName {
  "004" = "KB국민은행",
  "023" = "SC제일은행",
  "039" = "경남은행",
  "034" = "광주은행",
  "003" = "기업은행",
  "011" = "농협",
  "031" = "대구은행",
  "032" = "부산은행",
  "002" = "산업은행",
  "045" = "새마을금고",
  "007" = "수협",
  "088" = "신한은행",
  "048" = "신협",
  "081" = "하나(외환)은행",
  "020" = "우리은행",
  "071" = "우체국",
  "037" = "전북은행",
  "012" = "축협",
  "090" = "카카오뱅크",
  "089" = "케이뱅크",
  "027" = "한국씨티은행",
  "092" = "토스뱅크",
}

export enum PayMethods {
  "card" = "신용카드",
  "trans" = "실시간계좌이체",
  "vbank" = "가상계좌",
  "phone" = "휴대폰소액결제",
  "paypal" = "페이팔 SPB 일반결제",
  "applepay" = "애플페이",
  "naverpay" = "네이버페이",
  "samsung" = "삼성페이",
  "kpay" = "KPay앱",
  "kakaopay" = "카카오페이",
  "payco" = "페이코",
  "lpay" = "LPAY",
  "ssgpay" = "SSG페이",
  "tosspay" = "토스간편결제",
  "cultureland" = "문화상품권",
  "smartculture" = "스마트문상",
  "happymoney" = "해피머니",
  "booknlife" = "도서문화상품권",
  // "point" = "베네피아 포인트 등 포인트 결제",
  "point" = "포인트",
  "wechat" = "위쳇페이",
  "alipay" = "알리페이",
  "unionpay" = "유니온페이",
  "tenpay" = "텐페이",
}

export function isMyPageMenu(value: string): value is keyof typeof MyPageMenu {
  return value in MyPageMenu;
}

export enum ProductDifficulty {
  HIGH = "상",
  MEDIUM = "중",
  LOW = "하",
}

export const dateOptions = [3, 6, 12, 24, 36].map((n) => ({
  label: `${n}개월`,
  // value: dayjs().subtract(n, "month").toISOString(),
  value: dayjs().subtract(n, "month").format("YYYY-MM-DD"),
}));
