import styled from "@emotion/styled";
import React, { ChangeEvent } from "react";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const InputContainer = styled.textarea`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--grey-50, #cbcbcc);
  background: var(--white, #fff);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.032px;
  resize: none;
  &::placeholder {
    color: var(--grey-60, #b2b2b3);
  }
`;
const ErrorText = styled.div`
  position: absolute;
  color: var(--red-100, #f00);
  margin-top: 2px;
  font-size: 8px;
  font-weight: 400;
`;

export type InputValue = string | number | ReadonlyArray<string>;
export type InputChangeEvent = ChangeEvent<HTMLTextAreaElement>;

interface Props {
  placeholder?: string;
  value?: InputValue;
  style?: React.CSSProperties;
  onChange?: (ev: InputChangeEvent) => void;
  errors?: any;
}

const TextArea: React.FunctionComponent<Props> = ({
  value = "",
  onChange,
  style,
  placeholder,
  errors,
}) => {
  return (
    <Container>
      <InputContainer
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={errors ? { ...style, border: "1px solid red" } : style}
      />
      {errors && <ErrorText>{errors}</ErrorText>}
    </Container>
  );
};

export default TextArea;
