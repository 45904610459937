import styled from "@emotion/styled";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Banner } from "../api/bannerApi";
import { ReactComponent as Prev } from "../assets/BannerChevronLeft.svg";
import { ReactComponent as Next } from "../assets/BannerChevronRight.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

const BannerCarouselContainer = styled.div`
  width: 100%;
  position: relative;
`;

const CarouselItem = styled.a`
  cursor: pointer;
`;
const CarouselImg = styled.div`
  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
`;
const StatusWrapper = styled.div`
  position: absolute;
  bottom: 14px;
  z-index: 10;
  right: 14px;
  border-radius: 100px;
  background: rgba(71, 71, 72, 0.9);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
`;
const SelectedSlide = styled.span`
  color: var(--neon-green-100, #0f0);
  font-size: 12px;
  font-weight: 500;
`;
const TotalSlide = styled.span`
  color: var(--grey-30, #efeff0);
  font-size: 12px;
  font-weight: 500;
`;

const arrowStyles: React.CSSProperties = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
};

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export default function BannerCarousel({
  banners,
  height,
  showIndicators = false,
  borderRadius = 0,
  showArrows = true,
  name = "",
}: {
  banners: Banner[];
  height: number;
  showIndicators?: boolean;
  borderRadius?: number;
  showArrows?: boolean;
  name: string;
}) {
  const [idx, setIdx] = useState(0);
  const ref = useRef<SwiperRef | null>(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount((v) => v + 1);
  }, [ref.current, banners]);

  if (banners.length === 0) return null;

  return (
    <BannerCarouselContainer style={{ height }}>
      <Swiper
        ref={ref}
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 10 * 1000,
          disableOnInteraction: false,
        }}
        loop={false}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: name + "-banner-arrow-next",
          prevEl: name + "-banner-arrow-prev",
        }}
        onSlideChange={(s) => {
          setIdx(s.activeIndex);
        }}
      >
        {banners.map(({ uuid, pc_image, mobile_image, url }) => (
          <SwiperSlide key={name + "-banner-" + uuid}>
            <CarouselItem key={uuid} href={url}>
              <CarouselImg
                style={{
                  backgroundImage: `url(${isMobile ? mobile_image : pc_image})`,
                  height,
                  borderRadius,
                }}
              />
            </CarouselItem>
          </SwiperSlide>
        ))}
      </Swiper>
      <PrevButton
        {...{ swiper: ref.current?.swiper }}
        className={name + "-banner-arrow-prev"}
        idx={idx}
      />
      <NextButton
        {...{ swiper: ref.current?.swiper }}
        className={name + "-banner-arrow-next"}
        idx={idx}
      />
      {showIndicators && banners.length > 0 && (
        <StatusWrapper>
          <SelectedSlide>{idx + 1}</SelectedSlide>
          <TotalSlide>/{banners.length}</TotalSlide>
        </StatusWrapper>
      )}
    </BannerCarouselContainer>
  );
}

function PrevButton({
  swiper,
  className,
  idx,
}: {
  swiper?: SwiperCore;
  className: string;
  idx: number;
}) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slideTo(idx - 1);
    }
  }, [swiper, idx]);

  return (
    <Arrow
      onClick={clickHandler}
      style={{ left: 15, zIndex: 10 }}
      className={className}
    >
      <Prev />
    </Arrow>
  );
}
function NextButton({
  swiper,
  className,
  idx,
}: {
  swiper?: SwiperCore;
  className: string;
  idx: number;
}) {
  const clickHandler = useCallback(() => {
    if (!swiper) return;
    else {
      swiper.slideTo(idx + 1);
    }
  }, [swiper, idx]);

  return (
    <Arrow
      onClick={clickHandler}
      style={{ right: 15, zIndex: 10 }}
      className={className}
    >
      <Next />
    </Arrow>
  );
}
