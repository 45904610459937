import styled from "@emotion/styled";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ReactComponent as Prev } from "../../assets/BannerChevronLeft.svg";
import { ReactComponent as Next } from "../../assets/BannerChevronRight.svg";
import SquareImage from "../Common/SquareImgae";

const ProductCarouselContainer = styled.div`
  width: 420px;
  height: 420px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: calc(100vw - 28px);
  }
`;
const arrowStyles: React.CSSProperties = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
};

export default function ProductCarousel({ imgs }: { imgs: string[] }) {
  return (
    <ProductCarouselContainer>
      <Carousel
        showArrows={true}
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <div onClick={clickHandler} style={{ ...arrowStyles, right: 15 }}>
              <Next />
            </div>
          )
        }
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <div onClick={clickHandler} style={{ ...arrowStyles, left: 15 }}>
              <Prev />
            </div>
          )
        }
        swipeable
        showIndicators={false}
        statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
      >
        {imgs.map((img) => (
          <SquareImage key={img} imageUrl={img} radius={8} />
          // <CarouselImg key={img} src={img} />
        ))}
      </Carousel>
    </ProductCarouselContainer>
  );
}
