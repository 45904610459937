import { NoticeTopic } from "../types/enums";
import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Notice {
  uuid: string;
  title: string;
  created: string;
  modified: string;
}

export function getNoticeList(
  limit?: number,
  offset?: number,
  topic?: keyof typeof NoticeTopic
): Promise<PaginationType<Notice>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  if (topic && topic !== "ALL") query += `topic=${topic}`;
  return nollyAxios.get(`/api/notices` + query).then(({ data }) => data);
}

export interface NoticeDetail extends Notice {
  content: string;
}

export function getNoticeDetail(noticeUuid: string): Promise<NoticeDetail> {
  return nollyAxios.get(`/api/notices/` + noticeUuid).then(({ data }) => data);
}

export interface Event {
  uuid: string;
  title: string;
  created: string;
  modified: string;
}

export function getEventList(
  limit?: number,
  offset?: number
): Promise<PaginationType<Event>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  return nollyAxios.get(`/api/events` + query).then(({ data }) => data);
}

export interface EventDetail extends Event {
  content: string;
}

export function getEventDetail(uuid: string): Promise<EventDetail> {
  return nollyAxios.get(`/api/events/${uuid}`).then(({ data }) => data);
}

export interface Press {
  uuid: string;
  title: string;
  created: string;
  modified: string;
}

export function getPressList(
  limit?: number,
  offset?: number
): Promise<PaginationType<Press>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  return nollyAxios.get(`/api/press` + query).then(({ data }) => data);
}

export interface PressDetail extends Press {
  content: string;
}

export function getPressDetail(uuid: string): Promise<PressDetail> {
  return nollyAxios.get(`/api/press/${uuid}`).then(({ data }) => data);
}
