import { isMobile } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import Loading from "./components/Common/Loading";
import ScrollToTop from "./components/Common/ScrollToTop";
import GlobalError from "./components/GlobalError";
import ImageDetailPopup from "./components/ImageDetailPopup";
import LoginPopup from "./components/LoginPopup";
import Index from "./router";
import MobileIndex from "./router/mobile";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import GoTop from "./components/Common/GoTopButton";

function App() {
  useEffect(() => {
    document.addEventListener('touchstart', touchHandler, {passive: true});
  }, []);

  function touchHandler(event: any) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }

  return (
    <ErrorBoundary FallbackComponent={GlobalError}>
      <ScrollToTop />
      <LoginPopup />
      <ImageDetailPopup />
      <Loading />
      {isMobile ? <MobileIndex /> : <Index />}
      <GoTop />
    </ErrorBoundary>
  );
}

export default App;
