import styled from "@emotion/styled";
import { CommonContainer } from "./Common";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

const FooterContainer = styled.footer`
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid var(--grey-30, #efeff0);
`;
const Container = styled(CommonContainer)`
  padding: 40px 14px;
  flex-direction: column;
  align-items: flex-start;
`;
const TopWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 28px;
`;
const TopLink = styled.a`
  color: var(--grey-60, #b2b2b3);
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
const MidWrapper = styled.div`
  margin-bottom: 14px;
`;
const MidTitle = styled.a`
  display: block;
  color: var(--grey-90, #474748);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 12px;
`;
const MidContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--grey-80, #707071);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const MidFooter = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 8px 4px;
  align-self: stretch;
  flex-wrap: wrap;
  color: var(--grey-70, #939394);
  font-size: 12px;
  font-weight: 400;
`;
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 8px 4px;
  align-self: stretch;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 28px;
`;
const AgreeMentLink = styled.a`
  font-size: 12px;
  font-weight: bold;
`;

export default function Footer() {
  const { pathname } = useLocation();
  if (isMobile && pathname.includes("/login")) return null;
  if (isMobile && pathname.includes("/signup")) return null;
  if (isMobile && pathname.includes("/find-id")) return null;
  if (isMobile && pathname.includes("/find-pw")) return null;
  if (isMobile && pathname.includes("/pw-reset")) return null;
  if (isMobile && pathname.includes("/introduction")) return null;
  if (isMobile && pathname.includes("/partnership")) return null;
  if (isMobile && pathname.includes("/adopt-enterprise")) return null;

  if (isMobile && pathname.includes("/products")) return null;
  if (isMobile && pathname.includes("/products")) return null;
  if (isMobile && pathname.includes("/payment")) return null;
  if (isMobile && pathname.includes("/mypage")) return null;
  if (isMobile && pathname.includes("/careers")) return null;
  if (isMobile && pathname.includes("/notices")) return null;
  if (isMobile && pathname.includes("/help-center")) return null;
  if (isMobile && pathname.includes("/terms")) return null;
  if (isMobile && pathname.includes("/privacy")) return null;

  return (
    <FooterContainer>
      <Container>
        <TopWrapper>
          <TopLink href={"/introduction"}>Nolly 소개</TopLink>
          <TopLink href={"/partnership"}>제휴 안내</TopLink>
          <TopLink href={"/careers"}>인재 채용</TopLink>
        </TopWrapper>
        <MidWrapper>
          {/* <MidTitle href={"/help-center"}>고객센터</MidTitle> */}
          <MidTitle href={"/help-center"}>고객센터</MidTitle>
          <MidContent>
            고객센터:{" "}
            <AgreeMentLink style={{ display: "contents" }} href={"/mypage/ask"}>
              문의하기
            </AgreeMentLink>{" "}
            | 이메일 (CS@nolly.life)
            <br />
            운영시간: 평일/주말 10:00 ~ 17:00 (휴식 12:00 ~ 13:00)
            <br />
            광고/제휴: Contact@nolly.life
          </MidContent>
          <MidFooter>
            주식회사 놀리 | 사업자등록번호: 744-87-02924 | 통신판매업신고번호:
            2023-서울마포-3292 | 개인정보 관리 책임자/대표: 윤하늘 | 서울시
            마포구 양화로 120, 로컬스티치 2F | 연락처 : 070-8098-0601
            {/* <br /> */}
            {/* 주식회사 놀리는 통신판매중개자로서 거래당사자가 아니며, 호스트가
            등록한 상품정보 및 거래에 대해 주식회사 놀리는 일체의 책임을 지지
            않습니다.
            <br />
            NICEPAY 안전거래 서비스 | 고객님의 안전거래를 위해 현금결제 시, 저희
            사이트에서 가입한 구매안전 서비스를 이용하실 수 있습니다.
            <AgreeMentLink
              style={{ display: "contents" }}
              href="https://pg.nicepay.co.kr/issue/IssueEscrow.jsp?Mid=IM0015497m&CoNo=7448702924"
            >
              가입 확인
            </AgreeMentLink> */}
          </MidFooter>
        </MidWrapper>
        <BottomWrapper>
          <AgreeMentLink
            // target={"_blank"}
            href="/terms"
            style={{ color: "#939394" }}
          >
            이용 약관
          </AgreeMentLink>{" "}
          |{" "}
          <AgreeMentLink
            // target={"_blank"}
            href="/privacy"
            style={{ color: "#939394" }}
          >
            개인정보처리 방침
          </AgreeMentLink>
        </BottomWrapper>
      </Container>
    </FooterContainer>
  );
}

{
  /* <div>
  (주)놀리 | 사업자등록번호: 744-87-02924 |{" "}
  <a target={"_blank"} href="/terms" style={{ color: "#939394" }}>
    이용약관
  </a>{" "}
  |{" "}
  <a target={"_blank"} href="/privacy" style={{ color: "#939394" }}>
    개인정보약관(필수)
  </a>{" "}
  |{" "}
  <a target={"_blank"} href="/privacy-optional" style={{ color: "#939394" }}>
    개인정보약관(선택)
  </a>{" "}
  |{" "}
  <a target={"_blank"} href="/marketing-agreement" style={{ color: "#939394" }}>
  혜택/정보 약관
  </a>{" "}
  | 서울시 마포구 양화로 120, 로컬스티치 2F
</div> */
}
