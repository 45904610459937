import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { ReactComponent as Up } from "../../assets/ChevronUp.svg";

const GoTopContainer = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  @media screen and (max-width: 768px) {
    bottom: 70px;
  }
`;

const GoTopButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #29292a;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
`;

export default function GoTop() {
  const [showGoTop, setShowGoTop] = useState(false);

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 50);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
    return () => window.removeEventListener("scroll", handleVisibleButton);
  }, []);

  if (!showGoTop) return null;

  return (
    <GoTopContainer onClick={handleScrollUp}>
      <GoTopButton>
        <Up />
      </GoTopButton>
    </GoTopContainer>
  );
}
