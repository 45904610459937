import nollyAxios from "./nollyAxios";

export interface Answer {
  uuid: string;
  title: string;
  content: string;
  created: string;
  modified: string;
}

export function getAnswer(questionUuid: string): Promise<Answer> {
  return nollyAxios
    .get(`/api/product_questions/${questionUuid}/product_answers/answer`)
    .then(({ data }) => data);
}
