import React, { useState } from "react";
import Header from "./Header";
import styled from "@emotion/styled";
import Footer from "./Footer";
import BottomTabBar from "./BottomTabBar";
import MobileHeader from "./Header/Mobile";

const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 1 0;
`;

const MobileLayout: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LayoutContainer>
      <MobileHeader />
      <Main>{children}</Main>
      <Footer />
      <BottomTabBar />
    </LayoutContainer>
  );
};

export default MobileLayout;
