import nollyAxios from "./nollyAxios";

export interface Inquiry {
  uuid: string;
  company_name: string;
  address_a: string;
  address_b: string;
  poc_name: string;
  poc_mobile_no: string;
  poc_email: string;
  content: string;
  created: string;
  modified: string;
}

export function createInquiry(
  payload: Omit<Inquiry, "uuid" | "created" | "modified">
): Promise<any> {
  return nollyAxios
    .post(`/api/service_inquiries`, payload)
    .then(({ data }) => data);
}
