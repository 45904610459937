import styled from "@emotion/styled";
import { IsActiveProps } from "../Product/ProductCommon";
import { isMobile } from "react-device-detect";
import { ReactComponent as IcSort } from "../../assets/IcSort.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useState } from "react";
import { ProductSort } from "../../types/enums";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const ListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
export const ListTitle = styled.div`
  color: var(--black, #000);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.048px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
export const ListTitleCount = styled.div`
  color: var(--grey-70, #939394);
  font-size: 18px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const SortItem = styled.div<IsActiveProps>`
  color: ${(props) => (props.isActive ? "#29292A" : "#B2B2B3")};
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: ${(props) => (props.isActive ? "underline" : "none")};
  cursor: pointer;
`;

const MobileSortTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const MobileSortTitle = styled.div`
  color: var(--grey-80, #707071);
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
`;
const MobileTabWrapper = styled.div<IsActiveProps>`
  display: flex;
  padding: 20px 14px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: ${(props) => (props.isActive ? "#29292A" : "#B2B2B3")};
  font-size: 14px;
  font-weight: ${(props) => (props.isActive ? 600 : 500)};
`;

export default function ListHeader({
  title,
  subTitle,
  sortBy,
  onChangeSortBy,
}: {
  title: string;
  subTitle: string;
  sortBy: keyof typeof ProductSort;
  onChangeSortBy: (t: keyof typeof ProductSort) => void;
}) {
  const [open, setOpen] = useState(false);

  const _onChangeSortBy = (t: keyof typeof ProductSort) => {
    onChangeSortBy(t);
    setOpen(false);
  };

  const onDismiss = () => {
    setOpen(false);
  };
  return (
    <HeaderWrapper>
      <ListTitleWrapper>
        <ListTitle>{title}</ListTitle>
        <ListTitleCount>{subTitle}</ListTitleCount>
      </ListTitleWrapper>
      {isMobile ? (
        <>
          <MobileSortTitleWrapper onClick={() => setOpen(true)}>
            <IcSort />
            <MobileSortTitle>{ProductSort[sortBy]}</MobileSortTitle>
          </MobileSortTitleWrapper>
          <BottomSheet
            open={open}
            onDismiss={onDismiss}
            style={{ zIndex: 101 }}
          >
            <MobileTabWrapper
              isActive={sortBy === "sold_count"}
              onClick={() => _onChangeSortBy("sold_count")}
            >
              인기순
            </MobileTabWrapper>
            <MobileTabWrapper
              isActive={sortBy === "review_count"}
              onClick={() => _onChangeSortBy("review_count")}
            >
              리뷰 많은 순
            </MobileTabWrapper>
            <MobileTabWrapper
              isActive={sortBy === "current_price"}
              onClick={() => _onChangeSortBy("current_price")}
            >
              가격 낮은 순
            </MobileTabWrapper>
            <MobileTabWrapper
              isActive={sortBy === "-current_price"}
              onClick={() => _onChangeSortBy("-current_price")}
            >
              가격 높은 순
            </MobileTabWrapper>
          </BottomSheet>
        </>
      ) : (
        <SortWrapper>
          <SortItem
            isActive={sortBy === "sold_count"}
            onClick={() => onChangeSortBy("sold_count")}
          >
            인기순
          </SortItem>
          <SortItem
            isActive={sortBy === "review_count"}
            onClick={() => onChangeSortBy("review_count")}
          >
            리뷰 많은 순
          </SortItem>
          <SortItem
            isActive={sortBy === "current_price"}
            onClick={() => onChangeSortBy("current_price")}
          >
            가격 낮은 순
          </SortItem>
          <SortItem
            isActive={sortBy === "-current_price"}
            onClick={() => onChangeSortBy("-current_price")}
          >
            가격 높은 순
          </SortItem>
        </SortWrapper>
      )}
    </HeaderWrapper>
  );
}
