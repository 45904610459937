import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface PointPurchase {
  uuid: string;
  amount_spent: number;
  purchase_method: string;
  created: string;
  modified: string;
}

export function pointPurchase(amount_spent: number): Promise<PointPurchase> {
  const payload = {
    amount_spent,
  };
  return nollyAxios
    .post(`/api/point_purchases`, payload)
    .then(({ data }) => data);
}

export function getPointPruchases(
  limit?: number,
  offset?: number,
  creaetd__gte?: string
): Promise<PaginationType<PointPurchase>> {
  let query = "?";
  if (offset) query += `offset=${offset}&`;
  if (limit) query += `limit=${limit}&`;
  if (creaetd__gte) query += `creaetd__gte=${creaetd__gte}`;
  return nollyAxios
    .get(`/api/point_purchases${query}`)
    .then(({ data }) => data);
}

export function requestRefund(
  amount: number,
  bank_name: string,
  bank_account_no: string,
  bank_account_owner: string
): Promise<any> {
  const payload = {
    amount,
    bank_account_no,
    bank_account_owner,
    bank_name,
  };
  return nollyAxios
    .post(`/api/point_refund_requests`, payload)
    .then(({ data }) => data);
}
