import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  ProductReview,
  likeReview,
  removeLikeReview,
} from "../../api/reviewApi";
import { ReactComponent as Thumbsup } from "../../assets/ThumbsupIcon.svg";
import { ReactComponent as ThumbsupActive } from "../../assets/ThumbsupIconActive.svg";
import { maskString } from "../../utils/common.util";
import { useSetRecoilState } from "recoil";
import { loginPopupState } from "../../store/commonStore";
import { isMobile } from "react-device-detect";
import { imageDetailState } from "../../store/productStore";

const ReviewItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  /* align-self: stretch; */
  border-bottom: 1px solid var(--grey-30, #efeff0);
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
  }
`;
const LeftWrapper = styled.div`
  display: flex;
  min-width: 160px;
  max-width: 160px;
  padding: 20px 14px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    padding: 0 14px;
  }
`;
const MiddleWrapper = styled.div`
  display: flex;
  padding: 20px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  /* align-self: stretch; */
`;

const Title = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const Content = styled.pre`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  max-width: 650px;
  white-space: pre-line;
  word-break: break-all;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  @media screen and (max-width: 768px) {
    font-size: 14px;
    max-width: 100%;
  }
`;
const ImgsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  max-width: 100%;
  /* align-self: stretch; */
  overflow: scroll;
  white-space: nowrap;
  justify-content: flex-start;
  max-width: 650px;
  overflow-y: scroll;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  width: 100px;
  height: 100px;
`;
const DateWrapper = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 200px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding-top: 0;
  }
`;

export default function ProductReviewItem({
  review,
}: {
  review: ProductReview;
}) {
  const [likeUuid, setLikeUuid] = useState<null | string>(null);
  const [likeCount, setLikeCount] = useState<number>(0);
  const setLoginPopup = useSetRecoilState(loginPopupState);
  const setImgDetail = useSetRecoilState(imageDetailState);

  useEffect(() => {
    if (review) {
      setLikeUuid(review.like_uuid);
      setLikeCount(review.like_count);
    }
  }, [review]);

  const toggle = () => {
    if (!window.localStorage.getItem("accessToken")) {
      setLoginPopup({
        next: "/products/" + review.product.uuid,
        state: {},
      });
      return;
    }

    if (likeUuid) {
      removeLikeReview(likeUuid).then(() => {
        setLikeUuid(null);
        setLikeCount((v) => v - 1);
      });
    } else {
      likeReview(review.uuid).then(({ uuid }) => {
        setLikeUuid(uuid);
        setLikeCount((v) => v + 1);
      });
    }
  };
  return (
    <ReviewItemWrapper>
      <LeftWrapper>{maskString(review.user.username)}</LeftWrapper>
      <MiddleWrapper>
        <Title>{review.title}</Title>
        {isMobile ? (
          <>
            <ImgsWrapper>
              {review.image_urls.map((t, idx) => (
                <Img
                  src={t}
                  key={idx}
                  onClick={() => setImgDetail({ list: review.image_urls, idx })}
                />
              ))}
            </ImgsWrapper>
            <Content>{review.content}</Content>
          </>
        ) : (
          <>
            <Content>{review.content}</Content>
            <ImgsWrapper>
              {review.image_urls.map((t, idx) => (
                <Img
                  src={t}
                  key={idx}
                  onClick={() => setImgDetail({ list: review.image_urls, idx })}
                />
              ))}
            </ImgsWrapper>
          </>
        )}
        <DateWrapper>{dayjs(review.created).format("YYYY-MM-DD")}</DateWrapper>
      </MiddleWrapper>
      <RightWrapper onClick={toggle}>
        {likeUuid ? (
          <ThumbsupActive style={{ cursor: "pointer" }} />
        ) : (
          <Thumbsup style={{ cursor: "pointer" }} />
        )}
        도움이 됐어요 {likeCount}
      </RightWrapper>
    </ReviewItemWrapper>
  );
}
