import {
  CalendarDigit,
  Day,
  Locale,
} from "@hassanmojab/react-modern-calendar-datepicker";

export const myCustomLocale: Locale = {
  months: [
    "01월",
    "02월",
    "03월",
    "04월",
    "05월",
    "06월",
    "07월",
    "08월",
    "09월",
    "10월",
    "11월",
    "12월",
  ],

  // week days by order
  weekDays: [
    {
      name: "일", // used for accessibility
      short: "일", // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: "월",
      short: "월",
    },
    {
      name: "화",
      short: "화",
    },
    {
      name: "수",
      short: "수",
    },
    {
      name: "목",
      short: "목",
    },
    {
      name: "금",
      short: "금",
    },
    {
      name: "토",
      short: "토",
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: Day) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date: Day) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date: Day) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit: CalendarDigit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: "Next Month",
  previousMonth: "Previous Month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select...",

  // for input range value
  from: "from",
  to: "to",

  // used for input value when multi dates are selected
  digitSeparator: ",",

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};
