import nollyAxios from "./nollyAxios";

export interface RegisterEmailRes {
  session_token: string;
}

// phone_number
export function registerEmail(email: string): Promise<RegisterEmailRes> {
  const payload = {
    email,
  };
  return nollyAxios
    .post(`/api/email/register`, payload)
    .then(({ data }) => data);
}

// phone_number
export function verifyEmail(
  email: string,
  session_token: string,
  security_code: string
): Promise<void> {
  const payload = {
    email,
    session_token,
    security_code,
  };
  return nollyAxios.post(`/api/email/verify`, payload).then(({ data }) => data);
}
