import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { createQuestion } from "../../api/questionApi";
import { QuestionTopic } from "../../types/enums";
import Button from "../Common/Button";
import Input from "../Common/Input";
import TextArea from "../Common/TextArea";
import { isMobile } from "react-device-detect";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;
const HeaderTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.044px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const InputRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const InputLabelWrapper = styled.div`
  display: flex;
  width: 110px;
  min-width: 110px;
  align-items: flex-start;
  gap: 2px;
  color: var(--grey-100, #29292a);
  font-size: 15px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const RequiredSpan = styled.span`
  color: #f00;
  font-size: 14px;
  font-weight: 400;
`;

export default function OneToOneCreate({ review }: { review?: any }) {
  const navigate = useNavigate();

  const [topic, setTopic] = useState<string>("PRODUCT");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const options = useMemo(() => {
    return Object.entries(QuestionTopic).map(([k, v], idx) => ({
      label: v,
      value: k,
    }));
  }, []);

  const create = () => {
    createQuestion(topic, title, content).then(() => {
      alert("1:1 문의를 성공적으로 남겼습니다.");
      navigate(-1);
    });
  };

  return (
    <Container>
      {!isMobile && (
        <Header>
          <HeaderTitleWrapper>
            <HeaderTitle>1:1 문의</HeaderTitle>
          </HeaderTitleWrapper>
        </Header>
      )}
      <FormContainer>
        <InputRowWrapper>
          <InputLabelWrapper>
            유형 <RequiredSpan>*</RequiredSpan>
          </InputLabelWrapper>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            isSearchable={false}
            options={options}
            onChange={(a) => setTopic(a?.value || "PRODUCT")}
            styles={{
              container: (base) => ({ width: "100%", height: "42px" }),
              control: (base) => ({ ...base, cursor: "pointer" }),
            }}
            placeholder={"문의 유형을 선택해주세요."}
            components={{ IndicatorSeparator: () => null }}
          />
        </InputRowWrapper>
        <InputRowWrapper>
          <InputLabelWrapper>
            제목 <RequiredSpan>*</RequiredSpan>
          </InputLabelWrapper>
          <Input
            placeholder="제목을 입력해주세요."
            style={{ height: 42 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </InputRowWrapper>
        <InputRowWrapper style={{ alignItems: "flex-start" }}>
          <InputLabelWrapper style={{ marginTop: 10 }}>
            내용 <RequiredSpan>*</RequiredSpan>
          </InputLabelWrapper>
          <TextArea
            placeholder="내용을 입력해주세요."
            style={{ height: 268 }}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </InputRowWrapper>
        <InputRowWrapper style={{ alignItems: "flex-start" }}>
          <InputLabelWrapper style={{ marginTop: 10 }} />
          <Button
            style={{ background: "#29292A", color: "white" }}
            onClick={() => create()}
          >
            등록
          </Button>
        </InputRowWrapper>
      </FormContainer>
    </Container>
  );
}
