import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendResetPwEmail } from "../api/authApi";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import Input from "../components/Common/Input";

const LoginContainer = styled(CommonContainer)`
  padding: 80px 14px 120px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
    padding-top: 40px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Label = styled.div`
  color: var(--grey-70, #939394);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const SubTitle = styled.div`
  color: var(--grey-100, #29292a);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
`;

export default function FindPwPage() {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailSendClick = async () => {
    try {
      await sendResetPwEmail(email);
      setIsSent(true);
    } catch (e) {}
  };

  return (
    <LoginContainer>
      <ContentContainer>
        <Title>비밀번호 찾기</Title>
        {isSent && email ? (
          <>
            <SubTitle>
              {email}으로 <br />
              비밀번호 재설정 메일이 발송되었어요.
              <br />
              <br />
              5분 후에도 메일이 오지 않는다면
              <br />
              스팸함을 확인해 주세요.
            </SubTitle>
            <Button
              onClick={() => navigate("/login")}
              style={{
                marginTop: 60,
                background: "#29292A",
                color: "white",
                border: "none",
                marginBottom: 12,
              }}
            >
              로그인
            </Button>
          </>
        ) : (
          <>
            <InputWrapper>
              <Label>이메일</Label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder="이메일을 입력해주세요."
                style={{ marginBottom: 12 }}
              />
            </InputWrapper>
            <Button
              onClick={() => handleEmailSendClick()}
              style={{
                background: "#29292A",
                color: "white",
                border: "none",
                marginBottom: 12,
              }}
            >
              확인
            </Button>
          </>
        )}
      </ContentContainer>
    </LoginContainer>
  );
}
