import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Answer, getAnswer } from "../../api/answerApi";
import { ProductQuestion } from "../../api/productQuestionApi";
import { ReactComponent as IcLock } from "../../assets/IcLock.svg";
import { useRecoilValue } from "recoil";
import { userState } from "../../store/authStore";
import { isMobile } from "react-device-detect";

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px 14px;
    border-bottom: 1px solid var(--grey-30, #efeff0);
  }
`;
const RowDetail = styled.div`
  display: flex;
`;
const Cell = styled.div`
  color: var(--grey-70, #939394);
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  display: flex;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    font-weight: 400;
    padding: 0;
    border-bottom: none;
  }
`;
const Title = styled(Cell)`
  justify-content: flex-start;
  flex: 1 0 0;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;
const Other = styled(Cell)`
  width: 160px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: auto;
  }
`;
const QuestionContent = styled.pre``;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--grey-20, #f9f9fa);
  padding: 20px 14px;
`;
const AnswerWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
const AnswerTitle = styled.div`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const AnswerContent = styled.pre`
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const AnswerDate = styled.div`
  color: var(--grey-70, #939394);
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
export default function ProductQuestionItem({
  question,
}: {
  question: ProductQuestion;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [answer, setAnswer] = useState<null | Answer>(null);
  const user = useRecoilValue(userState);
  const isOwner = question.user.uuid === user?.uuid;

  useEffect(() => {
    if (isOpen && !answer) {
      if (question.is_public) {
        getAnswer(question.uuid).then(setAnswer).catch(console.error);
      } else {
        if (question.user.uuid === user?.uuid) {
          getAnswer(question.uuid).then(setAnswer).catch(console.error);
        }
      }
    }
  }, [isOpen, answer, question.is_public]);

  if (isMobile)
    return (
      <>
        <Row onClick={() => setIsOpen((v) => !v)}>
          {question.is_public ? (
            <Title style={{ borderBottom: "none" }}>{question.title}</Title>
          ) : (
            <Title style={{ color: "#939394", borderBottom: "none" }}>
              <IcLock /> 비밀글 입니다.
            </Title>
          )}
          <RowDetail>
            <Other>{question.user.username}</Other>
            <Other>{dayjs(question.created).format("YYYY-MM-DD")}</Other>
            <Other>{dayjs(question.created).format("YYYY-MM-DD")}</Other>
          </RowDetail>
        </Row>
        {isOpen && (question.is_public || (!question.is_public && isOwner)) && (
          <Content>
            <QuestionContent>{question.content}</QuestionContent>
            {answer && (
              <AnswerWrapper>
                <AnswerTitle>[답변]</AnswerTitle>
                <AnswerContent>{answer.content}</AnswerContent>
                <AnswerDate>
                  {dayjs(answer.created).format("YYYY-MM-DD")}
                </AnswerDate>
              </AnswerWrapper>
            )}
          </Content>
        )}
      </>
    );

  return (
    <>
      <Row onClick={() => setIsOpen((v) => !v)}>
        {question.is_public ? (
          <Title>{question.title}</Title>
        ) : (
          <Title style={{ color: "#939394" }}>
            <IcLock /> 비밀글 입니다.
          </Title>
        )}
        <Other>{question.user.username}</Other>
        <Other>{dayjs(question.created).format("YYYY-MM-DD")}</Other>
        <Other>{dayjs(question.created).format("YYYY-MM-DD")}</Other>
      </Row>
      {isOpen && (question.is_public || (!question.is_public && isOwner)) && (
        <Content>
          <QuestionContent>{question.content}</QuestionContent>
          {answer && (
            <AnswerWrapper>
              <AnswerTitle>[답변]</AnswerTitle>
              <AnswerContent>{answer.content}</AnswerContent>
              <AnswerDate>
                {dayjs(answer.created).format("YYYY-MM-DD")}
              </AnswerDate>
            </AnswerWrapper>
          )}
        </Content>
      )}
    </>
  );
}
