import { atom } from "recoil";
import { NoticeTopic } from "../types/enums";

export { loadingState, loginPopupState, noticeState };

const loadingState = atom<boolean>({
  key: "Loading",
  default: false,
});

const noticeState = atom<keyof typeof NoticeTopic>({
  key: "NoticeState",
  default: "ALL",
});

interface LoginPopupState {
  next: string,
  state: object
}

const loginPopupState = atom<null | LoginPopupState>({
  key: "LoginPopup",
  default: null,
});
