import styled from "@emotion/styled";
import React, { ChangeEvent, HTMLInputTypeAttribute, useEffect } from "react";
import useCountdown from "../../hooks/useCountDown";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const InputContainer = styled.input`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid var(--grey-50, #cbcbcc);
  background: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.032px;
  &::placeholder {
    color: var(--grey-60, #b2b2b3);
  }
  &:disabled {
    background: var(--grey-30, #efeff0);
    color: var(--grey-60, #b2b2b3);
  }
`;
const CountDownText = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--red-100, #f00);
  font-size: 16px;
  font-weight: 400;
`;

const ErrorText = styled.div`
  position: absolute;
  color: var(--red-100, #f00);
  margin-top: 2px;
  font-size: 8px;
  font-weight: 400;
`;

export type InputValue = string | number | ReadonlyArray<string>;
export type InputChangeEvent = ChangeEvent<HTMLInputElement>;

interface Props {
  placeholder?: string;
  value?: InputValue;
  type?: HTMLInputTypeAttribute;
  style?: React.CSSProperties;
  onChange?: (ev: InputChangeEvent) => void;
  errors?: any;
  disabled?: boolean;
  max?: number;
  countDown?: string;
}

const Input: React.FunctionComponent<Props> = ({
  value = "",
  onChange,
  style,
  placeholder,
  errors,
  type = "text",
  disabled = false,
  max,
  countDown,
}) => {
  const { timeLeft, stopInterval } = useCountdown(countDown);

  useEffect(() => {
    if (countDown === "") {
      stopInterval();
    }
  }, [countDown]);

  return (
    <Container>
      <InputContainer
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        max={max}
        type={type}
        style={errors ? { ...style, border: "1px solid red" } : style}
        autoCapitalize='none'
      />
      {/* {!countDown !== undefined && ( */}
      {countDown && (
        <CountDownText>
          {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, "0")}
        </CountDownText>
      )}
      {errors && <ErrorText>{errors}</ErrorText>}
    </Container>
  );
};

export default Input;
