type CustomError = {
  code: string;
  detail: string;
  attr: any;
};
export type CustomException = {
  type: string;
  errors: CustomError[];
};

function getCodeFromException(customException: CustomException) {
  if (!customException.errors || customException.errors.length === 0)
    return null;
  const error = customException.errors[0];
  return error.code;
}

function getErrorMsg(code: string) {
  switch (code) {
    case "120101":
    case "120102": {
      return "결제를 실패하였습니다.";
    }
    case "120103": {
      return "남은 자리가 없습니다.";
    }
    case "120104": {
      return "포인트가 충분하지 않습니다.";
    }
    case "120105": {
      return "환불 상태를 확인해주세요.";
    }
    case "120106": {
      return "환불 가능한 기간이 아닙니다.";
    }
    case "120107": {
      return "결제할 상품에 문제가 있습니다.";
    }
    case "110104": {
      return "잘못된 회사 코드 입니다."
    }
    default:
      return "";
  }
}

export function alertExceptionMsg(customException?: CustomException, defaultMsg?: string) {
  if (!customException) return alert(defaultMsg);
  const code = getCodeFromException(customException);
  if (!code) return alert(defaultMsg);

  if (code) {
    const msg = getErrorMsg(code);
    if (msg) alert(msg);
    else alert(defaultMsg);
  } else {
    alert(defaultMsg);
  }
}

// class UserEmailInvalid(exceptions.ValidationError):
//     default_detail = 'UserEmailInvalid'
//     default_code = '110101`'

// class UserPasswordInvalid(exceptions.ValidationError):
//     default_detail = 'UserPasswordInvalid'
//     default_code = '110102`'

// class VerificationCodeInvalidated(exceptions.ValidationError):
//     default_detail = 'VerificationCodeInvalidated'
//     default_code = '110103'

// class PurchaseInvalid(exceptions.ValidationError):
//     default_detail = 'PurchaseInvalid'
//     default_code = '120101'

// class PaymentInvalid(exceptions.ValidationError):
//     default_detail = 'PaymentInvalid'
//     default_code = '120102'

// class NotEnoughSeat(exceptions.ValidationError):
//     default_detail = 'NotEnoughSeat'
//     default_code = '120103'

// class NotEnoughPoint(exceptions.ValidationError):
//     default_detail = 'NotEnoughPoint'
//     default_code = '120104'

// class PurchaseRefundStatusInvalid(exceptions.ValidationError):
//     default_detail = 'PurchaseRefundStatusInvalid'
//     default_code = '120105'

// class PurchaseRefundDayInvalid(exceptions.ValidationError):
//     default_detail = 'PurchaseRefundDayInvalid'
//     default_code = '120106'

// class ProductInactive(exceptions.ValidationError):
//     default_detail = 'ProductInactive'
//     default_code = '120107'

// class SMSTokenInvalid(exceptions.ValidationError):
//     default_detail = 'SMSTokenInvalid'
//     default_code = '130101'

// class EmailSecurityTokenInvalid(exceptions.ValidationError):
//     default_detail = 'EmailSecurityTokenInvalid'
//     default_code = '140101'

// class EmailSessionTokenInvalid(exceptions.ValidationError):
//     default_detail = 'EmailSessionTokenInvalid'
//     default_code = '140102'

// class EmailSecurityCodeExpired(exceptions.ValidationError):
//     default_detail = 'EmailSecurityCodeExpired'
//     default_code = '140104'

// class EmailSecurityCodeVerified(exceptions.ValidationError):
//     default_detail = 'EmailSecurityCodeVerified'
//     default_code = '140105'

// class PointAmountInvalid(exceptions.ValidationError):
//     default_detail = 'PointAmountInvalid'
//     default_code = '150101'
