import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { getFaqDetail } from "../../api/faqApi";

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
`;
const Title = styled.div`
  display: flex;
  padding: 20px 14px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 500;
`;

const DateWrapper = styled.div`
  display: flex;
  width: 160px;
  padding: 20px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  color: var(--grey-70, #939394);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

const Content = styled.pre`
  display: flex;
  padding: 40px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-30, #efeff0);
  background: var(--red-20, #fff9f9);
  color: var(--grey-100, #29292a);
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
`;

interface Props extends React.PropsWithChildren {
  uuid: string;
  title: string;
  createdAt: string;
  category: string;
}

const FaqItem: React.FunctionComponent<Props> = ({
  uuid,
  title,
  createdAt,
  category,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  useEffect(() => {
    if (uuid && !content && isOpen) {
      getFaqDetail(uuid).then(({ content }) => {
        setContent(content);
      });
    }
  }, [isOpen, uuid, content]);
  return (
    <Container>
      <Header onClick={() => setIsOpen((v) => !v)}>
        <Title style={isOpen ? { color: "#F00", fontWeight: '600'} : {}}>{title}</Title>
        {/* <DateWrapper>{category}</DateWrapper> */}
      </Header>
      {isOpen && <Content>{content}</Content>}
    </Container>
  );
};

export default FaqItem;
