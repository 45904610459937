import nollyAxios from "./nollyAxios";

export interface About {
  uuid: string;
  image: string;
  created: string;
  modified: string;
}

export function getIntro(): Promise<About> {
  return nollyAxios
    .get(`/api/service_introductions/latest`)
    .then(({ data }) => data);
}

export function getOffer(): Promise<About> {
  return nollyAxios
    .get(`/api/service_offers/latest`)
    .then(({ data }) => data);
}
