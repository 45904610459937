// nolly_instagram
import nollyAxios from "./nollyAxios";
import { PaginationType } from "./productApi";

export interface Insta {
  uuid: string;
  ig_link: string;
  order: number;
  image: string;
  created: string;
  modified: string;
}

export function getInstas(): Promise<PaginationType<Insta>> {
  return nollyAxios.get(`/api/nolly_instagram?offset=${0}&limit=${10}`).then(({ data }) => data);
}
