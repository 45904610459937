import { atom } from "recoil";
import { User } from "../api/authApi";
import { sessionStorageEffect } from "./effects/storageEffects";

export { userState };

const userState = atom<User | null>({
  key: "User",
  default: null,
  effects: [sessionStorageEffect("user")],
});
