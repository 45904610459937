import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function SquareImage({
  imageUrl,
  onClick,
  radius = 0,
}: {
  imageUrl?: string;
  onClick?: () => void;
  radius?: number;
}) {
  if (!imageUrl) {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: "100%", // 1:1 비율을 위한 패딩 값
          position: "relative",
          cursor: "pointer",
          borderRadius: radius,
          background: "rgba(0,0,0,0.1)",
        }}
      />
    );
  }
  return (
    <Wrapper style={{ borderRadius: radius }} onClick={onClick}>
      <Img
        src={imageUrl}
        alt="Square Image"
        style={{
          borderRadius: radius,
        }}
      />
    </Wrapper>
  );
}

export default SquareImage;
