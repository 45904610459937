import styled from "@emotion/styled";
import { useState } from "react";
import Button from "../components/Common/Button";
import { CommonContainer } from "../components/Common";
import Input from "../components/Common/Input";
import useQuery from "../hooks/useQuery";
import { resetPassword } from "../api/authApi";
import { useNavigate } from "react-router-dom";

const LoginContainer = styled(CommonContainer)`
  padding: 80px 14px 120px 14px;
  @media screen and (max-width: 768px) {
    padding: 16px 14px;
    padding-top: 40px;
  }
`;

const ContentContainer = styled(CommonContainer)`
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Label = styled.div`
  color: var(--grey-70, #939394);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export default function PwResetPage() {
  const query = useQuery();
  const uid = query.get("uid") || "";
  const token = query.get("token") || "";
  const [passwordA, setPasswordA] = useState("");
  const [passwordB, setPasswordB] = useState("");
  const navigate = useNavigate();

  const handleReset = async () => {
    if (!uid || !token) {
      alert("잘못된 접근입니다.");
      return;
    }
    await resetPassword(passwordA, passwordB, uid, token);
    alert("비밀번호 성공적으로 재설정 하였습니다.");
    navigate("/login");
  };

  return (
    <LoginContainer>
      <ContentContainer>
        <Title>비밀번호 재설정</Title>
        <InputWrapper>
          <Label>새 비밀번호 등록</Label>
          <Input
            type="password"
            value={passwordA}
            onChange={(e) => setPasswordA(e.currentTarget.value)}
            placeholder="새 비밀번호를 입력해 주세요"
            style={{ marginBottom: 12 }}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>새 비밀번호 확인</Label>
          <Input
            type="password"
            value={passwordB}
            onChange={(e) => setPasswordB(e.currentTarget.value)}
            placeholder="새 비밀번호를 한번 더 입력해 주세요"
            style={{ marginBottom: 12 }}
          />
        </InputWrapper>
        <Button
          onClick={() => handleReset()}
          style={{
            background: "#29292A",
            color: "white",
            border: "none",
            marginBottom: 12,
          }}
        >
          확인
        </Button>
      </ContentContainer>
    </LoginContainer>
  );
}
