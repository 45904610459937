import nollyAxios from "./nollyAxios";

export interface RegisterSmsRes {
  session_token: string;
}

// phone_number
export function registerSms(phone_number: string): Promise<RegisterSmsRes> {
  const payload = {
    phone_number,
  };
  return nollyAxios.post(`/api/sms/register`, payload).then(({ data }) => data);
}

// phone_number
export function verifySms(
  phone_number: string,
  session_token: string,
  security_code: string
): Promise<RegisterSmsRes> {
  const payload = {
    phone_number,
    session_token,
    security_code,
  };
  return nollyAxios.post(`/api/sms/verify`, payload).then(({ data }) => data);
}
