import PointHistory from "../components/MyPage/PointHistory";
import MyPageLayout from "../components/MyPageLayout";

export default function MyPointPage() {
  return (
    <MyPageLayout menu="point">
      <PointHistory />
    </MyPageLayout>
  );
}
